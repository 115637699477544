import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { logOutCall } from '../../ApiCalls';
import profilecircle from '../../assets/images/profile-circle.png';
import { AuthContext } from '../../context/AuthContext';
import TitleList from '../Modals/TitleLists/TitleList';
import './navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { IsopenContext } from '../../context/isopenContext';
export default function Navbar() {
  const { dispatch } = useContext(AuthContext);
  const handleClick = (e) => {
    e.preventDefault();
    logOutCall(dispatch);
  };
  const { dispatchNav, isOpen } = useContext(IsopenContext);
  const handleShow = () => {
    dispatchNav({ type: 'TOGGLE' });
    console.log(isOpen);
  };
  const { user } = useContext(AuthContext);
  const [profileData, setData] = useState({});
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPartnerInformation/${user?.success?.partnerId}`
      );

      setData(res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  const [help, setHelp] = useState(false);
  const [contact, setContact] = useState(false);
  const handleContact = () => {
    setContact(!contact);
    setCon(true);
  };

  const [con, setCon] = useState(true);
  const handleClose = () => {
    setHelp(!help);
  };

  return (
    <div className="navbarContainer">
      <div className="navLogo">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/` + profileData?.logo}
          alt=""
        />
        <div className="navnames">
          <div>{profileData?.name}</div>
          <div>{profileData?.url}</div>
        </div>
      </div>
      <div className="navButton">
        <div className="signoutandIconMenu">
          <div className="signoutButton" onClick={handleClick}>
            Signout
          </div>
          <div className="humbergerIconMenu" onClick={handleShow}>
            {/* <FontAwesomeIcon
              icon={faBarsStaggered}
              color="#2691a3"
              className=""
              size="2x"
            /> */}
            <IconButton
              color="primary"
              style={{ color: '#2691a3' }}
              aria-label="delete"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
        <div className="helpAndContact ">
          <div className="helpButton rejectButtonActive" onClick={handleClose}>
            <div className="navHelpButton">
              <img src={profilecircle} alt="" />
            </div>
            <div className="navButtonTitle">
              <p>Help</p>
            </div>
          </div>
          <div
            className="rejectButtonActive contactButton"
            onClick={handleContact}
          >
            <div className="navContactButton">
              <img src={profilecircle} alt="" />
            </div>
            <div className="navButtonTitle">
              <p>Contact</p>
            </div>
          </div>
          <TitleList
            title="Help"
            help="help"
            show={help}
            handleClose={handleClose}
          />
          <TitleList
            con={con}
            setCon={setCon}
            title="Contact Site Admin"
            contact="contact"
            show={contact}
            handleClose={handleContact}
          />
        </div>
      </div>
    </div>
  );
}
