import React, { useContext, useEffect, useState } from 'react';
import CardIconAndTitle from '../Card/CardIconAndTitle';
import CardOverviewEnding from '../Card/CardOverviewEnding';
import CardSurveyPERMA from '../Card/CardSurveyPERMA';
import CardOverviewCheck from '../Card/CardOverviewCheck';
import './cards.css';
import CardSurveyChallenges from '../Card/CardSurveyChallenges';
import SmallCards from './SmallCards';
import SmallCardNoBorder from './SmallCardNoBorder';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FeelWork from './FeelWork';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function Cards() {
  
  const { user } = useContext(AuthContext);
  const [dash, setDash] = useState();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {});
  const [data, setData] = useState();
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getOverallSummary/${user?.success?.partnerId}`
      );

      setData(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getdashboardsummary/${user?.success?.partnerId}`
      );

      setDash(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);

  return (
    <div className="overviewContainerTop">
      <Box
        sx={{ width: '100%', justifyContent: 'center', alignSelf: 'center' }}
      >
        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs
            value={value}
            centered
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#2691a3',
                fontWeight: 'bold',
                fontSize: '30px',
              },
            }}
          >
            <Tab
              label={
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '17px',
                    color: '#2691a3',
                  }}
                >
                  Overall Summary
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: '17px',
                    color: '#2691a3',
                  }}
                >
                  Wellbeing Stats
                </span>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="SecondContainer SecondContainerOv">
            <CardIconAndTitle
              width={305}
              count={dash?.coreWellbeingAreas}
              title="Core Wellbeing Areas"
              icon="spa"
            />{' '}
            <CardIconAndTitle
              width={305}
              count={dash?.yourWellbeingSolutions}
              title="Your Wellbeing Services"
              icon="heart"
            />{' '}
            <CardIconAndTitle
              width={305}
              count={dash?.promotedWellbeingAreas}
              title="Promoted Wellbeing Areas"
              icon="bull"
            />{' '}
            <CardIconAndTitle
              width={305}
              count={dash?.totalResources}
              title="Total Resources"
              icon="folder"
            />{' '}
            <CardIconAndTitle
              width={305}
              count={dash?.totalDocuments}
              title="Total Documents"
              icon="file"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.totalUrlLinks}
              title="Total url Links"
              icon="browser"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.totalSurveys}
              title="Total Surveys"
              icon="poll"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.liveSurveys}
              title="Live Surveys"
              icon="bars"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.surveysNotStarted}
              title="Surveys Not Started"
              icon="square"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.totalQuestions}
              title="Total Questions"
              icon="block"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.responses}
              title="Responses"
              icon="reply"
            />
            <CardIconAndTitle
              width={305}
              count={dash?.newQuestions}
              title="New Questions"
              icon="messages"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="cardsContainer overCardss ">
            <div className="FirstcardsContainer">
              <CardIconAndTitle
                width={305}
                count={data?.wellbeingScore ? data?.wellbeingScore : 0}
                title="Average Wellbeing Score"
                icon="spa"
              />
              <CardIconAndTitle
                title="Completed Surveys"
                icon="bars"
                count={data?.surveysCount ? data?.surveysCount : 0}
              />
              <CardOverviewEnding width={280} ending={data?.ClosingInWeeks} />
            </div>
            <div className="FirstcardsContainer feelingsCard secondCardContainer">
              <CardOverviewCheck feelings={data?.feelings} />
              <FeelWork />
            </div>
            <div className="FirstcardsContainer secondCardContainer"></div>
            <div className="ThirdCardContainer">
              {/* <div className="thirdFirstContainer">
              <CardOverviewArea wellbeingTotals={data?.wellbeingTotals} />
            </div> */}
              <div className="smallContainer smallContainerNew">
                <div className="thirdSecondContainer BottomAreaover2">
                  <CardSurveyPERMA perma={data?.permaCounts} />
                  <SmallCards
                    areas={data?.wellbeingAreaStats}
                    title="Wellbeing Concerns"
                  />
                  <SmallCards
                    areas={data?.wellbeingChallenges}
                    title="Wellbeing Challenges"
                  />
                  <SmallCards
                    areas={data?.requestedServices}
                    title="Trending Initiatives"
                  />
                </div>
                <div className="thirdSecondContainer BottomAreaover">
                  <CardSurveyChallenges
                    width={286}
                    loww="loww"
                    areas={data?.lowScoreReasons}
                    title="Poor Wellbeing Drivers"
                  />
                  <SmallCardNoBorder
                    areas={data?.performanceChallenges}
                    title="Performance Challenges"
                  />
                  <SmallCardNoBorder
                    areas={data?.topFeelings}
                    title="Top Feelings"
                  />
                  <SmallCardNoBorder
                    areas={data?.requestedServices}
                    title="Service Requested"
                  />
                </div>
              </div>
            </div>
            <div
              className="gap"
              style={{
                width: '100%',
                backgroundColor: 'white',
                height: '60px',
              }}
            ></div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
