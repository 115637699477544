import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
export default function CardSurveyTrends() {
  const [trend, setTrend] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getWellbeingTags/${user?.success?.partnerId}`
      );

      setTrend(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  return (
    <div className="CardSurveyTrendsContainer">
      <div className="surveyTrendTitle surveyTrendTitle-nt">
        Wellbeing Trends
      </div>
      <div className="surveyTypeContainer">
        <div className="typeContainer">
          <div className="typeTitle">Maintain</div>
          <div className="typeColor typeColor1">
            {trend && (
              <div className="valuesContainer">
                <div className="value">{trend?.financial_tag?.maintain}</div>

                <div className="value">{trend?.professional_tag?.maintain}</div>
                <div className="value">{trend?.mental_tag?.maintain}</div>
                <div className="value">{trend?.physical_tag?.maintain}</div>
                <div className="value">{trend?.social_tag?.maintain}</div>
                <div className="value">{trend?.societal_tag?.maintain}</div>
              </div>
            )}
          </div>
        </div>
        <div className="typeContainer">
          <div className="typeTitle">Risk</div>
          <div className="typeColor typeColor2">
            {trend && (
              <div className="valuesContainer">
                <div className="value">{trend?.financial_tag?.risk}</div>

                <div className="value">{trend?.professional_tag?.risk}</div>
                <div className="value">{trend?.mental_tag?.risk}</div>
                <div className="value">{trend?.physical_tag?.risk}</div>
                <div className="value">{trend?.social_tag?.risk}</div>
                <div className="value">{trend?.societal_tag?.risk}</div>
              </div>
            )}
          </div>
        </div>
        <div className="typeContainer">
          <div className="typeTitle">Issue</div>
          <div className="typeColor typeColor3">
            {trend && (
              <div className="valuesContainer">
                <div className="value">{trend?.financial_tag?.issue}</div>

                <div className="value">{trend?.professional_tag?.issue}</div>
                <div className="value">{trend?.mental_tag?.issue}</div>
                <div className="value">{trend?.physical_tag?.issue}</div>
                <div className="value">{trend?.social_tag?.issue}</div>
                <div className="value">{trend?.societal_tag?.issue}</div>
              </div>
            )}
          </div>
        </div>
        <div className="typeContainer">
          <div className="typeTitle"></div>
          <div className="allIconsContainer">
            <div className="allIconsContainerMain">
              <div className="centerIconAndTitleContainer centerIconAndTitleContainerSurvey">
                <div className="centerIconAndTitle ">
                  <div className="centerIcon">
                    <FontAwesomeIcon icon={faCoins} color="#2691a3" size="2x" />
                  </div>
                  <div className="centerTitle">
                    Financial <br /> Wellbeing
                  </div>
                </div>
                <div className="centerIconAndTitle">
                  <div className="centerIcon">
                    <FontAwesomeIcon
                      icon={faHandshake}
                      color="#2691a3"
                      size="2x"
                    />
                  </div>
                  <div className="centerTitle">
                    Professional <br /> Wellbeing
                  </div>
                </div>
                <div className="centerIconAndTitle">
                  <div className="centerIcon">
                    <FontAwesomeIcon
                      icon={faRunning}
                      color="#2691a3"
                      size="2x"
                    />
                  </div>
                  <div className="centerTitle">
                    Physical <br /> Wellbeing
                  </div>
                </div>
                <div className="centerIconAndTitle">
                  <div className="centerIcon">
                    <FontAwesomeIcon icon={faBrain} color="#2691a3" size="2x" />
                  </div>
                  <div className="centerTitle">
                    Mental <br /> Wellbeing
                  </div>
                </div>
                <div className="centerIconAndTitle">
                  <div className="centerIcon">
                    <FontAwesomeIcon icon={faSmile} color="#2691a3" size="2x" />
                  </div>
                  <div className="centerTitle">
                    Social <br /> Wellbeing
                  </div>
                </div>
                <div className="centerIconAndTitle">
                  <div className="centerIcon">
                    <FontAwesomeIcon icon={faUsers} color="#2691a3" size="2x" />
                  </div>
                  <div className="centerTitle">
                    Societal <br /> Wellbeing
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
