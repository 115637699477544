import React from 'react';
import { Box, Button } from '@mui/material';

export default function Testimonials({
  state,
  title,
  apiName,
  name,
  multiple,
  onChange,
  handleDelete,
  handleOpenFile,
}) {
  return (
    <div className="flex flex-col w-full gap-2 items-center">
      <div className="flex justify-between gap-4 w-full flex-wrap items-center sm:justify-center sm:!flex-wrap-reverse">
        <div className="rejectButtonActive helpButton relative">
          <label htmlFor={'picturefile'}>
            <input
              onChange={(e) =>
                multiple
                  ? onChange(name, e.target.files)
                  : onChange(name, e.target.files[0])
              }
              type="file"
              multiple={multiple}
              accept=".jpg, .jpeg, .png, .pdf"
              name={name}
              id={'picturefile'}
              style={{
                opacity: '0',
                left: '0',
                top: '0',
                height: '100%',
                position: 'absolute',
              }}
            />
            <div className="navButtonTitle">
              <p>Upload</p>
            </div>
          </label>
        </div>
        <div
          style={{
            borderRadius: '17px',
            backgroundColor: 'rgba(85, 143, 211, 0.1)',
            padding: '10px',
            display: 'flex',
            flex: '1',
          }}
        >
          {title}
        </div>
      </div>
      <div className="text-sm text-gray-200 w-full">
        <p
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            color: 'gray',
          }}
        >
          {(Array?.isArray(state[name]) &&
          state[name] &&
          state[name]?.length > 0
            ? 'Files selected'
            : state[name] && !state[name]?.name && !Array.isArray(state[name])
            ? state[name] || ''
            : state[name]?.name || '') || 'Choose file'}
        </p>
      </div>
      <Box className="flex gap-2">
        {state[name] && !state[name]?.name && !Array.isArray(state[name]) && (
          <div onClick={() => handleOpenFile(state[name], apiName)}>
            <Button
              variant="contained"
              size="small"
              sx={{
                '&.MuiButton-root': {
                  backgroundColor: '#2691a3',
                },

                '&.MuiButton-root:hover': {
                  backgroundColor: '#2691a3',
                },
              }}
            >
              View
            </Button>
          </div>
        )}
        {state[name]?.length &&
        !Array.isArray(state[name]) &&
        state[name]?.split(',').length === 1 ? (
          <Button
            onClick={() => handleDelete(state[name], apiName)}
            variant="contained"
            size="small"
            color="error"
          >
            Delete
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
}
