import { useContext } from 'react';
import './app.css';
import '../src/components/Card/card.css';
import '../src/components/Cards/cards.css';
import '../src/components/sidebar/sidebar.css';
import '../src/components/navbar/navbar.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Login from './pages/Login/Login';
import NewPassword from './pages/Login/NewPassword';
import Overview from './pages/overview/Overview';
import Profile from './pages/profile/Profile';
import Questions from './pages/Questions/Questions';
import Resources from './pages/Resources/Resources';
import Surveys from './pages/surveys/Surveys';
import Services from './pages/services/Services';
import Signup from './pages/signup/Signup';
import CreateSurvey from './pages/newSurvey/CreateSurvey';

const App = () => {
  const { user } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={user ? <Overview /> : <Login />}></Route>
        <Route
          path="/login"
          element={user ? <Navigate to="/" /> : <Login />}
        ></Route>
        <Route
          path="/register"
          element={user ? <Navigate to="/" /> : <Signup />}
        ></Route>
        <Route
          path="/createSurvey"
          element={user ? <Navigate to="/" /> : <CreateSurvey />}
        ></Route>
        <Route
          path="/newPassword"
          element={user ? <Navigate to="/" /> : <NewPassword />}
        ></Route>
        <Route path="/profile" element={user ? <Profile /> : <Login />}></Route>
        <Route
          path="/services"
          element={user ? <Services /> : <Login />}
        ></Route>
        <Route
          path="/resources"
          element={user ? <Resources /> : <Login />}
        ></Route>
        <Route path="/surveys" element={user ? <Surveys /> : <Login />}></Route>
        <Route
          path="/questions"
          element={user ? <Questions /> : <Login />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
