import React from 'react'

export default function AllResource(props) {
  return (
    <>
    {props.viewShow && (
      <div className="titleContainerModal">
        <div className="TitleListContainer">
          <div className="TitleListTitle">
            {props.title ? props.title : props.item.document_url }
          </div>
          <div className="TitleListTitle singleres">
            {props.item ? props?.item?.wellbeingtype : ''}
          </div>
          <div
            className={`resourcesingleselect ${
              props.wellbeing ? ' hidebackground ' : ''
            } ' TitleListBody TitleListBodyContact '`}
          >
           
           {props.item.areas_addressed?.split(',').map((index, id) => {
                return (
                  <div
                  key={id}
                    style={{ display: !index && 'none' }}
                    className="resourceCategory resourceCategoryAll"
                  >
                    {index.toString()}{' '}
                  </div>
                );
              })}
           
          </div>
          <div className="TitleListClose rejectButtonActive" onClick={()=>props.setViewShow(false)}>
            Close
          </div>
        </div>
      </div>
    )}
  </>
  )
}
