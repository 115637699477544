import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import ListSurveyOld from './ListSurveyOld';
import Loading from '../Views/Loading';
import { InputAdornment, TextField } from '@mui/material';
import { filteredItems } from '../../utils/filter';
import { SearchOutlined } from '@mui/icons-material';
export default function CardSurveysOld() {
  /* eslint-disable */
  const [survey, setSurvey] = useState();
  const [query, setQuery] = useState('');
  const handleSearchInputChange = (event) => {
    setQuery(event.target.value);
  };
  let sortedUsernamesArray = survey?.sort(function (a, b) {
    //
    return a.company_name > b.company_name ? 1 : -1;
  });
  const filteredSurveys = filteredItems(
    sortedUsernamesArray,
    query,
    'company_name'
  );
  // const [surveyUpdated, setSurveyUpdates] = useState();
  const [loading, setLoading] = useState(true);
  const [surveyCopy, setSurveyCopy] = useState([]);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/getSurveysListForPartner/${user?.success?.partnerId}`
        );
        setSurvey(res.data);

        setLoading(false);
      } catch (error) {}
    };
    console.log(surveyCopy);
    fetchPosts();
  }, [user?.success?.partnerId]);
  console.log(survey);
  useEffect(() => {
    setSurveyCopy(survey);
    setList(survey);
  }, [survey]);
  const [list, setList] = useState();
  const handleDelete = async (id) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/deleteSurvey`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        project_id: id,
      },
    }).then((res) => {
      window.location.reload();
    });
    // const newList = surveyCopy.filter((item) => item.project_id !== id);
    // setList(newList);
  };
  return (
    <div className="surveyAreaContainer surveyAreaContainerOld">
      <div className="buttonContainerSurvey">
        <div className="centerButton3 centerbtn ">Completed Surveys</div>
      </div>
      <Box mb={4}>
        <TextField
          onChange={handleSearchInputChange}
          placeholder="Search for company"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <div className="surveyTitleContainer">
        <div className="surveyTitle">Company</div>
        <div className="surveyTitle surveyTitleLink">Link</div>
        <div className="surveyTitle surveyTitlecenter">Action</div>
        {/* <div className="surveyTitle surveyTitlecenter"></div> */}
        <div className="surveyTitle surveyTitlecenter">End Date</div>
        <div
          style={{ marginLeft: '20px' }}
          className="surveyTitle surveyTitlecenter"
        >
          Expected responses
        </div>
        <div className="surveyTitle surveyTitlecenter">Responses recieved</div>
      </div>
      {!loading ? (
        <div className="surveyTable">
          {filteredSurveys?.length > 0 ? (
            <div className="apiSurvey">
              {filteredSurveys?.map((item, id) => (
                <div key={id}>
                  {loading ? (
                    <Box sx={{ width: '100%' }}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  ) : (
                    <ListSurveyOld
                      item={item}
                      handleDelete={() => handleDelete(item.project_id)}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Box
              className="flex justify-center items-center h-full"
              style={{ color: 'gray' }}
            >
              Survey not found
            </Box>
          )}
        </div>
      ) : (
        <Loading from="surveys" />
      )}
    </div>
  );
}
