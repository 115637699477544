import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import CardArea from '../Card/CardArea';
import CardIconAndTitle from '../Card/CardIconAndTitle';
import CardListofIcon from '../Card/CardListofIcon';
import CardWellBeing from './CardWellBeing';
import './cardservices.css';

export default function CardServices() {
  const { user } = useContext(AuthContext);
  const [promoted, setPromoted] = useState();
  const [services, setServices] = useState();
  const [wellbeing, setWellbeing] = useState();
  const [wellbeing2, setWellbeing2] = useState();
  const [profileData, setProfileData] = useState();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [promotedLoading, setPromotedLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPartnerInformation/${user?.success?.partnerId}`
      );

      setProfileData(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getServicesStatsForPartner/${user?.success?.partnerId}`
      );
      setLoading(false);
      setServices(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getUpsellServices/${user?.success?.partnerId}`
      );
      setLoading2(false);

      setWellbeing2(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getWellbeingServices/${user?.success?.partnerId}`
      );
      setLoading(false);
      setWellbeing(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPromotedAreas/${user?.success?.partnerId}`
      );
      setPromotedLoading(false);
      setPromoted(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  const [services2, setServices2] = useState([]);
  const [warn, setWarn] = useState();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPromotedAreas/${user?.success?.partnerId}`
      );

      setServices2(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  return (
    <div className="cardsContainer cardServicesMaincont">
      <div className="FirstcardsContainer">
        <CardIconAndTitle
          servicesProvided={
            services?.coreWellbeingAreas ? services?.coreWellbeingAreas : 0
          }
          title="Core Wellbeing Areas"
          icon="spa"
        />
        <CardIconAndTitle
          servicesProvided={
            services?.wellbeingSolutions ? services?.wellbeingSolutions : 0
          }
          title="Your Wellbeing Services"
          icon="heart"
        />
        <CardIconAndTitle
          servicesProvided={
            services?.promotedAreas ? services?.promotedAreas : 0
          }
          title="Promoted Wellbeing Areas"
          icon="bull"
        />
      </div>
      <div className="FirstcardsContainer secondCardContainer secondCardContainerServies">
        <CardArea
          warn={warn}
          setWarn={setWarn}
          services={services2}
          profileData={profileData}
        />
        <CardListofIcon
          loading={promotedLoading}
          services={services}
          promoted={promoted}
        />
      </div>
      <div className="FirstcardsContainer secondCardContainer wellbeing-ctr">
        <CardWellBeing
          wellbeing={wellbeing}
          desc="Services Requested"
          request="request"
          width={215}
          loading={loading}
        />
        <CardWellBeing
          loading={loading}
          wellbeing={wellbeing}
          desc="Top Wellbeing Services"
          width={215}
        />

        <CardWellBeing
          loading={loading2}
          wellbeing={wellbeing2}
          desc="Upsell Services"
          request="request"
          width={215}
        />
      </div>

      <div
        className="gap"
        style={{ width: '100%', backgroundColor: 'white', height: '60px' }}
      ></div>
    </div>
  );
}
