import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';

export default function Loading(props) {
  return (
    <>
      {props.from === 'viewResource' ? (
        <Box sx={{ width: '100%', alignSelf: 'center' }}>
          <Typography component="div" variant={'h3'}>
            <Skeleton />
          </Typography>
          <Typography component="div" variant={'h6'}>
            <Skeleton animation={false} />
          </Typography>
          <Typography component="div" variant={'h3'}>
            <Skeleton animation="wave" />
          </Typography>
          <Typography component="div" variant={'h3'}>
            <Skeleton animation="wave" />
          </Typography>
          <Typography component="div" variant={'h3'}>
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton />
          <Typography component="div" variant={'h4'}>
            <Skeleton animation="wave" />
          </Typography>
          <Typography component="div" variant={'h4'}>
            <Skeleton animation="wave" />
          </Typography>
          <Typography component="div" variant={'h4'}>
            <Skeleton animation="wave" />
          </Typography>
          <Skeleton animation="wave" />
        </Box>
      ) : (
        <>
          {props.from === 'surveys' ? (
            <Box sx={{ width: '100%', alignSelf: 'center' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <Box sx={{ width: '90%', alignSelf: 'center' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
            </Box>
          )}
        </>
      )}
    </>
  );
}
