import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Cardarr from './Cardarr';
import { AuthContext } from '../../context/AuthContext';

export default function CardArea(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPartnerInformation/${user?.success?.partnerId}`
      );

      setProfileData(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {});
  const [extra, setExtra] = useState();

  const [selectedArea, setSelectedArea] = useState();
  const [area, setArea] = useState();
  const [profileData, setProfileData] = useState({});
  const [split, setSplit] = useState();
  const [areaSplitItem, setAreaSplit] = useState();
  const [finalString, setfinalString] = useState([]);
  const [selectedSplit, setSelectedSplit] = useState();
  const [selectedProfessional, setSelectedProfessional] = useState([]);
  const [selectedPhysical, setSelectedPhysical] = useState([]);
  const [selectedMental, setSelectedMental] = useState([
    props.profileData?.mental_splits,
  ]);
  const [selectedFinancial, setSelectedFinancial] = useState([]);
  const [selectedSocial, setSelectedSocial] = useState([]);
  const [selectedSocietal, setSelectedSocietal] = useState([]);
  const [selectedProfessionalNew, setSelectedProfessionalNew] = useState('');
  const [selectedPhysicalNew, setSelectedPhysicalNew] = useState('');
  const [selectedMentalNew, setSelectedMentalNew] = useState('');
  const [selectedFinancialNew, setSelectedFinancialNew] = useState('');
  const [selectedSocialNew, setSelectedSocialNew] = useState('');
  const [selectedSocietalNew, setSelectedSocietalNew] = useState('');
  const [state, setState] = useState({
    username: '',
    financial_splits: '',
    professional_splits: '',
    mental_splits: '',
    physical_splits: '',
    social_splits: '',
    societal_splits: '',
  });

  useEffect(() => {
    setState({
      ...state,
      societal_splits: profileData?.societal_splits,
      social_splits: profileData?.social_splits,
      mental_splits: profileData?.mental_splits,
      physical_splits: profileData?.physical_splits,
      professional_splits: profileData?.professional_splits,
      financial_splits: profileData?.financial_splits,
    });
    //  props.profileData?.financial_splits &&
    setSelectedSplit(
      profileData.financial_splits
        ? profileData?.financial_splits?.split(',')
        : ''
    );
    //    props.profileData?.professional_splits &&
    setSelectedProfessional(
      profileData?.professional_splits
        ? profileData?.professional_splits?.split(',')
        : ''
    );
    setSelectedPhysical(
      profileData?.physical_splits
        ? profileData?.physical_splits?.split(',')
        : ''
    );
    setSelectedMental(
      profileData?.mental_splits ? profileData?.mental_splits?.split(',') : ''
    );
    setSelectedSocial(
      profileData?.social_splits ? profileData?.social_splits?.split(',') : ''
    );
    setSelectedSocietal(
      profileData?.societal_splits
        ? profileData?.societal_splits?.split(',')
        : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileData?.financial_splits,
    profileData?.mental_splits,
    profileData?.physical_splits,
    profileData?.professional_splits,
    profileData?.social_splits,
    profileData?.societal_splits,
  ]);

  console.log(selectedSocietal);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getSupportAreas`
      );
      setArea(res.data);
    };

    fetchPosts();
  }, []);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getHelpAreasSplits`
      );

      setSplit(res.data);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    split?.map((item) => {
      selectedArea === item.area && setAreaSplit(() => item.areaSplits);
    });
  }, [selectedArea, split]);
  useEffect(() => {
    setSelectedFinancialNew('');
    setSelectedPhysicalNew('');
    setSelectedProfessionalNew('');
    setSelectedMentalNew('');
    setSelectedSocialNew('');
    setSelectedSocietalNew('');
    setExtra('');
  }, [selectedArea]);

  useEffect(() => {
    if (selectedArea === 'financial') {
      setSelectedFinancialNew(extra);
    }
    if (selectedArea === 'physical') {
      setSelectedPhysicalNew(extra);
    }
    if (selectedArea === 'professional') {
      setSelectedProfessionalNew(extra);
    }
    if (selectedArea === 'social') {
      setSelectedSocialNew(extra);
    }
    if (selectedArea === 'societal') {
      setSelectedSocietalNew(extra);
    }
    if (selectedArea === 'mental') {
      setSelectedMentalNew(extra);
    }
    // console.log(selectedSocialNew)
  }, [extra, selectedArea, selectedSocialNew]);

  useEffect(() => {
    const newLocal = areaSplitItem?.split(', ');
    setfinalString(newLocal);
  }, [areaSplitItem]);
  const saveProfile = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updatePartnerAreaSplits`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        username: `${user?.success.username}`,
        financial_splits: selectedSplit ? selectedSplit?.toString() : '',
        professional_splits: selectedProfessional
          ? selectedProfessional?.toString()
          : '',
        mental_splits: selectedMental ? selectedMental?.toString() : '',
        physical_splits: selectedPhysical ? selectedPhysical?.toString() : '',
        social_splits: selectedSocial ? selectedSocial?.toString() : '',
        societal_splits: selectedSocietal ? selectedSocietal?.toString() : '',
        financial_new_areas: selectedFinancialNew ? selectedFinancialNew : '',
        professional_new_areas: selectedProfessionalNew
          ? selectedProfessionalNew
          : '',
        mental_new_areas: selectedMentalNew ? selectedMentalNew : '',
        physical_new_areas: selectedPhysicalNew ? selectedPhysicalNew : '',
        social_new_areas: selectedSocialNew ? selectedSocialNew : '',
        societal_new_areas: selectedSocietalNew ? selectedSocietalNew : '',
      },
    })
      .then(function (response) {
        window.location.reload();
        // console.log(state);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const [warn, setWarn] = useState();
  const handleClick = (item) => {
    if (props?.services?.includes(item)) {
      // setWarn(true);
      setSelectedArea(item);
    } else {
      setSelectedArea(item);
    }
  };
  const checkCurrent = (item) => {};

  const handleExtra = (e) => {
    setExtra(e.target.value);
    // if (selectedArea === 'financial') {
    // props.setListsub(...props.listsub, extra);
    // }
  };
  const handleMouseOut = () => {
    // console.log(extra);
  };

  return (
    <div className="resourceAreaContainer">
      {/* {warn && (
        <div className="coverall">
          <div className="innerCover">
            <div>
              This wellbeing area is currently being promoted by you. Kindly
              remove this area from the promoting list so that you may add your
              services in this area.
            </div>
            <div className="innerCover-nt">
              <div
                onClick={() => setWarn(false)}
                className="centerButton3 centerbtn centerButton3Remove"
              >
                Ok
              </div>
            </div>
          </div>
        </div>
      )} */}
      <div className="areaTitle titleMain">Your Wellbeing Services</div>
      <div className="areaCenter">
        <div className="centerTitleMain">
          Click on each wellbeing area to promote your services. If you cannot see your service description you can add it below. Service descriptions help us to direct clients and other partners to your services. All the services you select are added to our Wellbeing Services Database and promoted on our website.
        </div>
        <div>
          <div>
            <div
              className={`${
                selectedArea ? 'selectedAreaClicked ' : ''
              } centerIconAndTitleContainer`}
            >
              {area?.map((item, i) => {
                return (
                  <Cardarr
                    key={i}
                    checkCurrent={() => checkCurrent}
                    // warn={warn}
                    // setWarn={setWarn}
                    setState={setState}
                    state={state}
                    selectedSplit={selectedSplit}
                    selectedMental={selectedMental}
                    selectedPhysical={selectedPhysical}
                    selectedProfessional={selectedProfessional}
                    selectedSocial={selectedSocial}
                    selectedFinancial={selectedFinancial}
                    selectedSocietal={selectedSocietal}
                    setSelectedSplit={setSelectedSplit}
                    setSelectedMental={setSelectedMental}
                    setSelectedPhysical={setSelectedPhysical}
                    setSelectedProfessional={setSelectedProfessional}
                    setSelectedSocial={setSelectedSocial}
                    setSelectedFinancial={setSelectedFinancial}
                    setSelectedSocietal={setSelectedSocietal}
                    finalString={finalString}
                    setfinalString={setfinalString}
                    selectedArea={selectedArea}
                    handleClick={() => handleClick(item)}
                    icon={i}
                    item={item}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div>{/* <CardAreaMain/> */}</div>

        <div></div>
      </div>
      <div className="centerButtonContainer">
        {selectedArea && (
          <div className="addAAreas">
            <div className="addtextextra">
              If your service is not listed here, kindly add your service:{' '}
              <input
                onMouseOut={() => handleMouseOut}
                onChange={handleExtra}
                type="text"
                value={extra}
              />
            </div>
            <div className="addmulti">
              ( You can add comma separated multiple areas )
            </div>
          </div>
        )}
        {selectedArea && (
          <div
            className="centerButton3 centerbtn centerbtnService"
            onClick={saveProfile}
          >
            Save
          </div>
        )}
      </div>
    </div>
  );
}
