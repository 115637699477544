import React, { useState } from 'react';
import SurveyBanner2 from '../../assets/images/SurveyBanner2.jpeg';
import kayalogo from '../../assets/images/kayalogo.jpeg';
import '../Login/login.css';
import '../signup/signup.css';
// import { AuthContext } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import axios from 'axios';
import './createSurvey.css';
import person from '../../assets/images/person.jpeg';
import { Checkbox } from '@mui/material';
export default function CreateSurvey() {
  // const [username, setUsername] = useState();
  // const [password, setPassword] = useState();
  // const { dispatch } = useContext(AuthContext);
  // const [error, setError] = useState();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  // const handleClick = async (e) => {
  //   e.preventDefault();

  //   dispatch({ type: 'LOGIN_START' });

  //   await axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_BASE_URL}/api/checkPartnerLogin`,
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //     data: {
  //       username: username,
  //       password: password,
  //     },
  //   })
  //     .then(function (response) {
  //       response.data.success?.partnerId &&
  //         dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
  //       !response.data.success?.partnerId &&
  //         setError('Invalid username and or password');
  //       // console.log(response);
  //     })
  //     .catch(function (error) {
  //       dispatch({ type: 'LOGIN_FAILURE', payload: error });
  //       console.log(error);
  //     });
  // };
  const [state, setState] = useState({});
  const handleUser = (e) => {
    const val = e.target.name;
    setState({
      ...state,
      [val]: e.target.value,
    });
  };
  console.log(state);
  // const handlepass = (e) => {
  //   setPassword(e.target.value);
  //   setError(false);
  // };
  // const [reset, setRest] = useState();

  const [cv, setCv] = useState(null);
  const handlesetCv = (value) => {
    setCv(value.target.files[0]);
    const val = value.target.name;
    setState({
      ...state,
      [val]: value.target.files[0],
    });
  };
  return (
    <div>
      <div className="LoginContainer signupContainer survey-create-container">
        <div className="loginImageConai">
          <img src={SurveyBanner2} alt="loginImageConaiImage" />
        </div>
        <div className="loginLogoContainer">
          <img className="loginLogoContainerImage" src={kayalogo} alt="" />
        </div>
        <div className="heading-container">
          <div>Please register your client's survey details below.</div>
          <div>
            If you select 'Managed by us' you will control how the survey link
            is communicated to your client and only you will control their
            access to the Wellbeing Insight and Planning portal.
          </div>
          <div>
            If you select 'Managed by our client' they will receive a
            verification email when you register the survey followed by their
            portal log in and survey link.
          </div>
        </div>
        <div className="body-container">
          <div className="body-container-left">
            <div className="radio-container">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    sx={{ color: '#89bfc9' }}
                    label="Managed by Us"
                  />
                  <FormControlLabel
                    value="male"
                    sx={{ color: '#89bfc9' }}
                    control={<Radio />}
                    label="Managed by our client"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="survey-create-input">
              <div className="input-lable-sx">
                Please enter your client's contact name
              </div>
              <div className="input-container input-container-survey">
                <input
                  onChange={handleUser}
                  type="text"
                  name="contactName"
                  readOnly={false}
                  placeholder="Name"
                  // ref={username}
                  required
                />
              </div>
            </div>
            <div className="survey-create-input">
              <div className="input-lable-sx">The company they work for</div>
              <div className="input-container input-container-survey">
                <input
                  onChange={handleUser}
                  type="text"
                  name="companyName"
                  readOnly={false}
                  placeholder="Client name"
                  // ref={username}
                  required
                />
              </div>
            </div>

            <div className="survey-create-input">
              <div className="input-lable-sx">Client industry</div>
              <div className="input-container input-container-survey">
                <select name="industry" id="cars" onChange={handleUser}>
                  <option value="volvo">Volvo</option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option selected value="saab">
                    Advertising and marketing
                  </option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
              </div>
            </div>
            <div className="survey-create-input">
              <div className="input-lable-sx">
                How many employees will be surveyed?
              </div>
              <div className="input-container input-container-survey">
                <input
                  onChange={handleUser}
                  type="number"
                  name="employeeNumber"
                  readOnly={false}
                  placeholder="Number of employees"
                  // ref={username}
                  required
                />
              </div>
            </div>
            <div className="survey-create-input">
              <div className="com-reg-d1-r">
                <div className="input-container input-container-logo">
                  <div className="signupLogo">
                    <Button
                      className="signupCVbtnSign"
                      htmlFor="cvFile"
                      variant="contained"
                      style={{
                        backgroundColor: '#2691a3',
                      }}
                    >
                      Upload Your Logo
                      <input type="file" name="logo" onChange={handlesetCv} />
                    </Button>
                    {cv && (
                      <img src={cv ? URL?.createObjectURL(cv) : ''} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="survey-create-input">
              <div className="bottom-text">
                Tick to confirm you have read and understood our Privacy policy
                and Terms and conditions{' '}
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div className="bottom-text2-container">
                <div className="bottom-text2">
                  KAYA will collect your contacts name and their company
                </div>
                <div className="bottom-text2">
                  KAYA will not share this information with any third parties.
                </div>
              </div>
            </div>
            <div className="survey-create-input">
              <Button
                className="signupCVbtnSign"
                htmlFor="cvFile"
                variant="contained"
                style={{
                  width: 'minContent',
                  alignSelf: 'flex-end',
                  textAlign: 'right',
                  minHeight: '50px',
                  backgroundColor: '#FDC003',
                }}
              >
                Register Client Survey
              </Button>
            </div>
          </div>
          <div className="body-container-right">
            <img src={person} alt="" />
          </div>
        </div>
        <div className="loginBottom createSurveyBottom">
          2022 © kayawellbeingindex.com All rights reserved.
        </div>
      </div>
    </div>
  );
}
