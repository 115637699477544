import React from 'react';
import like from '../../assets/images/like.png';
import edit from '../../assets/images/edit-3.png';
import global from '../../assets/images/global.png';
import stickynote from '../../assets/images/stickynote2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faLink,
  faQuestion,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import { faSpa } from '@fortawesome/free-solid-svg-icons';
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { faSquarePollVertical } from '@fortawesome/free-solid-svg-icons';
import { faReplyAll } from '@fortawesome/free-solid-svg-icons';
import { faMessage } from '@fortawesome/free-solid-svg-icons';

export default function CardIconAndTitle(props) {
  // const widt = 305;
  const heig = 135;
  return (
    <div style={{ height: `${heig}px` }} className="cardContainer cardInput">
      {props.image ? (
        <div>
          <div className="imgSidebarP imageoverviewp">
            <img
              src={
                `${process.env.REACT_APP_BASE_URL}/` + props.profileData.logo
              }
              alt=""
            />
          </div>
          <div className="sidebarptitleContainer"></div>
        </div>
      ) : (
        <div className="addLogo">
          {props.icon === 'spa' && (
            <FontAwesomeIcon icon={faSpa} color="#2691a3" size="3x" />
          )}
          {props.icon === 'heart' && (
            <FontAwesomeIcon icon={faHeartPulse} color="#2691a3" size="3x" />
          )}
          {props.icon === 'bull' && (
            <FontAwesomeIcon icon={faBullhorn} color="#2691a3" size="3x" />
          )}
          {props.icon === 'folder' && (
            <FontAwesomeIcon icon={faFolderOpen} color="#2691a3" size="3x" />
          )}
          {props.icon === 'file' && (
            <FontAwesomeIcon icon={faFile} color="#2691a3" size="3x" />
          )}
          {props.icon === 'browser' && (
            <FontAwesomeIcon icon={faLink} color="#2691a3" size="3x" />
          )}
          {props.icon === 'poll' && (
            <FontAwesomeIcon icon={faSliders} color="#2691a3" size="3x" />
          )}
          {props.icon === 'bars' && (
            <FontAwesomeIcon icon={faBarsProgress} color="#2691a3" size="3x" />
          )}
          {props.icon === 'block' && (
            <FontAwesomeIcon icon={faQuestion} color="#2691a3" size="3x" />
          )}
          {props.icon === 'square' && (
            <FontAwesomeIcon
              icon={faSquarePollVertical}
              color="#2691a3"
              size="3x"
            />
          )}
          {props.icon === 'reply' && (
            <FontAwesomeIcon icon={faReplyAll} color="#2691a3" size="3x" />
          )}
          {props.icon === 'messages' && (
            <FontAwesomeIcon icon={faMessage} color="#2691a3" size="3x" />
          )}
          <img src={props.icon === 'edit' ? edit : ''} alt="" />
          <img src={props.icon === 'global' ? global : ''} alt="" />
          <img src={props.icon === 'like' ? like : ''} alt="" />
          <img src={props.icon === 'survey' ? stickynote : ''} alt="" />
          <img src={props.icon === 'actioned' ? stickynote : ''} alt="" />
        </div>
      )}

      <div className="CardInput">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p
            style={{ fontSize: '14px', fontWeight: 'bold' }}
            className="titleMain"
          >
            {props.title}
          </p>
          <p
            style={{ fontSize: '20px', fontWeight: 'bold' }}
            className="countMain"
          >
            {props.servicesProvided}
          </p>
          {props.count && (
            <p
              style={{ fontSize: '20px', fontWeight: 'bold' }}
              className="countMain"
            >
              {props.count ? props.count : 0}
            </p>
          )}
          {props.val && (
            <p
              className="countMain"
              style={{
                fontSize: '15px',
                display: 'flex',
                justifyContent: 'center',
                textDecoration: 'underline',
                fontWeight: 'bold',
                width: '100%',
                wordBreak: 'break-all',
              }}
            >
              {props.val}
            </p>
          )}
          {props.questions && 
          <p className="countMain">{props.questions}</p>}
        </div>
      </div>
    </div>
  );
}
