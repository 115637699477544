import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import '../Views/IconAndWellbeing/iconAndWellbeing.css';
import { AuthContext } from '../../context/AuthContext';
import CardArray from './CardArray';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

export default function CardAreaResource() {
  const [error, setError] = useState(false);
  const [selectedArea, setSelectedArea] = useState();
  const [selectedRadio, setSelectedRadio] = useState();
  const [area, setArea] = useState();
  const [split, setSplit] = useState();
  const [areaSplitItem, setAreaSplit] = useState();
  const [finalString, setfinalString] = useState([]);
  const [selectedSplit, setSelectedSplit] = useState([]);
  const [videolink, setVideoLink] = useState('');
  const [videotitle, setVideoTitle] = useState('');
  const [data, setData] = useState();
  const [pdfTitle, setPdfTitle] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');

  useEffect(() => {
    setData({
      username: `lee@mywellbeingindex.co.uk`,
      wellbeingtype: selectedArea ? selectedArea : '',
      resourcetype:
        selectedRadio === 'pdf' ? 2 : selectedRadio === 'video' ? 1 : '',
      video_url: videolink ? videolink : '',
      title: pdfTitle ? pdfTitle : videotitle,
      videotitle: videotitle ? videotitle : '',
      resourcefile: pdfFile && pdfFile,
      resource_type: type,
      areas_addressed: selectedSplit.toString(),
    });
  }, [
    pdfFile,
    pdfTitle,
    selectedArea,
    selectedRadio,
    selectedSplit,
    videolink,
    type,
    videotitle,
    user?.success?.username,
  ]);
  console.log('type', data);
  const handleRadio = (e) => {
    const { name, value } = e.target;

    setSelectedRadio(value, name);
  };
  useEffect(() => {
    // console.log(loading)
    // console.log(error)
  });
  const handleSave = async () => {
    // eslint-disable-next-line no-lone-blocks
    // setLoading(true)
    (videolink !== '' && videotitle !== '' && !loading) ||
    (pdfTitle !== '' && pdfFile !== null && !loading)
      ? await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/loadIndividualResource`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: data,
        })
          .then(function (response) {
            console.log(response);
            setLoading(false);
            window.location.reload();
            // setOpen(false)
          })
          .catch(function (error) {
            console.log(error);
          })
      : setError(true);
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getSupportAreas`
      );

      setArea(res.data);
    };

    fetchPosts();
  }, []);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getHelpAreasSplits`
      );

      setSplit(res.data);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    split?.map((item) => {
      selectedArea === item.area && setAreaSplit(() => item.areaSplits);
    });
  }, [selectedArea, split]);

  const handleVideoLink = (e) => {
    setVideoLink(e.target.value);
    setError(false);
    setLoading(false);
  };
  const handleVideoTitle = (e) => {
    setVideoTitle(e.target.value);
    setError(false);
    setLoading(false);
  };

  const handlePdf = (e) => {
    setError(false);
    setPdfTitle(e.target.value);
  };
  useEffect(() => {
    const newLocal = areaSplitItem?.split(',');
    setfinalString(newLocal);
  }, [areaSplitItem]);
  const handleClick = (item) => {
    setSelectedArea(item);
    setLoading(false);
    setSelectedSplit([]);
  };

  return (
    <div className="resourceAreaContainer">
      <div className="areaTitle titleMain">Upload Your Wellbeing Resources</div>
      <div className="areaCenter">
        <div className="centerTitleMain">
          Our surveys and wellbeing plans signpost employees to relevant support
          resources. To ensure your clients and prospects get access to your
          support material when they need it please add it below. Upload
          individually by wellbeing area or you can use our bulk upload option
          at the bottom of the page. You can add an unlimited number of web
          links and up to 20 pdf documents.
        </div>
        <div>
          <div className="subitemsContainers">
            <div
              className={`${
                selectedArea ? 'selectedAreaClicked ' : ''
              } centerIconAndTitleContainer`}
            >
              {area?.map((item, id) => {
                return (
                  <CardArray
                    id={id}
                    key={id}
                    selectedSplit={selectedSplit}
                    setSelectedSplit={setSelectedSplit}
                    finalString={finalString}
                    selectedArea={selectedArea}
                    handleClick={() => handleClick(item)}
                    icon={id}
                    item={item}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div>{/* <CardAreaMain/> */}</div>

        {selectedArea && (
          <div className="radiobtn">
            <input
              disabled={pdfTitle}
              value="video"
              name="Sport"
              style={{ marginRight: '9px', width: 'auto' }}
              type="radio"
              onChange={handleRadio}
              id="rates"
            />
            <span>Video/Page Link</span>
            <input
              disabled={videotitle || videolink}
              id="rates"
              value="pdf"
              name="Sport"
              style={{ marginRight: '9px', marginLeft: '28px', width: 'auto' }}
              type="radio"
              onChange={handleRadio}
            />
            <span>PDF (Maximum File Size 5MB)</span>
            <FormControl sx={{ span: { color: 'green' } }} className="!ml-4">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={type}
                row
                onChange={(e) => setType(e.target.value)}
                name="radio-buttons-group"
                className="items-center gap-2"
              >
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  className="!text-xs font-normal"
                  sx={{ color: '#2691a3', fontFamily: 'poppins' }}
                >
                  Resources type
                </FormLabel>
                <FormControlLabel
                  value="free"
                  control={<Radio />}
                  label="Free"
                />
                <FormControlLabel
                  value="paid"
                  control={<Radio />}
                  label="Paid"
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {selectedRadio === 'pdf' && (
          <div className="pdffileUpload">
            <input type="text" placeholder="Enter Title" onChange={handlePdf} />
            <div id="" className="centerButton3 centerbtn">
              <label htmlFor={'pdfFile'}>Upload PDF Resource File</label>
            </div>
            <input
              id={'pdfFile'}
              type="file"
              onChange={(e) => setPdfFile(e.target.files[0])}
              accept=".pdf"
              name={'pdfFile'}
            />
            <p>{pdfFile?.name}</p>
          </div>
        )}
        {selectedRadio === 'video' && (
          <div className="inputResourceContainer">
            <input
              type="text"
              placeholder="Enter Video URL"
              onChange={handleVideoLink}
            />
            <input
              type="text"
              placeholder="Enter Title"
              onChange={handleVideoTitle}
            />
          </div>
        )}

        {/* <button className="centerbtn">Select All</button> */}
      </div>
      <div className="centerButtonContainer handleerrorbtn">
        <div
          style={{ cursor: `${loading ? 'not-allowed' : ''}` }}
          className="rejectButtonActive centerButton3 centerbtn centerbtnerr"
          onClick={handleSave}
        >
          Save
        </div>
        {error ? (
          <div className="errordiv">
            Please enter either Video title and video link or pdf title and file
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
