import React, { useState } from 'react';
export default function CardArrayServices(props) {
  const [isActive, setIsActive] = useState(false);

  const selectSub = (number) => {
    setIsActive(!isActive);
    props.selectedArea === props.selectedSub &&
      props.setListsub(
        props?.listsub?.includes(props?.item)
          ? props?.listsub.filter((n) => n !== props?.item)
          : () => [...props?.listsub, props?.item]
      );
  };

  return (
    <div
      key={props.id}
      className={`${
        props?.listsub?.includes(props?.item) ? 'backAdd ' : ''
      } singleCategory`}
      onClick={() => selectSub(props.item)}
    >
      {props.item}
    </div>
  );
}
