import React from 'react';

export default function ManageLogin(props) {
  const handleNew = (e) => {
    props.setNewPass(e.target.value);
    props.setError(false);
    props.handleChange(e);
  };
  const handleConfirm = (e) => {
    props.setConfirm(e.target.value);
    props.setError(false);
  };
  const handleOld = (e) => {
    props.setOldPass(e.target.value);
    props.setError(false);
  };

  return (
    <div className="flex flex-col gap-4 justify-center items-center w-full">
      <div className="contactTitle titleMain text-center">
        Edit & Manage Login Information
      </div>

      <div className="CardInputuser CardInput cardInputContact !w-full mb-2">
        <input
          required
          type="text"
          name="username"
          readOnly
          autoComplete="false"
          defaultValue={props?.LoginData[0]}
          className="!w-full"
        />
        <label>Username</label>
      </div>
      <div className="CardInput cardInputContact !w-full mb-2">
        <input
          style={{
            borderBottom: `${
              props.oldPass !== props.profilePassword ? '2px solid red' : ''
            }`,
          }}
          autoComplete="false"
          required
          type="password"
          name="password"
          // defaultValue={props?.LoginData[1]}
          onChange={handleOld}
          className="!w-full"
        />
        <label>Enter old Password</label>
      </div>
      <div className="CardInput cardInputContact !w-full mb-2">
        <input
          style={{
            borderBottom: `${
              props.newPass !== props.confirm || props.newPass?.length < 6
                ? '2px solid red'
                : ''
            }`,
          }}
          autoComplete="false"
          type="password"
          name="password"
          onChange={handleNew}
          className="!w-full"
        />
        <label>New Password</label>
      </div>
      <div className="CardInput cardInputContact !w-full mb-2">
        <input
          style={{
            borderBottom: `${
              props.newPass !== props.confirm || props.newPass?.length < 6
                ? '2px solid red'
                : ''
            }`,
          }}
          autoComplete="false"
          type="password"
          name="password"
          min={4}
          className="!w-full"
          onChange={handleConfirm}
        />
        <label>Confirm New Password</label>
      </div>
      {(props.newPass || props.confirm) && (
        <div>
          {props.newPass !== props.confirm && (
            <p className="errorpass">password do not match</p>
          )}
          {!/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(
            props.confirm
          ) && (
            <p className="errorpass">
              Password must contain at least one Special Symbol.
            </p>
          )}
          {!/^(?=.*[0-9]).*$/.test(props.confirm) && (
            <p className="errorpass">
              Password must contain at least one Digit.
            </p>
          )}
          {!/^(?=.*[A-Z]).*$/.test(props.confirm) && (
            <p className="errorpass">
              Password must have at least one Uppercase Character.
            </p>
          )}
          {!/^\S*$/.test(props.confirm) && (
            <p className="errorpass">Password must not contain Whitespaces.</p>
          )}
          {props.newPass?.length < 6 && (
            <p className="errorpass">
              password must me greater than 6 character
            </p>
          )}
        </div>
      )}
      {/* {props.error?<div className='errorpass'>Invalid Credentials</div>:<div></div>} */}
      <div className="previewButtons">
        <div
          className="saveButtonPaa rejectButtonActive"
          onClick={props.saveProfile}
        >
          Save New Password
        </div>
      </div>
      {/* <div className="helpButton">
        <div className="navHelpButton">
          <img src={editbutton} alt="" />
        </div>
        <div className="navButtonTitle">
          <p>Edit</p>
        </div>
      </div> */}
    </div>
  );
}
