import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import MuiAlert from '@mui/material/Alert';
export default function Toast(props) {
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const vertical = 'bottom';
  const horizontal = 'right';
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.state?.open}
      autoHideDuration={12000}
      onClose={props.handleClose}
      TransitionComponent={SlideTransition}
      vertical="bottom"
      key={vertical + horizontal}
      horizontal="right"
    >
      <Alert
        onClose={props.handleClose}
        severity="success"
        sx={{ width: '50%' }}
      >
        A confirmation email with you login details has been sent to the email
        address you provided.  Please log in and add your wellbeing resources
        and services. Regards, KAYA Admin
      </Alert>
    </Snackbar>
  );
}
