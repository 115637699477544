import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../components/Views/ListOfResourceView/listOfResources.css';
import SingleCard from './SingleCard';
import { AuthContext } from '../../context/AuthContext';
import AllResource from '../CardResources/AllResource';
import { faCoins, faHandshake } from '@fortawesome/free-solid-svg-icons';

import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Loading from '../Views/Loading';
import moment from 'moment/moment';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Toasst from '../Views/Toasst';
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '10%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Resources Found</Box>
    </StyledGridOverlay>
  );
}
export default function CardViewResource(props) {
  const [viewShow, setViewShow] = useState(false);
  const { user } = useContext(AuthContext);
  const [view, setView] = useState([]);
  const [all, setAll] = useState();

  const [loadingHandle, setLoadingHandle] = useState(false);
  const [loading, setLoading] = useState(true);
  let sortedUsernamesArray = view?.sort(function (a, b) {
    //
    return a.title > b.title ? 1 : -1;
  });
  let isKaya = parseInt(user?.success?.partnerId) === 79;
  const fetchPosts = async () => {
    setLoadingHandle(true);
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/getTrendingResources01/${user?.success?.partnerId}`
    );
    setLoading(false);
    setView(res.data);
    setLoadingHandle(false);
  };
  /* eslint-disable */
  useEffect(() => {
    fetchPosts();
  }, []);
  /* eslint-enable */

  const [open, setOpen] = React.useState(false);
  const confirmDelete = async (item) => {
    console.log(open);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/removeResource`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resourceId: item?.id },
    })
      .then(function (response) {
        console.log(response);
        window.location.reload();
        fetchPosts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleRemove = async (item) => {
    setLoadingHandle(true);
    let selectedApi = isKaya ? 'removeKayaCuratedResource' : 'removeResource';
    if (parseInt(item?.usedcount) > 0) {
      setOpen(true);
    }
    if (parseInt(item?.usedcount) === 0) {
      await axios({
        method: 'post',
        url: isKaya
          ? `${process.env.REACT_APP_BASE_URL}/api/${selectedApi}/${item?.id}`
          : `${process.env.REACT_APP_BASE_URL}/api/${selectedApi}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: { resourceId: item?.id },
      })
        .then(function (response) {
          console.log(response);
          setLoadingHandle(false);

          fetchPosts();
          // window.location.reload();
        })
        .catch(function (error) {
          setLoadingHandle(false);
          console.log(error);
        });
    }
  };

  /* eslint-enable */
  console.log(view);

  const [toastState, setToastState] = React.useState({
    open: false,
  });

  const handleToast = () => {
    setToastState({
      open: !toastState.open,
    });
  };
  const [promoteCount, setCount] = useState(0);
  const countRef = useRef(promoteCount);

  const countFields = (data, name) => {
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].promote === name) {
        count++;
      }
    }
    return count;
  };
  useEffect(() => {
    setCount(countFields(view, '1'));
    countRef.current = promoteCount; // update the ref with the latest count value
  }, [view, promoteCount]);
  const updatePromote = async (item) => {
    ((!isKaya && countRef.current < 6) || isKaya) && setLoadingHandle(true);
    setCount(() => countFields(view, '1'));
    let selectedApi = isKaya
      ? 'updatePromoteInKayaCuratedPool'
      : 'updateResourcePromote';
    (!isKaya && countRef.current < 6) || isKaya
      ? await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/${selectedApi}`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: { resource_id: item.id, promote: '1' },
        })
          .then(function (response) {
            console.log(response);
            fetchPosts();
            setLoadingHandle(false);

            // window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          })
      : handleToast();
  };
  const updateFreePaid = async (e, item) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updateResourcePromote`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resource_id: item.id, resource_type: e.target.value },
    })
      .then(function (response) {
        console.log(response);
        fetchPosts();
        setLoadingHandle(false);

        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleCheck = async (e, item) => {
    // setLoading(true);
    setLoadingHandle(true);

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/addResourceToKayaCuratedPool`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resource_id: item.id, kaya_curated: e.target.checked ? 1 : 0 },
    })
      .then(function (response) {
        console.log(response);
        fetchPosts();
        setLoadingHandle(false);
      })
      .catch(function (error) {
        setLoadingHandle(false);
        console.log(error);
      });
  };
  const updatePromote2 = async (item) => {
    setLoadingHandle(true);
    let selectedApi = isKaya
      ? 'updatePromoteInKayaCuratedPool'
      : 'updateResourcePromote';
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/${selectedApi}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resource_id: item.id, promote: '0' },
    })
      .then(function (response) {
        setLoadingHandle(false);
        console.log(response);
        fetchPosts();
      })
      .catch(function (error) {
        setLoadingHandle(false);
        console.log(error);
      });
  };

  const checkboxStyle = {
    color: '#2691a3', // change this to your desired color
  };
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(1);
  const handleSearchInputChange = (event) => {
    setSelected(1);
    setQuery(event.target.value);
  };
  const handleSearchInputChangeService = (event) => {
    setSelected(2);
    setQuery(event.target.value);
  };
  const filteredItems = sortedUsernamesArray?.filter((item) => {
    return selected === 1
      ? item?.title?.toLowerCase().includes(query.toLowerCase())
      : item?.areas_addressed?.toLowerCase().includes(query.toLowerCase());
  });
  console.log(props.single);

  /**
   *
   *
   *
   *
   *
   *
   * NEW SET
   *
   *
   *
   *
   *  **/
  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Area',
        flex: 0.3,
        renderCell: (params) => {
          return (
            <div className="wellb-area-bm">
              {params.row?.wellbeingtype?.toLowerCase() === 'financial' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faCoins}
                  color="#2691a3"
                />
              )}
              {params.row?.wellbeingtype?.toLowerCase() === 'professional' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faHandshake}
                  color="#2691a3"
                />
              )}
              {params.row?.wellbeingtype?.toLowerCase() === 'physical' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faRunning}
                  color="#2691a3"
                />
              )}
              {params.row?.wellbeingtype?.toLowerCase() === 'mental' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faBrain}
                  color="#2691a3"
                />
              )}
              {params.row?.wellbeingtype?.toLowerCase() === 'social' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faSmile}
                  color="#2691a3"
                />
              )}
              {params.row?.wellbeingtype?.toLowerCase() === 'societal' && (
                <FontAwesomeIcon
                  fontSize={'25px'}
                  icon={faUsers}
                  color="#2691a3"
                />
              )}
            </div>
          );
        },
      },
      {
        field: 'title',
        headerName: 'Resource Name',
        flex: 0.8,
        justifyContent: 'flexStart',
      },
      {
        field: 'partner_name',
        headerName: 'Partner Name',
        flex: 0.8,
        justifyContent: 'left',
        renderCell: (params) => {
          return (
            <div>
              {params.row?.partner_name ? params.row?.partner_name : 'Kaya'}
            </div>
          );
        },
      },
      {
        field: 'updated_on',
        headerName: 'Date Uploaded',
        flex: 0.6,
        renderCell: (params) => {
          return (
            <div>{moment(params.row?.updated_on).format('DD/MM/YYYY')}</div>
          );
        },
      },
      {
        field: 'video_url' || 'document_url',
        headerName: 'Weblink',
        sortable: false,
        flex: 0.3,
        renderCell: (params) => {
          return (
            <a
              href={
                params.row?.video_url
                  ? params.row?.video_url
                  : params.row?.document_url && params.row?.document_url
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="vall">
                {params?.row?.document_url ? 'PDF' : 'Weblink'}
              </div>
            </a>
          );
        },
      },
      {
        field: 'responses',
        headerName: 'Edit',
        sortable: false,
        flex: 0.3,
        renderCell: (params) => {
          return (
            <Button
              variant="contained"
              // disabled={isKaya && params?.row?.partner_name}
              onClick={() => props.handleShow(params?.row)}
              style={{
                padding: '5px',
                backgroundColor: '#2691a3',
                color: '#ffffff',
              }}
            >
              {params.row?.kaya_promoted === '1' ? 'View' : 'Edit'}
            </Button>
          );
        },
      },
      {
        field: 'survey_end_date',
        headerName: 'Remove',
        flex: 0.3,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: '1',
                justifyContent: 'center',
                width: '100%',
                gap: '15px',
                cursor: 'pointer',
              }}
            >
              {!parseInt(params?.row?.promote_count) > 0 && (
                <Button
                  disabled={isKaya && !params?.row?.partner_name}
                  style={{
                    padding: '5px',
                    backgroundColor:
                      isKaya && !params?.row?.partner_name ? '' : '#e9666b',
                    color: '#ffffff', // Text color
                  }}
                  variant="contained"
                  onClick={() => handleRemove(params?.row)}
                >
                  Remove
                </Button>
              )}
            </div>
          );
        },
      },

      {
        field: 'project_description',
        // headerAlign: 'center',
        align: 'center',
        headerName: 'Promote',
        flex: 0.6,
        // sortable: false,
        renderCell: (params) => {
          return (
            <div className="iconcontainer">
              {(isKaya && params?.row?.kaya_curated?.toString() === '1') ||
              (!isKaya && params?.row?.promote?.toString() === '1') ? (
                <Button
                  variant="contained"
                  onClick={() => updatePromote2(params?.row)}
                  disabled={isKaya && !params?.row?.partner_name}
                  style={{
                    padding: '5px',
                    backgroundColor:
                      isKaya && !params?.row?.partner_name ? '' : '#e9666b',
                    color: '#ffffff', // Text color
                  }}
                >
                  Stop Promoting
                </Button>
              ) : (
                <Button
                  disabled={isKaya && !params?.row?.partner_name}
                  variant="contained"
                  onClick={() => updatePromote(params?.row)}
                  style={{
                    padding: '5px',
                    backgroundColor:
                      isKaya && !params?.row?.partner_name ? '' : '#2691a3',
                    color: '#ffffff', // Text color
                  }}
                >
                  Promote
                </Button>
              )}
            </div>
          );
        },
        // valueGetter: (params) =>
        //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      },
      {
        field: 'ww',
        headerName: 'Free/Paid',
        flex: 0.4,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: '1',
                justifyContent: 'center',
                width: '100%',
                gap: '15px',
              }}
            >
              <FormControl sx={{ span: { color: 'green' } }} className="!ml-4">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={params.row?.resource_type?.toString()}
                  onClick={(e) => updateFreePaid(e, params?.row)}
                  name="radio-buttons-group"
                  className="!flex-row"
                >
                  <FormControlLabel
                    className="!mr-0"
                    value="free"
                    control={<Radio size="small" />}
                  />
                  <FormControlLabel
                    className="!mr-0"
                    value="paid"
                    control={<Radio size="small" />}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          );
        },
      },
      {
        field: 'new',
        headerName: 'Add to KAYA',
        flex: 0.4,
        sortable: false,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: 'flex',
                flex: '1',
                justifyContent: 'center',
                width: '100%',
                gap: '15px',
              }}
            >
              <Checkbox
                onClick={(e) => handleCheck(e, params.row)}
                style={checkboxStyle}
                defaultChecked={params.row?.kaya_curated?.toString() === '1'}
              />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filteredColumns = isKaya
    ? columns.filter((col) => col.field !== 'new')
    : columns.filter((col) => col.field !== 'partner_name');
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  return (
    <div className="CardViewResource" style={{ height: '600px' }}>
      <div className="viewTitle titleMain">
        <div>Your Wellbeing Resources</div>
      </div>
      <div className="viewtextContainer">
        <div className="viewtextContainer1">
          {!isKaya
            ? 'Up to 6 resources can be promoted on all employer and employee reports. This could be a link to your website or a training catalogue. All other resources will be promoted based on the linked services. Add your resources to our library and we will promote them in our surveys and other partner services.'
            : // : 'Click the Promote button to promote any partner resource to your clients or add it as part of your curated set of resources.'}
              'To support a holistic wellbeing approach, we recommend that you complement any gaps in your wellbeing services with KAYA generic resources. To use KAYA resources to support your resource gaps please select Promote.'}
        </div>
      </div>
      <div className="icon-search">
        <div className="icon-search-new">
          <TextField
            onChange={handleSearchInputChange}
            variant="outlined"
            label="Search Keyword"
          />
          <TextField
            onChange={handleSearchInputChangeService}
            variant="outlined"
            label="Search Service"
          />
        </div>
      </div>
      {!loading ? (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            // isRowSelectable={(params) => !params?.row?.departments?.length}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              loadingOverlay: LinearProgress,
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            {...columns}
            loading={props.loading || loading || loadingHandle}
            sx={{
              borderWidth: '0px',
              '& .MuiDataGrid-withBorderColor': {
                outline: 'none !important',
                outlineWidth: '0px !important',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none !important',
                outlineWidth: '0px !important',
              },
              '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                color: '#2691a3',
              },

              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                {
                  flex: '1',
                  // display: 'none',
                },
              '& .MuiDataGrid-cell--textLeft': {
                justifyContent: 'center',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center',
                color: '#2691a3',
              },
              '& .css-16c50h-MuiInputBase-root-MuiTablePagination-select': {
                display: 'none',
              },
              '& .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel':
                {
                  display: 'none',
                },
            }}
            rows={filteredItems || []}
            columns={filteredColumns}
            getRowId={(row) => row.id}
            // checkboxSelection
            labelRowsPerPage=""
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            options={{
              paging: false,
            }}
          />
        </div>
      ) : (
        <Loading from={'viewResource'} />
      )}

      <SingleCard
        setShowSingle={props.setShowSingle}
        showSingle={props.showSingle}
        single={props.single}
      />
      <AllResource
        title={all?.title}
        item={all}
        dum={setAll}
        dum2w={confirmDelete}
        setViewShow={setViewShow}
        viewShow={viewShow}
        all={all}
      />
      <Toasst
        handleClose={handleToast}
        state={toastState}
        error="Sorry you can not promote more than 6 resources"
      />
    </div>
  );
}
