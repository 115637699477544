import React from 'react'

export default function CardOverviewEnding(props) {
   const newEnding=props?.ending&& Object.values(props?.ending)
  return (
    <div className='CardOverviewEndingContainer'>
        <div className="endingTitle titleMain">
        Surveys Ending - 6 Weeks
        </div>
        <div className="endingContainer">
            <div className="firstEnding">
                <div className="firstColor">
                {newEnding?newEnding[0]:0}
                </div>
                <div className="firstCounter">
                1
                </div>
            </div>
            <div className="firstEnding">
                <div className="firstColor firstColor2">
                
                {newEnding?newEnding[1]:0}
                </div>
                <div className="firstCounter">
                    2
                </div>
            </div>
            <div className="firstEnding">
                <div className="firstColor firstColor3">
                
                {newEnding?newEnding[2]:0}
                </div>
                <div className="firstCounter">
                    3
                </div>
            </div>
            <div className="firstEnding">
                <div className="firstColor firstColor4">
                {newEnding?newEnding[3]:0}
                </div>
                <div className="firstCounter">
                    4
                </div>
            </div>
            <div className="firstEnding">
                <div className="firstColor firstColor5">
                {newEnding?newEnding[4]:0}
                </div>
                <div className="firstCounter">
                    5
                </div>
            </div>
            <div className="firstEnding">
                <div className="firstColor firstColor6">
            {newEnding ?newEnding[5]:0}
                    
                </div>
                <div className="firstCounter">
                    6
                </div>
            </div>
        </div>

    </div>
  )
}
