import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import './profile.css';
import '../../components/Cards/cards.css';
import '../../components/Card/card.css';

import Footer from '../../components/footer/Index';
import ProfileCards from '../../components/CardProfile/new/Index';

export default function Profile() {
  return (
    <div className="HomeContainer">
      <Sidebar />
      <div className="mainContainers">
        <Navbar />
        <ProfileCards />
        <Footer />
      </div>
    </div>
  );
}
