import { Button, Dialog, Grid } from '@mui/material';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CardWrapper from '../../cardWrapper/Index';
import '../../../components/Card/card.css';
import '../../../components/Cards/cards.css';
import ImageUpload from './cards/image_upload/Index';
import IconLabel from './cards/icon_label/Index';
import SocialMedias from './cards/social_medias/Index';
import GeograpyCoverage from './cards/geography/Index';
import ManageLogin from './cards/edit_login/Index';
import ContactManage from './cards/contact/Index';

import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';
import DescriptionCard from './cards/description/Index';
import Qualifications from './cards/qualifications/Index';
import Testimonials from './cards/testimonials';
import CardProfilePreview from '../../Card/CardProfilePreview';

export default function ProfileCards() {
  const [openFile, setOpenFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedApi, setSelectedApi] = useState('');
  const handleOpenFile = (file, api) => {
    setOpenFile(true);
    setSelectedFile(file?.split(','));
    setSelectedApi(api);
  };
  console.log(selectedFile);
  console.log(selectedApi);
  // console.log('selectedFile', selectedFile?.split(','));
  const closeFile = () => {
    setOpenFile(false);
  };

  const [newPass, setNewPass] = useState();
  const [oldPass, setOldPass] = useState();
  const [confirm, setConfirm] = useState();
  const { user } = useContext(AuthContext);

  const [profileData, setData] = useState({});
  const [areas, setAreas] = useState();
  const nameRef = useRef();
  const [file, setFile] = useState(null);
  const [geographic, setGeographic] = useState();
  const [open, setOpen] = React.useState(false);
  const [provide, setProvide] = useState();
  const [state, setState] = useState({
    // name: nameRef.current?.value,
    username: profileData?.username ? profileData?.username : '',
    name: profileData?.name ? profileData?.name : '',
    first_name: profileData?.first_name ? profileData?.first_name : '',
    surname: profileData?.surname ? profileData?.surname : '',
    email: profileData?.email ? profileData?.email : '',
    phone: profileData?.phone ? profileData?.phone : '',
    geographicareas: profileData?.geographicareas
      ? profileData?.geographicareas
      : '',
    password: profileData?.password ? profileData?.password : '',
    service_online: profileData?.service_online
      ? profileData?.service_online
      : '',
    facebook_handle: profileData?.facebook_handle
      ? profileData?.facebook_handle
      : '',
    twitter_handle: profileData?.twitter_handle
      ? profileData?.twitter_handle
      : '',
    youtube_handle: profileData?.ioutube_handle
      ? profileData?.youtube_handle
      : '',
    picturefile: file ? file : '',
    linkedin_handle: profileData?.iinkedin_handle
      ? profileData?.linkedin_handle
      : '',
    instagram_handle: profileData?.instagram_handle
      ? profileData?.instagram_handle
      : '',
    url: profileData?.url ? profileData?.url : '',
    description: profileData?.description ? profileData?.description : '',
  });
  const [error, setError] = useState(false);
  const saveProfile = async () => {
    /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(confirm) &&
      /^(?=.*[0-9]).*$/.test(confirm) &&
      /^\S*$/.test(confirm) &&
      /^(?=.*[A-Z]).*$/.test(confirm) &&
      oldPass === profileData?.password &&
      newPass === confirm &&
      newPass.length > 6 &&
      setOpen(true);
    // : setError(true);

    /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(confirm) &&
    /^(?=.*[0-9]).*$/.test(confirm) &&
    /^\S*$/.test(confirm) &&
    /^(?=.*[A-Z]).*$/.test(confirm) &&
    oldPass === profileData?.password &&
    newPass === confirm &&
    newPass.length > 6
      ? await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/updatePartner`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: state,
        })
          .then(function (response) {
            // console.log(response);
            setOpen(false);
            // console.log(state);
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          })
      : setError(true);
  };
  const saveProfile2 = async () => {
    setOpen(true)(
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/api/updatePartner`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: state,
      })
        .then(function (response) {
          // console.log(response);
          setOpen(false);
          // console.log(state);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  };
  const handleDelete = async (name, apiName) => {
    try {
      setOpen(true);
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/api/${
          openFile ? selectedApi : apiName
        }`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          username: profileData?.username,
          filename: name,
        },
      }).then(() => {
        setOpen(false);
        window.location.reload();
      });
    } catch (error) {
      setOpen(false);
    }
    // console.log(
    //   `${process.env.REACT_APP_BASE_URL}/api/${
    //     openFile ? selectedApi : apiName
    //   }`,
    //   {
    //     id: user?.success?.partnerId,
    //     filename: name,
    //   }
    // );
  };
  const handleClose = () => {
    setOpen(true);
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getGeographicAreas/${user?.success?.partnerId}`
      );
      setAreas(res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getOverallSummary/${user?.success?.partnerId}`
      );

      // console.log(typeof res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  // ram@mywellbeingindex.co.uk
  // hello_riddlebox.co.uk
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPartnerInformation/${user?.success?.partnerId}`
      );
      //   localStorage.setItem(
      //     'profile',res?.data?.name

      // )
      // setLocal(localStorage.getItem('profile'));
      // console.log(local)

      setData(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);

  useEffect(() => {
    setState({
      username: profileData?.username ? profileData?.username : '',
      name: profileData?.name ? profileData?.name : '',
      first_name: profileData?.first_name ? profileData?.first_name : '',
      surname: profileData?.surname ? profileData?.surname : '',
      email: profileData?.email ? profileData?.email : '',
      phone: profileData?.phone ? profileData?.phone : '',
      password: profileData?.password ? profileData?.password : '',
      service_online: profileData?.service_online
        ? profileData?.service_online
        : '',
      facebook_handle: profileData?.facebook_handle
        ? profileData?.facebook_handle
        : '',
      twitter_handle: profileData?.twitter_handle
        ? profileData?.twitter_handle
        : '',
      youtube_handle: profileData?.youtube_handle
        ? profileData?.youtube_handle
        : '',
      linkedin_handle: profileData?.linkedin_handle
        ? profileData?.linkedin_handle
        : '',
      instagram_handle: profileData?.instagram_handle
        ? profileData?.instagram_handle
        : '',
      picturefile: file ? file : '',
      geographicareas: profileData?.geographicareas
        ? profileData?.geographicareas
        : '',
      url: profileData?.url ? profileData?.url : '',
      description: profileData?.description ? profileData?.description : '',
      testimonial_1: profileData?.testimonial_1
        ? profileData?.testimonial_1
        : '',
      testimonial_2: profileData?.testimonial_2
        ? profileData?.testimonial_2
        : '',
      testimonial_3: profileData?.testimonial_3
        ? profileData?.testimonial_3
        : '',
      professionalMemberships: profileData?.professionalMemberships
        ? profileData?.professionalMemberships
        : [],
      qualifications: profileData?.qualifications
        ? profileData?.qualifications
        : '',
    });
  }, [
    file,
    profileData?.description,
    profileData?.email,
    profileData?.facebook_handle,
    profileData?.first_name,
    profileData?.geographicareas,
    profileData?.instagram_handle,
    profileData?.linkedin_handle,
    profileData?.name,
    profileData?.password,
    profileData?.phone,
    profileData?.service_online,
    profileData?.surname,
    profileData?.twitter_handle,
    profileData?.url,
    profileData?.username,
    profileData?.youtube_handle,
    profileData?.qualifications,
    profileData?.professionalMemberships,
    profileData?.testimonial_1,
    profileData?.testimonial_2,
    profileData?.testimonial_3,
  ]);

  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  useEffect(() => {
    oldPass === profileData?.password &&
      newPass === confirm &&
      newPass > 6 &&
      setState((s) => ({
        ...s,
        password: newPass,
      }));
  }, [confirm, newPass, oldPass, profileData?.password]);

  useEffect(() => {
    setGeographic(
      profileData?.geographicareas
        ? profileData?.geographicareas?.split(',')
        : ''
    );
  }, [profileData?.geographicareas]);
  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      picturefile: file,
    }));
  }, [file]);

  const handleChangelogo = (e) => {
    e.target.files?.length > 0 &&
      (e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpeg' ||
        e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'png' ||
        e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpg') &&
      setFile(e.target.files[0]);
    setState({
      ...state,
      [e.target.name]: file,
    });
    // console.log(state);
  };

  useEffect(() => {
    setProvide(profileData?.service_online);
  }, [profileData]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      service_online: provide,
    }));
  }, [provide]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      geographicareas: geographic?.toString(),
    }));
  }, [geographic]);

  useEffect(() => {}, [profileData]);
  useEffect(() => {
    // console.log(state);
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (name, value) => {
    const files = Array.from(value);
    setSelectedFiles(files);
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    // console.log(e);
    // PostData(`${process.env.REACT_APP_BASE_URL}/api/updatePartner`, {
    //   testimonial: e.target.value,
    // });

    setState({
      ...state,
      [name]:
        name === 'professionalMemberships' || name === 'qualifications'
          ? files
          : value,
    });
    console.log('state', state, value);
  };
  console.log('state', state);

  return (
    <Grid container spacing={2.5} p={4} className="profileCards-c">
      <Grid item md={12}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper className={'justify-center'}>
              <ImageUpload
                name={profileData?.picturefile}
                handleChange={handleChangelogo}
                file={file}
                setFile={setFile}
                image={`${process.env.REACT_APP_BASE_URL}/` + profileData?.logo}
                title="Wellbeing Score"
              />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper>
              <IconLabel
                handleChange={handleChange}
                name={profileData?.name}
                names="name"
                title="Business Name"
                icon="phone"
                nameRef={nameRef}
              />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper>
              <IconLabel
                handleChange={handleChange}
                name={profileData?.url}
                names="url"
                title="Web Address"
                icon="global"
              />
            </CardWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="w-full" md={12}>
        <Grid container className="w-full" spacing={2.5}>
          <Grid item className="w-full" sm={12} md={8}>
            <Grid container className="w-full" spacing={2.5}>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <DescriptionCard
                    handleChange={handleChange}
                    description={profileData?.description}
                  />
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <Qualifications
                    apiName="deleteQualifications"
                    state={state}
                    multiple={true}
                    onChange={handleFileChange}
                    name="qualifications"
                    handleOpenFile={handleOpenFile}
                    selectedFile={selectedFile}
                  />
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <Testimonials
                    apiName="deleteTestimonial_1"
                    handleDelete={handleDelete}
                    state={state}
                    title={'Testimonial 1'}
                    name="testimonial_1"
                    onChange={handleFileChange}
                    handleOpenFile={handleOpenFile}
                    selectedFile={selectedFile}
                  />
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <Testimonials
                    apiName="deleteTestimonial_2"
                    selectedFile={selectedFile}
                    setOpenFile={setOpenFile}
                    handleDelete={handleDelete}
                    state={state}
                    title={'Testimonial 2'}
                    name="testimonial_2"
                    onChange={handleFileChange}
                    handleOpenFile={handleOpenFile}
                  />
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <Testimonials
                    apiName="deleteTestimonial_3"
                    setOpenFile={setOpenFile}
                    selectedFile={selectedFile}
                    handleDelete={handleDelete}
                    state={state}
                    title={'Testimonial 3'}
                    name="testimonial_3"
                    onChange={handleFileChange}
                    handleOpenFile={handleOpenFile}
                  />
                </CardWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CardWrapper>
                  <Testimonials
                    state={state}
                    handleDelete={handleDelete}
                    selectedFile={selectedFile}
                    setOpenFile={setOpenFile}
                    multiple={true}
                    apiName={'deleteProfessionalMemberships'}
                    name="professionalMemberships"
                    title={'Your professional memberships'}
                    onChange={handleFileChange}
                    handleOpenFile={handleOpenFile}
                  />
                </CardWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} flex={1}>
            <CardWrapper style={{ height: '100%' }}>
              <ContactManage
                handleChange={handleChange}
                Contactvalues={[
                  profileData?.first_name,
                  profileData?.surname,
                  profileData?.email,
                  profileData?.phone,
                ]}
              />
            </CardWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper>
              <SocialMedias
                handleChange={handleChange}
                values={[
                  profileData?.facebook_handle,
                  profileData?.twitter_handle,
                  profileData?.instagram_handle,
                  profileData?.youtube_handle,
                  profileData?.linkedin_handle,
                ]}
              />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper>
              <GeograpyCoverage
                geographic={geographic}
                setGeographic={setGeographic}
                setAreas={setAreas}
                areas={areas}
                geographicareas={profileData?.geographicareas}
              />
            </CardWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardWrapper>
              <ManageLogin
                saveProfile={saveProfile}
                setOldPass={setOldPass}
                oldPass={oldPass}
                profilePassword={profileData?.password}
                confirm={confirm}
                newPass={newPass}
                error={error}
                setError={setError}
                setConfirm={setConfirm}
                setNewPass={setNewPass}
                handleChange={handleChange}
                LoginData={[profileData?.username, profileData?.password]}
              />
            </CardWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} className="w-full">
        <CardProfilePreview
          provide={provide}
          setProvide={setProvide}
          profileData={profileData}
          image={`${process.env.REACT_APP_BASE_URL}/` + profileData?.logo}
          open={open}
          handleClose={handleClose}
          saveProfile2={saveProfile2}
        />
      </Grid>
      <Dialog
        open={openFile}
        style={{ borderRadius: '17px' }}
        sx={{
          '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            borderRadius: '17px',
          },
          minHeight: '49vh',
        }}
        onClose={closeFile}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CardWrapper className={'justify-center'} style={{ minHeight: '40vh' }}>
          <div className="flex flex-col gap-3 w-full">
            {selectedFile?.map((text) => (
              <div className="cursor-pointer w-full flex justify-between gap-4">
                <a
                  href={`${process.env.REACT_APP_BASE_URL}/${text}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline w-4/5 overflow-hidden overflow-ellipsis whitespace-nowrap"
                >
                  {text}
                </a>
                <Button
                  onClick={() => handleDelete(text)}
                  variant="contained"
                  size="small"
                  color="error"
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </CardWrapper>
      </Dialog>
    </Grid>
  );
}
