import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import TitleList from '../Modals/TitleLists/TitleList';
import Loading from '../Views/Loading';

export default function CardResourceTrending() {
  const [dataTrend, setData] = useState();
  const [show, setShow] = useState(false);
  const { user } = useContext(AuthContext);
  const [loading,setLoading] = useState(true)
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getTrendingResources/${user?.success?.partnerId}`
      );
      setLoading(false)
      setData(res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);

  return (
    <div className="CardResourceTrending">
      <div className="trendingTitle titleMain">Your Trending Resources</div>
      {!loading ? <div className="trendingContainer">
        {dataTrend?.slice(0,4).map((item,key) => {
          return (
            
            <div key={key} className="trendingListItem">
              <div className="trendTitle">{item.title?item.title:item.document_url?item.document_url:item.video_url}</div>
              <div className="trendCount">{item.promote_count}</div>
            </div>
          );
        })}
      </div>: <Loading/>}
      <div className="textAreaLink" onClick={showModal}>
       {dataTrend&& 'View All'}
      </div>
      <TitleList dataTrend={dataTrend} title="Your Trending Resources" show={show} handleClose={hideModal} />{' '}
    </div>
  );
}
