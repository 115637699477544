import React from 'react';
import '../Modals/TitleLists/titleList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
export default function CardBulkSingle({ type, setType, ...props }) {
  const listIcons = [
    faCoins,
    faSmile,
    faRunning,
    faBrain,
    faUsers,
    faHandshakeSimple,
  ];
  const listIcon = {
    faCoins: 'Financial',
    faSmile: 'Professional',
    faRunning: 'Physical',
    faBrain: 'Mental',
    faUsers: 'Social',
    faHandshakeSimple: 'Societal',
  };
  return (
    <>
      {props.show && (
        <div className="titleContainerModal">
          <div className="TitleListContainer">
            <div className="TitleListTitle">
              Please select the wellbeing Area for this upload.
            </div>

            <div className="bulkContain">
              <div>
                <div
                  className={`${
                    props.wellbeing ? ' hidebackground ' : ''
                  } '   '`}
                >
                  <div className="bulkIconsContainer">
                    {Object.entries(listIcon).map((key, value) => (
                      <div
                        key={value}
                        className="centerIconAndTitle"
                        onClick={() => props.handleMain(key[1])}
                      >
                        <div className="centerIcon">
                          <FontAwesomeIcon
                            icon={listIcons[value]}
                            color={`${'#848484'}`}
                            size="2x"
                            className="iconBulk"
                          />
                        </div>
                        <div className="texttitleBulk centerTitle" id="test">
                          {key[1]}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      marginTop: '15px',
                      fontSize: '25px',
                      fontWeight: 'bold',
                      color: '#ffb934',
                    }}
                  >
                    {props.mainArea}
                  </div>
                  {props.mainArea && props.selected === 'pdf' && (
                    <div className="bulttitle">
                      Click the file add icon below to select the pdf files you
                      wish to add, once selected click Upload.
                    </div>
                  )}
                  {props.mainArea && props.selected === 'video' && (
                    <div className="bulttitle">
                      Click the file add icon below to select the weblink file
                      you wish to add, once selected click Upload.
                    </div>
                  )}
                  {props.mainArea && (
                    <div className="bulkInputContainer">
                      {props.selected === 'pdf' && (
                        <div
                          style={{ marginTop: '20px' }}
                          className="bulkLeftInput"
                        >
                          <div className="buldIconCont">
                            <input
                              type="file"
                              name="uploadBtn1"
                              id="uploadBtnn"
                              accept=".pdf"
                              multiple
                              onChange={(e) => {
                                props.handleFileEvent(e);
                              }}
                            />

                            <label
                              htmlFor="uploadBtn1"
                              onClick={props.handleChoose}
                            >
                              <FontAwesomeIcon
                                icon={faFolderPlus}
                                color="#2691a3"
                                size="1x"
                              />
                            </label>
                          </div>

                          <div className="docTitle">
                            <p className="FileName">Add Documents</p>
                          </div>
                        </div>
                      )}
                      {props.selected === 'video' && (
                        <div
                          style={{ marginTop: '20px' }}
                          className="bulkRightInput"
                        >
                          <div className="buldIconCont">
                            <input
                              type="file"
                              name="uploadBtn"
                              accept=".xlsx,.xls,.csv"
                              id="uploadBtn2"
                              onChange={props.handleFileSelectVideo}
                            />

                            <label
                              // onClick={props.handleChoose}
                              // onClick={handleChoose}
                              htmlFor="uploadBtn2"
                            >
                              <FontAwesomeIcon
                                icon={faVideo}
                                color="#2691a3"
                                size="1x"
                              />
                            </label>
                          </div>
                          <div className="docTitle">
                            <p className="FileName">Add Web link file</p>
                          </div>
                        </div>
                      )}
                      {/* <CardBulkSingle/> */}
                    </div>
                  )}
                  <div style={{ color: '#ffb934', fontSize: '12px' }}>
                    {(props.uploadedFiles.length || props.selectedFileVideo) &&
                      'File(s) selected'}
                  </div>
                </div>
              </div>
              <div className="sucessmessage">
                {props.message && props.message}
              </div>
              {props.mainArea && (
                <FormControl
                  sx={{ span: { color: 'green' } }}
                  className="!ml-4"
                >
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    className="!text-xl"
                  >
                    Resources type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={type}
                    row
                    onChange={(e) => setType(e.target.value)}
                    name="radio-buttons-group"
                    className="justify-center"
                  >
                    <FormControlLabel
                      value="free"
                      control={<Radio />}
                      label="Free"
                    />
                    <FormControlLabel
                      value="paid"
                      control={<Radio />}
                      label="Paid"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {props.mainArea && (
                  <div
                    onClick={props.handleSubmit}
                    className="centerButton3 centerbtn"
                  >
                    {!props.isLoading
                      ? `${props.uploadMessage}`
                      : 'Uploading...'}
                  </div>
                )}
              </div>
              <div
                className="TitleListClose rejectButtonActive"
                onClick={function (event) {
                  props.handleShow();
                  props.handlereload();
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
