import axios from 'axios';
import { useEffect, useState } from 'react';

export const loginCall = async (userCredential, dispatch) => {
  dispatch({ type: 'LOGIN_START' });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/checkPartnerLogin`,
      userCredential
    );

    dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE', payload: err });
  }
};
export const logOutCall = async (dispatch) => {
  dispatch({ type: 'LOGIN_START' });
  try {
    dispatch({ type: 'LOGOUT' });
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE', payload: err });
  }
};
const useFetch = (url, refetch) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(url);
        setData(res.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    fetchData();
  }, [url, refetch]);
  const reFetch = async () => {
    setError({});
    setLoading(true);
    try {
      const res = await axios.get(url);
      setData(res.data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };
  return { data, loading, error, reFetch };
};
export const PostData = async (url, data) => {
  try {
    await axios.post(url, data);
  } catch (err) {
    console.log(err);
  }
  // return { message, loading, error };
};

export default useFetch;
