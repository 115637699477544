import React, { useContext, useEffect, useState } from 'react';
import './titleList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'; // <-- import styles to be used
import { faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { AuthContext } from '../../../context/AuthContext';
import { Stack } from '@mui/system';
export default function TitleList(props) {
  const { user } = useContext(AuthContext);
  const [dataTrend, setData] = useState();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getTrendingResources/${user?.success?.partnerId}`
      );

      setData(res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  const saveProfile3 = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/CompanyApi/SetCompanyAdminByPartner`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { ...contact, company_id: props.company_id },
    })
      .then(function (response) {
        props.setCon(false);
        // console.log(response);
        window.location.reload()
        // console.log(state);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const saveProfile2 = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/sendContactEmail`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: state,
    })
      .then(function (response) {
        props.setCon(false);

        // console.log(response);
        // console.log(state);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [contact, setContact] = useState({});
  const [state, setState] = useState({});
  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  function handleChange2(evt) {
    const value = evt.target.value;
    setContact({
      ...contact,
      [evt.target.name]: value,
    });
    console.log({ ...contact, company_id: props.company_id });
  }
  useEffect(() => {
    // console.log(state);
  }, [state]);
  return (
    <>
      {props.show && (
        <div className="titleContainerModal">
          <div className="TitleListContainer">
            <div className="TitleListTitle">
              {props.title ? props.title : 'Title Here'}
            </div>
            <div
              className={`${
                props.wellbeing ? ' hidebackground ' : ''
              } ' TitleListBody TitleListBodyContact '`}
            >
              {props.item?.map((item, id) => (
                <div key={id} style={{ width: '90%', paddingTop: '20px' }}>
                  <div className="listofwellbeing listofwellbeingjj">
                    <div className="contactj">
                      <div style={{ color: '#2691a3' }}>Name:</div>
                      <div>{item.manager_name}</div>
                    </div>
                    <div className="contactj">
                      <div style={{ color: '#2691a3' }}>Email:</div>
                      <div>{item.email}</div>
                    </div>
                    <div className="contactj">
                      <div style={{ color: '#2691a3' }}>Message:</div>
                      <div>{item.message}</div>
                    </div>
                  </div>
                </div>
              ))}
              {props.resources?.map((item, id) => {
                return (
                  <div className="listResourceContainer" key={id}>
                    <div className="listResourceTitle">{item.title}</div>

                    <div className="fffffff">
                      {item.areas_addressed?.split(',').map((index, id) => {
                        return (
                          <div className="resourceCategory">
                            {index.toString()}{' '}
                          </div>
                        );
                      })}
                    </div>
                    <div className="resourceIconContainer">
                      {!parseInt(item.promote_count) > 0 && (
                        <div className="removeIcon">Remove</div>
                      )}
                      <div>
                        {parseInt(item.promote_count) < 0 && (
                          <FontAwesomeIcon
                            icon={faThumbsUp}
                            color="#2691a3"
                            size="2x"
                          />
                        )}
                      </div>
                      <div className="iconandrating">
                        <div className="iconcontainer">
                          {item.rate > 0 && (
                            <FontAwesomeIcon
                              icon={faStar}
                              color="#2691a3"
                              size="1x"
                            />
                          )}
                        </div>
                        <div className="rating">{item.rate}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {props.wellbeing && (
                <div className="topWellbeingContainer topWellbeingContainerView">
                  <ul>
                    {props.wellbeing &&
                      Object.keys(props.wellbeing).map((key, index) => {
                        return (
                          <li key={index}>
                            <div>
                              <p className="keyTitle">{key}</p>

                              <p>{props.wellbeing[key]}</p>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
              {props?.title === 'Your Trending Resources' && (
                <div className="trendingContainerRes" style={{ width: '90%' }}>
                  <div className="trendingContainer poptrend">
                    {dataTrend?.map((item) => {
                      return (
                        <div className="trendingListItem">
                          <div className="trendTitle">{item.title}</div>
                          <div className="trendCount">{item.promote_count}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {props.challenge &&
                props.challenge.map((keys, values) => (
                  <div
                    key={keys}
                    className="challendeArea challendeAreaListChann"
                  >
                    <div className="trendingListItem trendingListItemPop trendingListItemPopTest">
                      <div className="trendTitle">{keys[0]}</div>
                      <div className="trendCount">{keys[1]}</div>
                    </div>
                  </div>
                ))}
              {props.description && (
                <div className="descPopUp">
                  <p>{props.description}</p>
                </div>
              )}
              {props.help && (
                <div className="descPopUp">
                  <div className="helpContain">
                    <div className="feelinghelp">
                      <ul>
                        <li>
                          <strong>Feelings about work:</strong> Distribution of
                          how the company or team are feeling about their work.
                        </li>
                        <li>
                          <strong>Negative feelings: </strong>
                          These are the top 10 negative feelings for the company
                          or a team
                        </li>
                        <li>
                          <strong>Performance issues: </strong>These are the top
                          10 work impacts as reported by the team or by the
                          company.
                        </li>
                      </ul>
                    </div>
                    <div className="helpperma">
                      <div className="helpptitle">PERMA EXPLANATION</div>
                      <div className="helppcont">
                        <ul>
                          <li>
                            {' '}
                            <strong>Positive Emotions: </strong>Are the
                            foundation of wellbeing. When you trust that your
                            actions are being channelled effectively, you become
                            more positive about the present and future.{' '}
                          </li>
                          <li>
                            {' '}
                            <strong>Engagement:</strong> When we are truly
                            engaged in something we experience a feeling of
                            "oneness". We all need something in our lives that
                            entirely absorbs is into the present.
                          </li>
                          <li>
                            {' '}
                            <strong>Relationship quality: </strong>Relationships
                            are social connections. Humans are social animals
                            and thrive on connection, love, intimacy and a
                            strong emotional interaction with others.
                          </li>
                          <li>
                            {' '}
                            <strong>Meaning: </strong>Having a purpose and
                            meaning heavily contributes to a happy and
                            fulfilling life. Beyond material accomplishments,
                            humans yearn to be part of 'something bigger'.
                          </li>
                          <li>
                            {' '}
                            <strong>Accomplishment: </strong>Having goals and
                            ambition in life can help is to achieve things that
                            give us a sense of accomplishment. Feelings of pride
                            and accomplishment occur when goals are met.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="feelinghelp">
                      <ul>
                        <li>
                          <strong>Positive feelings:</strong> These are the top
                          10 positive feelings for the company or a team.
                        </li>
                        <li>
                          <strong>Top wellbeing issues: </strong> These are the
                          top 10 wellbeing impacts on performance
                        </li>
                        <li>
                          <strong>Wellbeing impact:</strong> This measure
                          provides a view of how feeling is spread across the
                          team.
                        </li>
                      </ul>
                    </div>
                    <div className="helpperma">
                      <div className="helpptitle">Wellbeing types</div>
                      <div className="helppcont">
                        <ul>
                          <li>
                            {' '}
                            <strong>Financial wellbeing </strong>is how people
                            feel about the control they have with their
                            financial future and their relationship with money.
                          </li>
                          <li>
                            {' '}
                            <strong>Professional wellbeing </strong>is how
                            people feel about control they have over their work
                            and career. It is about enjoying what they do
                            everyday.
                          </li>
                          <li>
                            {' '}
                            <strong>Mental wellbeing </strong>is how people feel
                            about the control they have over their minds and
                            their relationship with their feelings and emotions.
                          </li>
                          <li>
                            {' '}
                            <strong>Physical wellbeing </strong>is how people
                            feel about the control they have over their physical
                            health, and their relationship with their bodies.
                          </li>
                          <li>
                            {' '}
                            <strong>Social wellbeing </strong>is how people feel
                            about the control they have over their relationships
                            and the extent of these relationships.
                          </li>
                          <li>
                            {' '}
                            <strong>Societal wellbeing </strong>is how people
                            feel valued as a member of the society, and how they
                            connect to the wider social environment.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {props.Surveycontact && (
                <div style={{ width: '100%', height: '100%' }}>
                  {(
                    <div className="sucessCont">
                      <div class="form">
                        <div class="input-container ic1">
                          <input
                            id="contact_name"
                            name="first_name"
                            class="input"
                            required
                            type="text"
                            placeholder=" "
                            onChange={handleChange2}
                          />
                          <div class="cut"></div>
                          <label for="contact_name" class="placeholder">
                            First Name
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_email"
                            name="surname"
                            class="input"
                            required
                            type="email"
                            placeholder=" "
                            onChange={handleChange2}
                          />
                          <div class="cut"></div>
                          <label for="contact_email" class="placeholder">
                            Surname
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_phone"
                            required
                            name="title"
                            class="input"
                            type='text'
                            placeholder=" "
                            onChange={handleChange2}
                          />
                          <div class="cut cut-short"></div>
                          <label for="contact_phone" class="placeholder">
                            Title
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_text"
                            name="email"
                            class="input"
                            type="text"
                            required
                            placeholder=" "
                            onChange={handleChange2}
                          />
                          <div class="cut cut-short"></div>
                          <label for="contact_text" class="placeholder">
                            Email
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_text"
                            name="contact_phone"
                            class="input"
                            type="number"
                            required
                            placeholder=" "
                            onChange={handleChange2}
                          />
                          <div class="cut cut-short"></div>
                          <label for="contact_text" class="placeholder">
                            Phone
                          </label>
                        </div>

                        <button onClick={saveProfile3} class="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  // ) 
                  // : (
                  //   <div className="sucessCont">
                  //     <Stack sx={{ width: '100%' }} spacing={2}>
                  //       <Alert severity="success">
                  //         <AlertTitle>Success</AlertTitle>
                  //         <strong> Thank you.</strong>The contact request is
                  //         sent.
                  //       </Alert>
                  //     </Stack>
                  //   </div>
                  )}
                </div>
              )}
              {props.contact && (
                <div style={{ width: '100%', height: '100%' }}>
                  {props.con ? (
                    <div className="sucessCont">
                      <div class="form">
                        <div class="input-container ic1">
                          <input
                            id="contact_name"
                            name="contact_name"
                            class="input"
                            required
                            type="text"
                            placeholder=" "
                            onChange={handleChange}
                          />
                          <div class="cut"></div>
                          <label for="contact_name" class="placeholder">
                            Name
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_email"
                            name="contact_email"
                            class="input"
                            required
                            type="email"
                            placeholder=" "
                            onChange={handleChange}
                          />
                          <div class="cut"></div>
                          <label for="contact_email" class="placeholder">
                            Email
                          </label>
                        </div>
                        <div class="input-container ic2">
                          <input
                            id="contact_phone"
                            required
                            name="contact_phone"
                            class="input"
                            type="number"
                            placeholder=" "
                            onChange={handleChange}
                          />
                          <div class="cut cut-short"></div>
                          <label for="contact_phone" class="placeholder">
                            Phone ( optional )
                          </label>
                        </div>
                        <div class="input-container ic2 inputdesccont">
                          <textarea
                            id="contact_text"
                            name="contact_text"
                            class="input"
                            type="text"
                            required
                            placeholder=" "
                            onChange={handleChange}
                          />
                          <div class="cut cut-short"></div>
                          <label for="contact_text" class="placeholder">
                            Description
                          </label>
                        </div>

                        <button onClick={saveProfile2} class="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="sucessCont">
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="success">
                          <AlertTitle>Success</AlertTitle>
                          <strong> Your message has been sent.</strong> One of
                          the team will get back to you in 24 hours.
                        </Alert>
                      </Stack>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className="TitleListClose rejectButtonActive"
              onClick={props.handleClose}
            >
              Close
            </div>
          </div>
        </div>
      )}
    </>
  );
}
