import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

export default function CardSurveyPERMA(props) {
  const [perma, setPerma] = useState();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPermaCountsForPartner/${user?.success?.partnerId}`
      );

      setPerma(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);


  return (
    <div className={`${props.title==="Wellbeing Drivers"? " surveywell ":'' } CardSurveyPERMA titleMain`}>
      <div className="permaTitle titleMain">{props.title?props.title:"Wellbeing Drivers"}</div>
      <div className="permacondaier">
      <div className="permasContainer">
        <div className="permaMainContainer">
          <div className="maintainContainer">
            <div className="maintainTitle">Issues</div>
            {props.perma ? (
              <div className="maintainCheckboxs">
                <div className="mainChecbox">{props?.perma?.P1?props?.perma?.P1:0}</div>
                <div className="mainChecbox">{props?.perma?.E1?props?.perma?.E1:0}</div>
                <div className="mainChecbox">{props?.perma?.R1?props?.perma?.R1:0}</div>
                <div className="mainChecbox">{props?.perma?.M1?props?.perma?.M1:0}</div>
                <div className="mainChecbox">{props?.perma?.A1?props?.perma?.A1:0}</div>
              </div>
            ) : (
              <div className="maintainCheckboxs">
                <div className="mainChecbox">{perma?.P1?perma?.P1:0}</div>
                <div className="mainChecbox">{perma?.E1?perma?.E1:0}</div>
                <div className="mainChecbox">{perma?.R1?perma?.R1:0}</div>
                <div className="mainChecbox">{perma?.M1?perma?.M1:0}</div>
                <div className="mainChecbox">{perma?.A1?perma?.A1:0}</div>
              </div>
            )}
          </div>
        </div>
        <div className="permaMainContainer">
          <div className="maintainContainer">
            <div className="maintainTitle">Risk</div>
            {props.perma ? (
              <div className="maintainCheckboxs">
                <div className="supportChecbox">{props?.perma?.P2?props?.perma?.P2:0}</div>
                <div className="supportChecbox">{props?.perma?.E2?props?.perma?.E2:0}</div>
                <div className="supportChecbox">{props?.perma?.R2?props?.perma?.R2:0}</div>
                <div className="supportChecbox">{props?.perma?.M2?props?.perma?.M2:0}</div>
                <div className="supportChecbox">{props?.perma?.A2?props?.perma?.A2:0}</div>
              </div>
            ) : (
              <div className="maintainCheckboxs">
                <div className="supportChecbox">{perma?.P2?perma?.P2:0}</div>
                <div className="supportChecbox">{perma?.E2?perma?.E2:0}</div>
                <div className="supportChecbox">{perma?.R2?perma?.R2:0}</div>
                <div className="supportChecbox">{perma?.M2?perma?.M2:0}</div>
                <div className="supportChecbox">{perma?.A2?perma?.A2:0}</div>
              </div>
            )}
          </div>
        </div>
        <div className="permaMainContainer">
          <div className="maintainContainer">
            <div className="maintainTitle"></div>
            <div className="maintainCheckboxs">
              <div className="asupportChecbox">P</div>
              <div className="asupportChecbox">E</div>
              <div className="asupportChecbox">R</div>
              <div className="asupportChecbox">M</div>
              <div className="asupportChecbox">A</div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
