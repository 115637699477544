import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';

export default function SocialMedias(props) {
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="socialHeader titleMain text-center">
        Social Media Handles
      </div>
      <div className="iconAndInputContainer">
        <div className="iconAndInput">
          <div className="iconContainer">
            <FacebookIcon className="social-handles social-handles-facebook" />
          </div>
          <div className="inputContainer !w-full">
            <input
              type="text"
              name="facebook_handle"
              onChange={props.handleChange}
              defaultValue={props.values[0]}
              className="!w-full"
            />
          </div>
        </div>
        <div className="iconAndInput">
          <div className="iconContainer">
            <TwitterIcon className="social-handles social-handles-twitter" />
          </div>
          <div className="inputContainer !w-full">
            <input
              type="text"
              name="twitter_handle"
              onChange={props.handleChange}
              defaultValue={props.values[1]}
              className="!w-full"
            />
          </div>
        </div>
        <div className="iconAndInput">
          <div className="iconContainer">
            <InstagramIcon className="social-handles social-handles-instagram" />
          </div>
          <div className="inputContainer !w-full">
            <input
              type="text"
              name="instagram_handle"
              onChange={props.handleChange}
              defaultValue={props.values[2]}
              className="!w-full"
            />
          </div>
        </div>
        <div className="iconAndInput">
          <div className="iconContainer">
            <YouTubeIcon className="social-handles social-handles-youtube" />
          </div>
          <div className="inputContainer !w-full">
            <input
              name="youtube_handle"
              type="text"
              onChange={props.handleChange}
              defaultValue={props.values[3]}
              className="!w-full"
            />
          </div>
        </div>
        <div className="iconAndInput">
          <div className="iconContainer">
            <LinkedInIcon className="social-handles social-handles-linkeden" />
          </div>
          <div className="inputContainer !w-full">
            <input
              type="text"
              name="linkedin_handle"
              onChange={props.handleChange}
              defaultValue={props.values[4]}
              className="!w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
