import React, { useState } from 'react'
import TitleList from '../Modals/TitleLists/TitleList';

export default function SmallCards(props) {
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  let finalData = props?.areas&&Object.entries(props?.areas)
  return (
    <div style={{borderLeft:`{${props.border === "none" && props.border}}!important`}} className='SmallCardsContainer'>
      <div className="smallCardTitle titleMain">
      {props.title}
      </div>
      <div className="smallBody">

      { props.title!=="Trending Initiatives" && finalData && finalData.slice(0,3).map((keys,id) =>

        <div key={id} className="listofwellbeing" > 
          {/* <p style={{fontSize:'20px',fontWeight:'bold'}}>{keys}</p> */}
    
          <p style={{fontSize:'12px',fontWeight:'bold', textTransform:'uppercase'}}>{keys[0]}</p>
          <p style={{fontSize:'12px',fontWeight:'bold'}}>{keys[1]}</p>
          
         
          {/* <p style={{fontSize:'14px',fontWeight:'bold'}}>{props.title}</p> */}
        </div>
      )
        }
      { props.title==="Trending Initiatives" && finalData && finalData.slice(0,2).map((keys,id) =>

        <div key={id} className="listofwellbeing" > 
          {/* <p style={{fontSize:'20px',fontWeight:'bold'}}>{keys}</p> */}
    
          <p style={{fontSize:'12px',fontWeight:'bold', textTransform:'uppercase'}}>{keys[0]}</p>
          <p style={{fontSize:'12px',fontWeight:'bold'}}>{keys[1]}</p>
          
         
          {/* <p style={{fontSize:'14px',fontWeight:'bold'}}>{props.title}</p> */}
        </div>
      )
        }
      </div>
      <div className="textAreaLink" onClick={showModal}>View All</div>
          <TitleList title={props.title} challenge={finalData} show={show} handleClose={hideModal}/>
    </div>
  )
}
