import React, { useContext, useEffect, useState } from 'react';
import './listofWellbeing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';
export default function ListofWellbeing(props) {
  const { user } = useContext(AuthContext);
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);

  useEffect(() => {
    const updateAreas = async () => {
      props.stop &&
        (await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/updatePromotedAreas/${user?.success.partnerId}`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: {
            username: `${user?.success.username}`,
            promotedAreas: props.promoted2.toString(),
          },
        })
          .then(function (response) {
            props.handleClickOpen();
          })
          .catch(function (error) {
            console.log(error);
          }));
    };
    updateAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = async () => {
    props.setList(props.list.filter((n) => n !== props.item));
    props.setPromoted2(
      props.promoted2.includes(props.item)
        ? props.promoted2.filter((n) => n !== props.item)
        : () => [...props.promoted2, props.item]
    );
    setRed(true);
    setGreen(false);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updatePromotedAreas`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        username: `${user?.success.username}`,
        promotedAreas: props.promoted2.toString(),
      },
    })
      .then(function (response) {
        // console.log(user?.success.username)
        // console.log(props.promoted2.toString())
        props.handleClickOpen();
      })
      .catch(function (error) {
        console.log(error);
      });
    // window.location.reload()
  };
  const handleAdd = async () => {
    props.setList(
      props.list.includes(props.item)
        ? props.list.filter((n) => n !== props.item)
        : () => [...props.list, props.item]
    );
    props.setPromoted2(
      props.promoted2.includes(props.item)
        ? props.promoted2.filter((n) => n !== props.item)
        : () => [...props.promoted2, props.item]
    );
    setGreen(true);
    setRed(false);
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updatePromotedAreas`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        username: `${user?.success?.username}`,
        promotedAreas: props.promoted2.toString(),
      },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchDatas = async () => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/api/updatePromotedAreas`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          username: `${user?.success?.username}`,
          promotedAreas: props.promoted2.toString(),
        },
      });
    };

    fetchDatas();
  }, [props.promoted2, user?.success?.username]);

  return (
    <div
      style={{ backgroundColor: `${red ? 'white' : green ? '#2691a3' : ''}` }}
      key={props.id}
      className={`listContainer listContainerServices ${
        props.promoted2?.includes(props?.item) ? 'bkkkkk' : ''
      }`}
    >
      <div className="ListaddLogo">
        <FontAwesomeIcon
          icon={
            (props.item === 'financial' && faCoins) ||
            (props.item === 'societal' && faUsers) ||
            (props.item === 'professional' && faHandshakeSimple) ||
            (props.item === 'physical' && faRunning) ||
            (props.item === 'mental' && faBrain) ||
            (props.item === 'social' && faSmile)
          }
          color={`${props.selectedArea === props.item ? '#0c7c90' : '#848484'}`}
          style={{
            color: `${
              props.promoted2.includes(props.item) ? 'white' : '#848484'
            }`,
          }}
          size="1x"
        />
      </div>

      <div
        style={{ textTransform: 'capitalize', color: `${!red ? '' : 'black'}` }}
        className="listCardInput"
      >
        <p>{props.item}</p>
      </div>
      {!green && (
        <div onClick={() => handleAdd(props.item)} className="buttonservicess">
          Promote
        </div>
      )}
      {!red && props.promoted2?.includes(props?.item) && (
        <div
          onClick={() => handleRemove(props.item)}
          // onClick={()=>handleAdd(props.item)}
          className="buttonservicess buttonservicessRemove"
        >
          Stop
        </div>
      )}
    </div>
  );
}
