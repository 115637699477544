import React from 'react';

export default function CardOverviewCheck(props) {
  const newFeeling = props?.feelings&& Object.values(props?.feelings)
  return (
    <div className="CardOverviewCheckContainer">
      <div className="overCheckTitle titleMain">Feel About Work</div>
      <div className="feelingContainer">
        <div className="singleFeeling">
          <div className="feelCounter feelColor1">{newFeeling ? props?.feelings["Burnt out"] :0}</div>
          <div className="feelTitle feelColor1">Burn OUt</div>
        </div>
        <div className="singleFeeling">
          <div className="feelCounter feelColor2">{newFeeling ? props.feelings?.Stressed:0}</div>
          <div className="feelTitle feelColor2">Stressed</div>
        </div>
        <div className="singleFeeling">
          <div className="feelCounter feelColor3">{newFeeling ? props?.feelings?.Worried:0}</div>
          <div className="feelTitle feelColor3">Worried</div>
        </div>
        <div className="singleFeeling">
          <div className="feelCounter feelColor4">{newFeeling ? props.feelings?.Frustrated :0}</div>
          <div className="feelTitle feelColor4">Frustrated</div>
        </div>
      <div className="singleFeeling">
          <div className="feelCounter feelColor7">{newFeeling ? props.feelings?.OK:0}</div>
          <div className="feelTitle feelColor7">Ok</div>
        </div>
        <div className="singleFeeling">
          <div className="feelCounter feelColor6">{newFeeling ?  props.feelings?.Good:0}</div>
          <div className="feelTitle feelColor6">Good</div>
        </div>
        <div className="singleFeeling ">
          <div className="feelCounter feelColor5">{newFeeling ?  props.feelings?.Optimistic:0}</div>
          <div className="feelTitle feelColor5">Optimistic</div>
        </div>
      </div>
      <div className="feelingButton">
        {/* <div className="singleFeeling">
          <div className="feelCounter feelColor7">{newFeeling && newFeeling[3]}</div>
          <div className="feelTitle feelColor7">Ok</div>
        </div> */}
      </div>
    </div>
  );
}
