import React, { useContext, useEffect, useState } from 'react';
import text01 from '../../assets/images/text01.png';
import SurveyBanner2 from '../../assets/images/SurveyBanner2.jpeg';
import kayalogo from '../../assets/images/kayalogo.jpeg';
import './login.css';
import { AuthContext } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toast from '../../components/Views/Toast';
import { InputAdornment } from '@mui/material';
import Footer from '../../components/footer/Index';

import { Visibility, VisibilityOff } from '@mui/icons-material';
export default function Login() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState();
  const navigate = useNavigate();
  const [key, setKey] = useState('');

  const date = new Date();
  const year = date.getFullYear();
  const [emailError, setEmailError] = useState(false);
  const handleClose2 = () => {
    setOpen(false);
  };
  const [available, setAvailable] = useState();
  const handleClose3 = async () => {
    navigate('/newPassword', {
      state: { username: username, key: key && key },
    });
  };
  const [toastState, setToastState] = React.useState({
    open: false,
  });
  const { state } = useLocation('');
  const { id } = state || '';
  useEffect(() => {
    id &&
      setToastState({
        open: true,
      });
  }, [id]);
  console.log(id);
  console.log(state);
  const handleCloseToast = () => {
    setToastState({
      open: false,
    });
  };

  const handleClose = async () => {
    (reset === '' || reset === undefined) && setEmailError(true);
    reset &&
      (await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/partners/checkPartner`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          username: reset,
        },
      })
        .then(function (response) {
          response.data?.partner === 'available' && setAvailable(true);
          response.data?.partner === 'not available' && setAvailable(false);
          // // setOpen(false);
          // setOpen2(true)
          console.log(response);
        })
        .catch(function (error) {
          setError(error.message);
          console.log(error);
        }));
  };
  // const username = useRef();
  // const password = useRef();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [code, setCode] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const [errorPassword, setErrorPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handlePassword = (evt) => {
    setError(false);
    setErrorPassword(false);
    setPassword(evt.target.value);
    setShowPassword(false);
  };
  const { dispatch } = useContext(AuthContext);
  const [error, setError] = useState();
  const handleClick = async (e) => {
    e.preventDefault();
    !username && setErrorUsername(true);
    !password && setErrorPassword(true);
    !code && setErrorCode(true);
    username && password && code && setLoading(true);
    dispatch({ type: 'LOGIN_START' });
    console.log({
      username: username,
      password: password,
      code: code,
    });
    username &&
      code &&
      password &&
      (await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/api/checkPartnerLogin`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          username: username,
          password: password,
          accesskey: code,
        },
      })
        .then(function (response) {
          response.data.success?.partnerId &&
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
          !response.data.success?.partnerId &&
            setError('Invalid username and or password');
          setLoading(false);
          // console.log(response);
        })
        .catch(function (error) {
          dispatch({ type: 'LOGIN_FAILURE', payload: error });
          console.log(error);
          setLoading(false);
        }));
  };
  const handleUser = (e) => {
    setError(false);
    setUsername(e.target.value);
    setErrorUsername(false);
  };
  const handleCode = (e) => {
    setError(false);
    setCode(e.target.value);
    setErrorCode(false);
  };
  // const handlepass = (e) => {
  //   setPassword(e.target.value);
  //   setError(false);
  // };
  const [reset, setRest] = useState();
  const changeReset = (e) => {
    setRest(e.target.value);
    setEmailError(false);
  };
  useEffect(() => {
    const handleClick = async (e) => {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/partners/sendPasswordResetKey`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: {
          username: reset,
        },
      })
        .then(function (response) {
          // console.log(response.data?.key);
          setKey(response?.data?.key);
        })
        .catch(function (error) {});
    };
    available === true && handleClick();
  }, [available, reset]);

  const handleEmailClick = () => {
    const emailAddress = 'hello@mywellbeingindex.co.uk';
    const subject = 'Get access code';
    const body = '';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div>
      <div className="LoginContainer LoginContainer-login">
        <div className="loginImageConai">
          <img src={SurveyBanner2} alt="loginImageConaiImage" />
        </div>
        <div className="loginLogoContainer">
          <img className="loginLogoContainerImage" src={kayalogo} alt="" />
        </div>
        <div className="loginMainContainer">
          <div className="loginLeft">
            <div className="form login-form-s">
              <form onSubmit={handleClick}>
                <div className="input-container input-container-login">
                  <label>Username </label>
                  {/* <input
                    onChange={handleUser}
                    type="text"
                    name="username"
                    readOnly={false}
                    // ref={username}
                    required
                  /> */}
                  <Box
                    className="signupInputfields"
                    component="form"
                    sx={{
                      width: '100%',
                    }}
                    noValidate
                  >
                    <TextField
                      sx={{
                        width: '100%',
                      }}
                      id="standard-basic"
                      type="text"
                      error={errorUsername}
                      variant="outlined"
                      name="username"
                      onChange={handleUser}
                    />
                  </Box>
                </div>
                <div className="input-container">
                  <label>Password </label>
                  {/* <input
                    onChange={handlepass}
                    readOnly={false}
                    type="password"
                    name="password"
                    // ref={password}
                    required
                  /> */}
                  <Box
                    className="signupInputfields"
                    component="form"
                    sx={{
                      width: '100%',
                    }}
                    noValidate
                  >
                    <TextField
                      sx={{
                        width: '100%',
                      }}
                      id="standard-basic"
                      type={showPassword ? 'text' : 'password'}
                      variant="outlined"
                      name="password"
                      error={errorPassword}
                      value={password || ''}
                      onChange={handlePassword}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility fontSize="small" />
                              ) : (
                                <VisibilityOff fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </div>
                <div className="input-container input-container-login">
                  <label>Access code </label>
                  {/* <input
                    onChange={handleUser}
                    type="text"
                    name="username"
                    readOnly={false}
                    // ref={username}
                    required
                  /> */}
                  <Box
                    className="signupInputfields"
                    component="form"
                    sx={{
                      width: '100%',
                    }}
                    noValidate
                  >
                    <TextField
                      sx={{
                        width: '100%',
                      }}
                      id="standard-basic"
                      type="text"
                      error={errorCode}
                      variant="outlined"
                      name="code"
                      onChange={handleCode}
                    />
                  </Box>
                </div>
                <Box mt={2} onClick={handleEmailClick}>
                  Get in touch with{' '}
                  <a onClick={handleEmailClick} href="#">
                    hello@mywellbeingindex.co.uk
                  </a>{' '}
                  to get the access code
                </Box>
                <div className="button-container button-container-login">
                  <Button
                    className="signupCVbtnSign"
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    style={{
                      backgroundColor: `${loading ? '' : ' #2691a3'}`,
                    }}
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      padding: '8px 30px',
                      textTransform: 'capitalize',
                      backgroundColor: '#2691a3',
                      '&.css-ke7xld-MuiButtonBase-root-MuiButton-root:hover': {
                        backgroundColor: '#2691a3',
                      },
                    }}
                  >
                    Login
                  </Button>
                  {/* <input type="submit" value="Login" /> */}
                </div>
                <div className="divError">{error}</div>
                <div className="registerLink">
                  <Link to={'/register'}>
                    Don't have an account? Register now.
                  </Link>
                </div>
                <div
                  className="registerLink registerLinkForgot"
                  onClick={() => setOpen(true)}
                >
                  {/* <Link to={'/newPassword'}> */}
                  Forgot Password? Click here.
                  {/* </Link> */}
                </div>
              </form>
              {open2 && (
                <Box sx={{ width: '100%' }}>
                  <Collapse in={open2}>
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpen2(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                      sx={{ mb: 2 }}
                    >
                      Please check your email
                    </Alert>
                  </Collapse>
                  {/* <Button
                        disabled={open}
                        variant="outlined"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Re-open
                      </Button> */}
                </Box>
              )}
            </div>
          </div>
          <div className="loginRight">
            <img className="loginRightImage" src={text01} alt="" />
          </div>
        </div>

        <div
          className="loginBottom loginBottom-main"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Footer />
          </div>
          <div>{year} © kayawellbeingindex.com All rights reserved.</div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset your password, please enter your email here. We will send
            you the relevant link.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={emailError}
            required
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => changeReset(e)}
          />
        </DialogContent>
        {available === true && (
          <Box sx={{ width: '100%' }}>
            <Collapse in={true}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                We have sent the confirmation key to your email address. If your
                email is registered with us you will be able to access the link.
              </Alert>
            </Collapse>
            {/* <Button
                        disabled={open}
                        variant="outlined"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        Re-open
                      </Button> */}
          </Box>
        )}
        {available === false && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">Sorry this username is not found !</Alert>
          </Stack>
        )}
        <DialogActions>
          <Button onClick={handleClose2}>Cancel</Button>
          {!available ? (
            <Button onClick={handleClose}>Submit</Button>
          ) : (
            <Button onClick={handleClose3}>Confirm</Button>
          )}
        </DialogActions>
      </Dialog>
      {<Toast handleClose={handleCloseToast} state={toastState} />}
    </div>
  );
}
