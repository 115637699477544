import React, { useContext, useEffect, useState } from 'react';
import CardAreaSurvey from '../Card/CardAreaSurvey';
import CardIconAndTitle from '../Card/CardIconAndTitle';
import CardSurveyTrends from '../Card/CardSurveyTrends';
import './cardServeys.css';
import CardSurveyPERMA from '../Card/CardSurveyPERMA';
import axios from 'axios';
import CardWellBeing from '../CardsServices/CardWellBeing';
import { AuthContext } from '../../context/AuthContext';
import CardSurveysOld from './CardSurveysOld';
export default function CardServeys() {
  const { user } = useContext(AuthContext);
  const [trend, setTrend] = useState();
  const [wellbeing, setWellbeing] = useState();
  const [topwellbeing, topsetWellbeing] = useState();
  const [serviceloading,setserviceLoading] = useState(true)
  const [toploading,settopLoading] = useState(true)
 useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getWellbeingServices/${user?.success?.partnerId}`
      );
      setserviceLoading(false)
      setWellbeing(res.data);
    };

    fetchPosts();
  },[user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getTopChallenges/${user?.success?.partnerId}`
      );
      settopLoading(false)
      topsetWellbeing(res.data);
    };

    fetchPosts();
  },[user?.success?.partnerId]);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getSurveySummaryForPartner/${user?.success?.partnerId}`
      );
      setTrend(res.data);
    };

    fetchPosts();
  },[user?.success?.partnerId]);

  return (
    <div className="cardsContainer surveyiconas">
      <div className="FirstcardsContainer">
        <CardIconAndTitle
          count={trend?.runningSurveysCount?trend?.runningSurveysCount:0}
          title="Surveys Running"
          icon="bars"
        />
        <CardIconAndTitle
          count={trend?.completedSurveysCount?trend?.completedSurveysCount:0}
          title="Surveys Completed"
          icon="poll"
        />
        <CardIconAndTitle
          count={trend?.surveysNotStarted?trend?.surveysNotStarted:0}
          title="Surveys Not Started"
          icon="square"
        />
      </div>
      <div className="FirstcardsContainer secondCardContainer ResourceRight ResourceRightSurvey">
        <div className="leftContainer leftContainerSurvey">
          <div className="leftAreaUpload">
            <CardAreaSurvey />
            <CardSurveysOld old="old" />
          </div>
        </div>
        <div className="rightContainer rightContainerServey">
          
          <div className="rightUploadContainer">
            <CardWellBeing
              wellbeing={wellbeing}
              desc="Initiatives & Services"
              request="request"
              width={215}
              loading={serviceloading}
              from={'from'}

            />
          </div>
          <div className="rightTrendingContainer surveyIcons">
            <CardWellBeing
              from={'from'}
              loading={toploading}
              wellbeing={topwellbeing}
              desc="Wellbeing Challenges"
              challenge="challenge"
              width={215}
            />
          </div>
          <div className="rightTrendingContainer">
            <CardSurveyPERMA title={'Wellbeing Drivers'} />
          </div>
        </div>
        <CardSurveyTrends />
      </div>

      <div
        className="gap"
        style={{ width: '100%', backgroundColor: 'white', height: '60px' }}
      ></div>
    </div>
  );
}
