import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ListOfSurvey from '../Views/ListOfSurvey/ListOfSurvey';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { AuthContext } from '../../context/AuthContext';
import Loading from '../Views/Loading';
import { InputAdornment, TextField } from '@mui/material';
import { filteredItems } from '../../utils/filter';
import { Search } from '@mui/icons-material';
export default function CardAreaSurvey() {
  /* eslint-disable */

  const [survey, setSurvey] = useState([]);
  const [query, setQuery] = useState('');
  const handleSearchInputChange = (event) => {
    setQuery(event.target.value);
  };

  let sortedUsernamesArray = survey?.sort(function (a, b) {
    //
    return a.company_name > b.company_name ? 1 : -1;
  });
  const filteredSurveys = filteredItems(
    sortedUsernamesArray,
    query,
    'company_name'
  );
  // const [surveyUpdated, setSurveyUpdates] = useState();
  const [loading, setLoading] = useState(true);
  const [surveyCopy, setSurveyCopy] = useState([]);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/getSurveysListForPartner/${user?.success?.partnerId}`
        );
        setSurvey(res.data);

        setLoading(false);
      } catch (error) {}
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    setSurveyCopy(survey);
    setList(survey);
  }, [survey]);
  const [list, setList] = useState([]);
  const handleDelete = async (id) => {
    const res = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/deleteSurvey`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        project_id: id,
      },
    }).then(() => {
      window.location.reload();
      // const newList = surveyCopy.filter((item) => item.project_id !== id);
      // setList(newList);
    });
    console.log(res);
  };
  return (
    <div className="surveyAreaContainer">
      <div className="buttonContainerSurvey">
        <div className="rejectButtonActive centerButton3 centerbtn centerbtn1">
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`${process.env.REACT_APP_BASE_URL}/login/register/${user?.success?.partnerId}`}
          >
            Create Survey
          </a>
        </div>

        {survey?.length > 0 && (
          <div
            className="centerButton3 rejectButtonActive centerbtn centerbtn2"
            onClick={() => window.location.reload()}
          >
            Update Survey
          </div>
        )}
        <a
          href={`${process.env.REACT_APP_BASE_URL}/reports/surveyReport/193002e668758ea9762904da1a22337c`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            onClick={() => console.log(process.env.REACT_APP_DEMO_URL)}
            className="centerButton3 rejectButtonActive centerbtn centerbtn2"
          >
            Download Demo Report
          </div>
        </a>
      </div>
      <Box mb={4}>
        <TextField
          disabled={loading}
          placeholder="Search for company"
          onChange={handleSearchInputChange}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <div className="surveyTitleContainer">
        <div className="surveyTitle">Company</div>
        <div className="surveyTitle surveyTitleLink"></div>
        <div className="surveyTitle surveyTitlecenter">Client Managed</div>
        <div className="surveyTitle surveyTitlecenter">Action</div>
        {/* <div className="surveyTitle surveyTitlecenter"></div> */}
        <div className="surveyTitle surveyTitlecenter surveyTitlecenterEnddate">
          End Date
        </div>
        <div
          style={{ marginLeft: '20px' }}
          className="surveyTitle surveyTitlecenter"
        >
          Expected responses
        </div>
        <div className="surveyTitle surveyTitlecenter">Responses recieved</div>
      </div>
      {!loading ? (
        <div className="surveyTable">
          {filteredSurveys?.length > 0 ? (
            <div className="apiSurvey">
              {filteredSurveys?.map((item, id) => (
                <div key={id}>
                  {loading ? (
                    <Box sx={{ width: '100%' }}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  ) : (
                    <ListOfSurvey
                      item={item}
                      handleDelete={() => handleDelete(item.project_id)}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Box
              className="flex justify-center items-center h-full"
              style={{ color: 'gray' }}
            >
              Survey not found
            </Box>
          )}
        </div>
      ) : (
        <Loading from="surveys" />
      )}
    </div>
  );
}
