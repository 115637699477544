import React, { useEffect, useState } from 'react';
import text01 from '../../assets/images/text01.png';
import './login.css';
import SurveyBanner2 from '../../assets/images/SurveyBanner2.jpeg';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import kayalogo from '../../assets/images/kayalogo.jpeg';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
export default function NewPassword() {
  const [keynew, setkeynew] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpass] = useState('');
  const { state } = useLocation();
  const { username, key } = state;
  const [error, setError] = useState();
  const [finalkey,setfinalKey] = useState()
  useEffect(()=>{
   key&& setfinalKey(key)
  },[key])
  // const { isFetching, dispatch, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(confirmpassword) &&
    /^(?=.*[0-9]).*$/.test(confirmpassword) &&
    /^\S*$/.test(confirmpassword) &&
    /^(?=.*[A-Z]).*$/.test(confirmpassword) &&
    keynew === finalkey &&
    password === confirmpassword &&
    password?.length > 6
      ? await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/partners/updatePasswordReset`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: { username: username, password: password },
        })
          .then(function (response) {
            console.log(response);
            setError('');
            navigate('/login', {
              state: { message: 'Password Changed successfully' },
            });
          })
          .catch(function (error) {
            console.log(error);
          })
      : password !== confirmpassword && password?.length > 6
      ? setError('Password does not match')
      : password === confirmpassword && password?.length <= 6
      ? setError('Password length must be > 6 characters  ')
      : keynew !== finalkey &&
        password === confirmpassword &&
        password?.length > 6
      ? setError('Key does not match ')
      : !/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(
          confirmpassword
        )
      ? setError('Password must container special character')
      : !/^(?=.*[0-9]).*$/.test(confirmpassword)
      ? setError('Password must container a number')
      : !/^\S*$/.test(confirmpassword)
      ? setError('Password can not container blank')
      : !/^(?=.*[A-Z]).*$/.test(confirmpassword) &&
        setError('Password must container one Uppercase');
  };
  useEffect(() => {
    console.log(keynew);
    console.log(finalkey);
    console.log(password);
    console.log(confirmpassword);
  });

  return (
    <div>
      <div className="LoginContainer">
        <div className="loginImageConai">
          <img src={SurveyBanner2} alt="loginImageConaiImage" />
        </div>
        <div className="loginLogoContainer">
          <img className="loginLogoContainerImage" src={kayalogo} alt="" />
        </div>
        <div className="loginMainContainer">
          <div className="loginLeft">
            <div className="form">
              <form onSubmit={handleClick}>
                <div className="input-container">
                  <label>Password reset key </label>
                  <input
                    type="text"
                    name="username"
                    onChange={(e) => setkeynew(e.target.value)}
                    required
                  />
                </div>
                <div className="input-container">
                  <label>New Password </label>
                  <input
                    type="password"
                    name="password"
                    onChange={(e) => setConfirmpass(e.target.value)}
                    required
                  />
                </div>
                <div className="input-container">
                  <label>Confirm password </label>
                  <input
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="button-container">
                  <input type="submit" value="Update Password" />
                </div>
                <div style={{ marginTop: '20px' }}>
                  {error && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="error">{error && error}</Alert>
                    </Stack>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="loginRight">
            <img className="loginRightImage" src={text01} alt="" />
          </div>
        </div>
        <div className="loginBottom">
          2022 © kayawellbeingindex.com All rights reserved.
        </div>
      </div>
    </div>
  );
}
