import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import CardAreaResource from '../Card/CardAreaResource';
import CardBulkUpload from '../Card/CardBulkUpload';
import CardIconAndTitle from '../Card/CardIconAndTitle';
import CardResourcePromote from '../Card/CardResourcePromote';

import CardResourceTrending from '../Card/CardResourceTrending';
import CardViewResource from '../Card/CardViewResource';
import './cardResources.css';
export default function CardResources() {
  const { user } = useContext(AuthContext);
  const [trend, setTrend] = useState();
 

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getResourcesSummaryForPartner/${user?.success?.partnerId}`
      );

      setTrend(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  const [showSingle, setShowSingle] = useState(false);
  const [single, setSingle] = useState();
  
  const handleShow=(item)=>{
    setShowSingle(true)
    setSingle(item)
  }
  return (
    <div className="cardsContainer cardresourceContainer">
      <div className="FirstcardsContainer">
      <CardIconAndTitle count={trend?.resourcesCount?trend?.resourcesCount:0} title="Total Number Of Resources" icon="folder" />
        <CardIconAndTitle count={trend?.videosCount?trend?.videosCount:0} title="Total URL Links" icon="browser" />
        <CardIconAndTitle count={trend?.documentsCount?trend?.documentsCount:0} title="Total Documents" icon="file" />
     </div>
      <div
        className="FirstcardsContainer secondCardContainer ResourceRight"
      >
        <div className="leftContainer">
          <div className="leftAreaUpload">
            <CardAreaResource services="resource" />
            <CardViewResource setShowSingle={setShowSingle} single={single} showSingle={showSingle} setSingle={setSingle} handleShow={handleShow} />
          </div>
        </div>
        <div className="rightContainer rightmobile">
          <div className="rightUploadContainer">
            <CardBulkUpload />
          </div>
          <div className="rightTrendingContainer">
            <CardResourceTrending />
          </div>
          <div className="rightTrendingContainer">
            <CardResourcePromote/>
          </div>
        </div>
      </div>
      
      <div className="gap" style={{width:'100%',backgroundColor:'white',height:'60px',marginTop:'20px'}}></div>
    </div>
  );
}
