import React from 'react';

export default function CardWrapper({ style, className, children }) {
  return (
    <div
      className={className}
      style={{
        ...style,
        padding: '24px',
        display:'flex',
        height:'100%',
        borderLeft: '17.16px solid #2691a3',
        borderRadius: '17px',
        boxShadow: '1px 4px 10px 1px rgba(0, 0, 0, 0.24)',
      }}
    >
      {children}
    </div>
  );
}
