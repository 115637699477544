import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from 'react-datepicker';
import Tooltip from '@mui/material/Tooltip';

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faLineChart,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import md5 from 'md5-hash';
import moment from 'moment/moment';

export default function ListSurveyOld(props) {
    /* eslint-disable */

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen(false);
    props.handleDelete();
  };
  const [response, setResponse] = useState(props.item.expected_respondents);
  const [startDate, setStartDate] = useState(
    new Date(props.item.survey_end_date)
  );
  useEffect(() => {
    setResponse(
      props.item.expected_respondents && props.item.expected_respondents
    );
  }, [props.expected_respondents, props.item.expected_respondents]);
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const formatDate = (startDate) => {
    return [
      padTo2Digits(startDate?.getDate()),
      padTo2Digits(startDate?.getMonth() + 1),
      startDate?.getFullYear(),
    ].join('/');
  };

  const end = 'survey_end_date[' + props.item.project_id + ']';
  const respondants = 'expected_respondents[' + props.item.project_id + ']';

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const myObject = {};
  useEffect(() => {}, [end, respondants, response, startDate]);

  const handleResponse = async (evt) => {
    const value = evt.target.value;
    myObject[end] = startDate && formatDate(startDate);
    myObject[respondants] = value && value;
    setResponse(value);
    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/api/saveSurveyDetails`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: myObject && myObject,
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  const handleChange = (value, e) => {
    myObject[end] = value && formatDate(value);
    myObject[respondants] = response && response;
    setStartDate(value);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/saveSurveyDetails`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: myObject && myObject,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleReport = (id) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/reports/surveyReport/${md5(id)}`
    );
  };
  const handleReportlight = (id) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/reports/newReport/${md5(id)}`
    );
  };
  const handleSurvey = (id) => {
    id && window.open(`${process.env.REACT_APP_LINK}/surveys/${id}`);
  };
  // eslint-disable-next-line no-redeclare
  function copyToClipboard(item) {
    // e.preventDefault();
    const tempInput = document.createElement('input');
    tempInput.value = item;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
  const [old, setOld] = useState();
  function monthDiff(dateFrom, dateTo) {
    setOld(
      dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }
  useEffect(() => {
    monthDiff(new Date(props.item?.survey_end_date), new Date());
  }, [props.item?.survey_end_date]);
  const completedSurvey =
    moment(new Date(props.item?.survey_end_date), 'dd/MM/yyyy').diff(
      new Date(),
      'days'
    ) < 0;

  return (
    <div>
      {completedSurvey && (
        <>
          <div className="surveyListContainer surveyoldList">
            <div className="surveyListItem companyName">
              <p>{props.item?.company_name || 'Baseline Survey'}</p>
            </div>
            {new Date(props.item.survey_end_date) < new Date() ? (
              <div className="surveyListItem surveyListItemLink copyLinkClick">
                Survey Completed
              </div>
            ) : (
              <div className="surveyListItem surveyListItemLink">
                {}
                <div>
                  <textarea
                    onClick={() => handleSurvey(props.item.project_id)}
                    className="linkCopy"
                    name=""
                    id=""
                    value={`${process.env.REACT_APP_SURVEY_URL}/doSurvey/${md5(
                      props.item?.project_id
                    )}`}
                    readOnly
                  ></textarea>
                </div>
                <div
                  className="copyLinkClick"
                  onClick={() =>
                    copyToClipboard(
                      `${process.env.REACT_APP_SURVEY_URL}/doSurvey/${md5(
                        props.item?.project_id
                      )}`
                    )
                  }
                >
                  copy link
                </div>
              </div>
            )}
            <div className="surveyListItem surveyIconContainerIcon">
              <div
                className="surveyIconContainerl"
                style={{
                  pointerEvents:
                    props.item?.company_name === 'Baseline' ||
                    !props.item?.company_name
                      ? 'none'
                      : 'auto',
                }}
              >
                {props.item.responses <= 0 && (
                  <Tooltip title="Delete">
                    <FontAwesomeIcon
                      className="deleteButtonIcon"
                      icon={faTrash}
                      color={
                        props.item?.company_name === 'Baseline' ||
                        !props.item?.company_name
                          ? 'rgba(0, 0, 0, 0.12)'
                          : 'tomato'
                      }
                      size="1x"
                      onClick={handleClickOpen}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="surveyIconContainerl">
                {
                  <Tooltip title="Access the Client Wellbeing Insight Portal">
                    <a
                      href={`${process.env.REACT_APP_LINK}/surveys/${props.item?.company_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="btncontainerCompany">
                        <FontAwesomeIcon
                          className="deleteButtonIcon"
                          icon={faLineChart}
                          color="#ffb934"
                          size="1x"
                        />
                      </div>
                    </a>
                  </Tooltip>
                }
              </div>
              <div className="surveyIconContainerl">
                {props.item.responses > 0 && (
                  <Tooltip title="Lite Wellbeing report">
                    <div
                      onClick={() => handleReportlight(props.item?.project_id)}
                    >
                      <FontAwesomeIcon
                        className="deleteButtonIcon"
                        icon={faFile}
                        color="#93d3c5"
                        size="1x"
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="surveyIconContainerl">
                {props.item.responses > 0 && (
                  <Tooltip title="Detailed Wellbeing report">
                    <div onClick={() => handleReport(props.item?.project_id)}>
                      <FontAwesomeIcon
                        className="deleteButtonIcon"
                        icon={faFile}
                        color="#2691a3"
                        size="1x"
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="surveyListItem surveyListItemDate">
              <DatePicker
                disabled={new Date(props.item.survey_end_date) < new Date()}
                selected={startDate}
                className="datepickerr"
                onChange={(value, e) => handleChange(value, e)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div
              style={{ marginLeft: '20px' }}
              className="surveyListItem surveyListItemRecieved"
            >
              <p>
                <input
                  style={{
                    width: '40px',
                    textAlign: 'center',
                    color: '#2691a3',
                  }}
                  required
                  type="text"
                  name="expected"
                  onChange={handleResponse}
                  defaultValue={props.item.expected_respondents}
                />
              </p>
              {/* <p>{props.item.expected_respondents}</p> */}
            </div>
            <div className="surveyListItem surveyListItemRecieved">
              <p style={{ width: '40px', textAlign: 'center' }}>
                {props.item.responses}
              </p>
            </div>
          </div>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete This Survey?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to permanently delete this survey? This
                  action Cannot be undone
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose2} autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </div>
  );
}
