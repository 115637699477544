import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';

// import 'font-awesome/css/font-awesome.min.css';
import { AuthContextProvider } from './context/AuthContext';
import { IsopenContextProvider } from './context/isopenContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <React.StrictMode>
      <AuthContextProvider>
        <IsopenContextProvider>
          <App />
        </IsopenContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </React.StrictMode>
);
