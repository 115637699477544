import axios from 'axios';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Star from './Star';

export default function TextArea(props) {
  const [show, setShow] = useState(false);
  const [selectedResource, setSelectedResource] = useState();
  const [selectedResource1, setSelectedResource1] = useState();
  const handleClick = (item) => {
    if (item?.document_url) {
      setSelectedResource(item?.document_url);
      setSelectedResource1(item?.title ? item?.title : item?.document_url);
    }
    if (item?.video_url) {
      setSelectedResource(item?.video_url);
      setSelectedResource1(item?.title ? item?.title : item?.video_url);
    }
    setShow(false);
  };
  const [answer, setAnswer] = useState();
  const [answerUpdate, setAnswerUpdate] = useState();

  const handleChange = (event) => {
    setAnswer(event.target.value);
    setAnswerUpdate(event.target.value);
  };
  const saveQuestionAnswer = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/saveQuestionAnswer`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        id: props.current?.id,
        answer: answer,
        resource_url: selectedResource ? selectedResource : '',
        resource_title: selectedResource1 ? selectedResource1?.toString() : '',
      },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateQuestion = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/saveQuestionAnswer`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        id: props.current?.id,
        answer: answerUpdate,
        resource_url: props.current?.resource_link,
      },
    })
      .then(function () {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleReject = async (id) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/rejectQuestion`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        id: id,
      },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      {props.show && (
        <div className="qTitleListPop">
          {props.from === 'answered' ? (
            <div className="qcontainerpnding">
              <div className="questionTextare">
                <div className="questionTextarea-n">Question</div>
                <div>{props.current.question}</div>
                <div className="questionTextarea-n">Detail Description</div>
                <div>
                  {props.current?.questionDetails
                    ? props.current?.questionDetails
                    : '-'}
                </div>
                <div className="questionTextarea-n">Wellbeing Area</div>
                <div>
                  {props.current?.category ? props.current?.category : '-'}
                </div>
                <div className="questionTextarea-n">Wellbeing Services</div>
                <div>
                  {props.current?.subcategory
                    ? props.current?.subcategory
                    : '-'}
                </div>

                <div className="qFurther">
                  {props.current?.rank &&
                    Array.from(Array(parseInt(props.current?.rank)))?.map(
                      () => <Star />
                    )}
                </div>

                <div className="questionTextarea-n">Answer</div>
                <textarea
                  onChange={handleChange}
                  defaultValue={props.current.answer}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  readOnly={props.current?.rank}
                  placeholder="Advise given by the partner"
                ></textarea>

                <div style={{ fontWeight: 'bold', color: '#2691a3' }}>
                  Resource attached
                </div>
                {props.current.resource_link ? (
                  <div className="hiddenresource hiddenresource2">
                    <a
                      href={props.current.resource_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {
                        props.current?.resource_link?.split('/')[
                          props.current?.resource_link?.split('/')?.length - 1
                        ]
                      }
                    </a>
                  </div>
                ) : (
                  <div className="hiddenresource hiddenresource2">
                    No attached resource
                  </div>
                )}

                {!props.current?.rank && (
                  <div className="buttonContainerSurvey updateunser">
                    <div className="btn" onClick={updateQuestion}>
                      Update
                    </div>
                  </div>
                )}
              </div>
              <div className="cancelContainer">
                <FontAwesomeIcon
                  icon={faXmark}
                  color="#2691a3"
                  fontSize="20px"
                  onClick={() => props.setShow(false)}
                />
              </div>
              <div
                className="TitleListClose TitleListClose22"
                onClick={() => props.setShow(false)}
              >
                Close
              </div>
            </div>
          ) : (
            <div className="qcontainerpnding">
              <div className="questionTextare">
                <div className="questionTextarea-n">Question</div>
                <div>{props.current?.question}</div>
                <div className="questionTextarea-n">Detail Description</div>
                <div>
                  {props.current?.questionDetails
                    ? props.current?.questionDetails
                    : '-'}
                </div>
                <div className="questionTextarea-n">Wellbeing Area</div>
                <div>
                  {props.current?.category ? props.current?.category : '-'}
                </div>
                <div className="questionTextarea-n">Wellbeing Services</div>
                <div>
                  {props.current?.subcategory
                    ? props.current?.subcategory
                    : '-'}
                </div>
                <div className="questionTextarea-n">Answer</div>

                <textarea
                  onChange={handleChange}
                  value={props.answer}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Advise given by the partner"
                ></textarea>
                <div className="questionTextarea-n">Attached Resource</div>
                <div>
                  {selectedResource1 ? selectedResource1?.toString() : '-'}
                </div>
                <div className="buttonContainerSurvey">
                  <div
                    className="rejectButton rejectButtonActive"
                    onClick={() => handleReject(props.current.id)}
                  >
                    Reject
                  </div>
                  <div className="centerButton3 rejectButtonActive centerbtn attachButtonQuestion centerbtn1 attachButtonQuestionff">
                    {show && (
                      <div>
                        <div className=" rejectButtonActive attachResource">
                          <ul>
                            {props.resources?.map((item, id) =>
                              item.video_url !== '' &&
                              item.video_url !== null &&
                              item.wellbeingtype === props.current?.category ? (
                                <li
                                  key={id}
                                  onClick={() => handleClick(item)}
                                  className="listResourcel"
                                >
                                  {item.title ? item.title : item.video_url}
                                </li>
                              ) : (
                                item.wellbeingtype ===
                                  props.current?.category && (
                                  <li
                                    key={id}
                                    onClick={() => handleClick(item)}
                                    className="listResourcel"
                                  >
                                    {item.title
                                      ? item.title
                                      : item.document_url}
                                  </li>
                                )
                              )
                            )}
                          </ul>
                        </div>
                        <div
                          className="TitleListClose"
                          onClick={() => setShow(false)}
                        >
                          Close
                        </div>
                      </div>
                    )}
                    <div onClick={() => setShow(true)}>Attach Resource</div>
                  </div>
                  <div
                    className="centerButton3 centerbtn centerbtn2 rejectButtonActive"
                    onClick={saveQuestionAnswer}
                  >
                    Submit Answer
                  </div>
                </div>
              </div>
              <div className="cancelContainer">
                <FontAwesomeIcon
                  icon={faXmark}
                  color="#2691a3"
                  fontSize="20px"
                  onClick={() => props.setShow(false)}
                />
              </div>
              <div
                style={{  }}
                className="TitleListClose TitleListClose33"
                onClick={() => props.setShow(false)}
              >
                Close
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
