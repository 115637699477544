import React from 'react';

export default function ContactManage(props) {
  return (
    <div className="cardInput flex flex-col gap-4 !w-full">
      <div className="contactTitle titleMain text-center">
        Contact Information
      </div>

      <div className="CardInput cardInputContact !w-full mb-2">
        <input
          required
          type="text"
          name="first_name"
          defaultValue={props?.Contactvalues[0]}
          onChange={props.handleChange}
          className="!w-full"
        />
        <label>First Name</label>
      </div>
      <div className="CardInput  cardInputContact !w-full mb-2">
        <input
          required
          type="text"
          name="surname"
          defaultValue={props?.Contactvalues[1]}
          onChange={props.handleChange}
          className="!w-full"
        />
        <label>Surname</label>
      </div>
      <div className=" CardInputuser CardInput cardInputContact !w-full mb-2">
        <input
          required
          type="text"
          readOnly
          name="email"
          defaultValue={props?.Contactvalues[2]}
          onChange={props.handleChange}
          className="!w-full"
        />
        <label>Email</label>
      </div>
      <div className="CardInput cardInputContact !w-full mb-2">
        <input
          required
          type="text"
          name="phone"
          defaultValue={props?.Contactvalues[3]}
          onChange={props.handleChange}
          className="!w-full"
        />
        <label>Phone</label>
      </div>
    </div>
  );
}
