import React, { useState } from 'react';
import TitleList from '../../../../Modals/TitleLists/TitleList';

export default function DescriptionCard(props) {
  const [show, setShow] = useState(false);
  const hideModal = () => {
    setShow(false);
  };
  return (
    <div className="w-full">
      <div className="textAreaTitle titleMain">
        {props.desc ? props.desc : 'Description'}
      </div>

      <div className="textareaProfiles !w-full">
        <textarea
          style={{
            borderRadius: '17px',
            backgroundColor: 'rgba(85, 143, 211, 0.1)',
          }}
          name="description"
          id=""
          className="w-full p-4 h-24"
          cols="30"
          rows="10"
          defaultValue={props.description}
          onChange={props.handleChange}
        ></textarea>
      </div>
      <TitleList
        description={props.description}
        title="description"
        show={show}
        handleClose={hideModal}
      />
    </div>
  );
}
