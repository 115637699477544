import React, { useContext, useEffect, useState } from 'react';
import CardAreaResourceSplit from './CardAreaResourceSplit';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
export default function Cardarr(props) {
  const { user } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPromotedAreas/${user?.success?.partnerId}`
      );

      setServices(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  const listIcon = [
    faCoins,
    faHandshakeSimple,
    faRunning,
    faBrain,
    faSmile,
    faUsers,
  ];
  const farr = props.finalString;
  
  useEffect(() => {
      props.finalString?.map(
        (item) =>
          props.selectedSplit?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
      props.finalString?.map(
        (item) =>
          props.selectedPhysical?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
      props.finalString?.map(
        (item) =>
          props.selectedProfessional?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
    

      props.finalString?.map(
        (item) =>
          props.selectedSocial?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
    
      props.finalString?.map(
        (item) =>
          props.selectedMental?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
    
  
      props.finalString?.map(
        (item) =>
          props.selectedSocietal?.toString()?.replace( /\s/g, '')?.includes(item?.replace( /\s/g, '')) &&
          farr?.sort(function (x, y) {
            return x === item ? -1 : y === item ? 1 : 0;
          })
        // : setfarr(() => [...props?.finalString, item])
      );
    }
    );
  return (
    <div
      key={props?.id}
      onClick={() => props.checkCurrent}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="centerIconAndTitle" onClick={props.handleClick}>
        <div className="centerIcon">
          <FontAwesomeIcon
            icon={listIcon[props.icon]}
            color={`${
              props.selectedArea === props.item ? '#0c7c90' : '#848484'
            }`}
            size="2x"
          />
        </div>
        <div className="centerTitle" id="test">
          {props.item}
        </div>
      </div>
      <div>
        <div className="mainAndSub">
          {props.selectedArea === props.item && (
            <div
              className="categoryContainer"
             
            >
              {props.finalString?.toString().replace(/\s*,\s*/g, ",").split(',').sort()?.map((item, index) => {
                return (
                  <CardAreaResourceSplit
                  key={index}
                    // warn={props.warn}
                    // setWarn={props.setWarn}
                    services={services}
                    index={index}
                    setState={props.setState}
                    state={props.state}
                    listsub={props.selectedSplit}
                    setListsub={props.setSelectedSplit}
                    item={item}
                    selectedSub={props.item}
                    selectedArea={props.selectedArea}
                    finalString={props.finalString}
                    selectedMental={props.selectedMental}
                    selectedPhysical={props.selectedPhysical}
                    selectedProfessional={props.selectedProfessional}
                    selectedSocial={props.selectedSocial}
                    selectedFinancial={props.selectedFinancial}
                    selectedSocietal={props.selectedSocietal}
                    setSelectedMental={props.setSelectedMental}
                    setSelectedPhysical={props.setSelectedPhysical}
                    setSelectedProfessional={props.setSelectedProfessional}
                    setSelectedSocial={props.setSelectedSocial}
                    setSelectedFinancial={props.setSelectedFinancial}
                    setSelectedSocietal={props.setSelectedSocietal}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
