import axios from 'axios';
import React, { useContext, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { AuthContext } from '../../context/AuthContext';
export default function CardResourcePromote() {
  
  const { user } = useContext(AuthContext);
  const [sucess, setSucess] = useState(false);
  const handleSubmit = async (item) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/sendPurchaseRequest`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { partner_id: user?.success.partnerId },
    })
      .then(function (response) {
        console.log(response);
        setSucess(true);
        // window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="CardResourceTrending cardPromote">
      <div className="trendingTitle titleMain" >
      Would you like to add more of your promotional material to the Wellbeing Reports?
      </div>
      {sucess ? (
       <Stack sx={{ width: '100%' }} spacing={2}>
       
       <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          <strong> Thank you.</strong> Your request to purchase promotion package is sent.
        </Alert>
     </Stack>
      ) : (
        <div className="centerButton3 rejectButtonActive centerbtn centerbtnPromote" onClick={handleSubmit}>
          Send Me Details
        </div>
      )}
    </div>
  );
}
