import React, { useState } from 'react';
import ProfilePreview from '../Modals/ProfilePreview/ProfilePreview';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
export default function CardProfilePreview(props) {
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  return (
    <div className="CardProfilePreviewContainer">
      <div className="previewButtons">
        <div className="previewButton rejectButtonActive" onClick={showModal}>
          Preview
        </div>
        <div
          className="saveButtonP rejectButtonActive"
          onClick={props.saveProfile2}
        >
          Save
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: 2222222 }}
          open={props.open}
          onClick={props.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ProfilePreview
          profileData={props.profileData}
          image={props.image}
          show={show}
          handleClose={hideModal}
        />
      </div>
    </div>
  );
}
