import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import WellbeingList from '../Modals/WellBeingLists/WellbeingList';
import ListofWellbeing from '../Views/ListOfWellbeing/ListofWellbeing';
import Loading from '../Views/Loading';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
export default function CardListofIcon(props) {
  const [show, setShow] = useState(false);
  const [services, setServices] = useState();
  const { user } = useContext(AuthContext);
  const [promoted2, setPromoted2] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [stop, setStop] = useState(false);
  const handleClose2 = () => {
    setOpen(false);
    setStop(true);
    window.location.reload();
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPromotedAreas/${user?.success?.partnerId}`
      );

      setPromoted2(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  console.log(promoted2);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getServicesStatsForPartner/${user?.success?.partnerId}`
      );

      setServices(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  const hideModal = () => {
    setShow(false);
  };
  const [list, setList] = useState(
    props?.services?.areasThatCabBePromoted
      ? props?.services?.areasThatCabBePromoted
      : ''
  );
  useEffect(() => {
    setList(
      services?.areasThatCabBePromoted ? services?.areasThatCabBePromoted : ''
    );
    // console.log(list)
  }, [services]);
  const areas = [
    'financial',
    'professional',
    'physical',
    'mental',
    'social',
    'societal',
  ];
  return (
    <div className="cardListsContainer">
      <div className="listCardTitle titleMain">Wellbeing Areas Promoted</div>
      {!props.loading ? (
        <div className="mainSubContaineraa">
          {areas?.map((item, id) => {
            return (
              <ListofWellbeing
                stop={stop}
                handleClickOpen={handleClickOpen}
                promoted2={promoted2}
                setPromoted2={setPromoted2}
                list={list}
                setList={setList}
                services={services}
                key={id}
                icon={item}
                item={item}
              />
            );
          })}
        </div>
      ) : (
        <Loading />
      )}
      <div className="titleofllll">
        <div>
          Don't worry if you don't support every wellbeing service listed, we
          have you covered. KAYA Wellbeing Support uses resources from
          charities, government bodies and advice groups to supplement your
          resources and ensure every employee is signposted to support. We will
          always promote your resources first (added in the Resources tab) and
          only use ours to fill in the gaps. If you don't want to use KAYA
          resources just click Stop.
        </div>
      </div>

      <WellbeingList
        show={show}
        promoted={props.promoted}
        handleClose={hideModal}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Stop Promote'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are switching off KAYA resource support. Only your resources
            will be used to support survey participants. If you want to restart
            using KAYA Wellbeing Support just click Promote.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
