import React, { useContext, useEffect, useState } from 'react';
import CardIconAndTitle from '../Card/CardIconAndTitle';
import CardSurveyChallenges from '../Card/CardSurveyChallenges';
import './cardQuestions.css';
import CardQuestionRating from '../Card/CardQuestionRating';
import axios from 'axios';
import SmallCardNoBorder from '../Cards/SmallCardNoBorder';
import CardWellBeing from '../CardsServices/CardWellBeing';
import { AuthContext } from '../../context/AuthContext';
import ListPending from './ListPending';
import ListAnswered from './ListAnswered';

export default function CardQuestions() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState();
  const [wellbeing, setWellbeing] = useState();
  const [pendingallQuestions, setpendingAllQuestions] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getQuestionsList/${user?.success?.partnerId}`
      );
      setpendingAllQuestions(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getTopChallenges/${user?.success?.partnerId}`
      );

      setWellbeing(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getOverallSummary/${user?.success?.partnerId}`
      );

      setData(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);

  const [questions, setQuestions] = useState();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getQuestionStats/${user?.success?.partnerId}`
      );
      setQuestions(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);

  return (
    <div className="cardsContainer">
      <div className="FirstcardsContainer">
        <CardIconAndTitle
          questions={
            questions?.questions_asked ? questions?.questions_asked : 0
          }
          title="Questions Asked"
          icon="block"
        />
        <CardIconAndTitle
          questions={
            questions?.questions_answered ? questions?.questions_answered : 0
          }
          title="Questions Answered"
          icon="reply"
        />
        <CardIconAndTitle
          questions={questions?.averate_rating ? questions?.averate_rating : 0}
          title="Average Rating"
          icon="messages"
        />
      </div>
      <div className="FirstcardsContainer secondCardContainer ResourceRight">
        <div className="leftContainer">
          <div className="leftAreaUpload">
            {/* <CardQuestionsArea
              allQuestions={allQuestions}
              setAllQuestions={setAllQuestions}
              setpendingAllQuestions={setpendingAllQuestions}
            /> */}
            <ListPending pendingallQuestions={pendingallQuestions} />
            <ListAnswered />
            <div className="rightContainer questionsStarts">
              <div className="rightUploadContainer">
                <SmallCardNoBorder
                  areas={data?.requestedServices}
                  from="questionPage"
                  title="Service Requested"
                />
              </div>
              <div className="rightTrendingContainer">
                <CardWellBeing
                  from={'from'}
                  wellbeing={wellbeing}
                  desc="Top Wellbeing Challenges"
                  challenge="challenge"
                  width={215}
                />
              </div>
              <div className="rightTrendingContainer">
                <CardSurveyChallenges width="ljk" title="Contact Requests" />
              </div>
            </div>
          </div>
          <CardQuestionRating />
        </div>
      </div>

      <div
        className="gap"
        style={{ width: '100%', backgroundColor: 'white', height: '60px' }}
      ></div>
    </div>
  );
}
