import React, { useState } from 'react';
import TitleList from '../Modals/TitleLists/TitleList';
import Loading from '../Views/Loading'
export default function CardWellBeing(props) {
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  return (
    <div
      className={`${props.from === 'from' ? 'fromwb' : ''} hop cardtextarea cardtextareaServices`}
    >
      <div className="textAreaTitle titleMain">{props.desc}</div>

     {!props.loading? <div className="topWellbeingContainer">
        <ul>
          {props.wellbeing &&
            props.desc === 'Top Wellbeing Services' &&
            Object.keys(props.wellbeing)
              ?.slice(0, 9)
              .map((key, id) => {
                return (
                  <li key={id}>
                    <div>
                      <p className="keyTitle">{key}</p>

                      <p style={{fontWeight:'bold'}}>{props.wellbeing[key]}</p>
                    </div>
                  </li>
                );
              })}
          {props.wellbeing &&
            props.desc === 'Upsell Services' &&
            Object.keys(props.wellbeing)
              ?.slice(0, 9)
              .map((key, id) => {
                return (
                  <li key={id}>
                    <div>
                      <p className="keyTitle">{key}</p>

                      <p style={{fontWeight:'bold'}}>{props.wellbeing[key]}</p>
                    </div>
                  </li>
                );
              })}
          {props.wellbeing &&
           ( props.desc ===  "Services Requested" )&&
            props.request === 'request' &&
            Object.keys(props.wellbeing)
              ?.slice(0, 9)
              .map((key, index) => {
                return (
                  <li key={index}>
                    <div >
                      <p className="keyTitle">{key}</p>

                      <p className="keyTitleCount">{props.wellbeing[key]}</p>
                    </div>
                  </li>
                );
              })}
          {props.wellbeing &&
           ( props.desc === 'Initiatives & Services')&&
            props.request === 'request' &&
            Object.keys(props.wellbeing)
              ?.slice(0, 3)
              .map((key, index) => {
                return (
                  <li key={index}>
                    <div >
                      <p className="keyTitle">{key}</p>

                      <p className="keyTitleCount">{props.wellbeing[key]}</p>
                    </div>
                  </li>
                );
              })}
          {props.wellbeing &&
            props.challenge === 'challenge' &&
            Object.keys(props.wellbeing)
              ?.slice(0, 3)
              .map((key, index) => {
                return (
                  <li key={index}>
                    <div >
                      <p className="keyTitle">{key}</p>

                      <p className="keyTitleCount">{props.wellbeing[key]}</p>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>:<Loading/>}
      {props.wellbeing && <div className="rejectButtonActive textAreaLink" onClick={showModal}>
        View All
      </div>}
      <TitleList
        title={props.desc}
        wellbeing={props.wellbeing}
        show={show}
        handleClose={hideModal}
      />
    </div>
  );
}
