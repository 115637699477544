import React from 'react';

export default function GeograpyCoverage(props) {
  const handleClick = (item) => {
    props.setGeographic(
      props.geographic?.includes(item)
        ? props.geographic.filter((n) => n !== item)
        : () => [...props?.geographic, item]
    );
  };
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="CardProfileGeographicTitle titleMain text-center">
        Geographic Coverage
      </div>
      <div className="geoContainer !w-full">
        <div className="getTitle">
          <div>Selected Area</div>{' '}
          <div className="iconcupp">
            {/* <FontAwesomeIcon icon={faAngleUp} color="#2691a3" fontSize="15px" /> */}
          </div>
        </div>
        <div className="getAreaListContainer">
          {props.areas &&
            Object.values(props.areas)?.map((item, id) => (
              <div
                key={id}
                className={`${
                  props?.geographic?.includes(item) ? 'backAdd ' : ''
                } geoAreaList`}
                onClick={() => handleClick(item)}
              >
                {item}
              </div>
            ))}
          {/* <div className="geoAreaList">Geographic Area2</div>
      <div className="geoAreaList">Geographic Area3</div>
      <div className="geoAreaList">Geographic Area4</div>
      <div className="geoAreaList">Geographic Area4</div> */}
        </div>
      </div>
    </div>
  );
}
