import React, { useState } from 'react';
export default function CardAreaResourceSplit(props) {
  const [isActive, setIsActive] = useState(false);
  // const [main, setMain] = useState(true);

  // useEffect(() => {
  //   setMain(props?.services?.includes(props.selectedArea));
  // }, [props.services, props.selectedArea]);

  const selectSub = (number) => {
    console.log(props.item);
    console.log(props.selectedProfessional);
    // main && props.setWarn(true);

    if (props.selectedArea === 'financial') {
      setIsActive(!isActive);

      props.setListsub(
        props?.listsub
          ?.toString()
          ?.replace(/\s/g, '')
          ?.includes(props?.item?.replace(/\s/g, ''))
          ? props?.listsub?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.listsub, props?.item?.toString()]
      );
    }
    if (props?.selectedArea === 'physical') {
      props?.setSelectedPhysical(
        props?.selectedPhysical
          ?.toString()
          ?.replace(/\s/g, '')
          ?.includes(props?.item?.replace(/\s/g, ''))
          ? props?.selectedPhysical?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.selectedPhysical, props?.item?.toString()]
      );
    }
    if (props?.selectedArea === 'professional') {
      props?.setSelectedProfessional(
        props?.selectedProfessional
          ?.toString()
          ?.replace(/\s/g, '')
          ?.includes(props?.item?.replace(/\s/g, ''))
          ? props?.selectedProfessional?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.selectedProfessional, props?.item?.toString()]
      );
    }
    if (props?.selectedArea === 'social') {
      props?.setSelectedSocial(
        props?.selectedSocial
          ?.toString()
          ?.replace(/\s/g, '')
          ?.includes(props?.item?.replace(/\s/g, ''))
          ? props?.selectedSocial?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.selectedSocial, props?.item?.toString()]
      );
    }
    if (props?.selectedArea === 'societal') {
      props?.setSelectedSocietal(
        props?.selectedSocietal?.includes(props?.item)
          ? props?.selectedSocietal?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.selectedSocietal, props?.item?.toString()]
      );
    }
    if (props?.selectedArea === 'mental') {
      props?.setSelectedMental(
        props?.selectedMental
          ?.toString()
          ?.replace(/\s/g, '')
          ?.includes(props?.item?.replace(/\s/g, ''))
          ? props?.selectedMental?.filter(
              (n) => n?.replace(/\s/g, '') !== props?.item?.replace(/\s/g, '')
            )
          : () => [...props?.selectedMental, props?.item?.replace(/\s/g, '')]
      );
    }
  };

  return (
    <>
      <div
        key={props?.index}
        className={`${
          props?.listsub
            ?.toString()
            .replace(/\s/g, '')
            ?.includes(props?.item?.replace(/\s/g, '')) ||
          props?.selectedPhysical
            ?.toString()
            .replace(/\s/g, '')
            ?.includes(props?.item?.replace(/\s/g, '')) ||
          props?.selectedProfessional
            ?.toString()
            .replace(/\s/g, '')
            ?.includes(props?.item?.replace(/\s/g, '')) ||
          props?.selectedSocial
            ?.toString()
            .replace(/\s/g, '')
            ?.includes(props?.item?.replace(/\s/g, '')) ||
          props?.selectedMental
            ?.toString()
            .replace(/\s/g, '')
            ?.includes(props?.item?.replace(/\s/g, '')) ||
          props?.selectedSocietal?.includes(props?.item)
            ? 'backAdd '
            : ''
        } singleCategory`}
        onClick={() => selectSub(props.item)}
      >
        {props.item}
      </div>
    </>
  );
}
