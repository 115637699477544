import React from 'react';
import global from '../../../../../assets/images/global.png';
import '../../../../Card/card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

export default function IconLabel(props) {
  return (
    <div className="flex justify-between gap-4 w-full items-center">
      <div className="addLogo">
        {/* <img src={props.icon === 'phone' ? phone : ''} alt="" /> */}
        <img src={props.icon === 'global' ? global : ''} alt="" />
        {props.icon === 'phone' && (
          <FontAwesomeIcon icon={faAddressCard} color="#2691a3" size="3x" />
        )}
      </div>

      <div className="CardInput flex-1">
        <input
          required
          type="text"
          name={props.names}
          onChange={props.handleChange}
          defaultValue={props?.name}
          className="!w-full"
        />
        <label>{props.title}</label>
      </div>
    </div>
  );
}
