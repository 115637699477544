import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

export default function Toasst(props) {
  Toasst.propTypes = {
    handleClose: PropTypes.func,
    state: PropTypes.object,
  };
  // const { open } = props.state;
  // function SlideTransition(props) {
  //   return <Slide {...props} direction="up" />;
  // }

  const vertical = 'bottom';
  const horizontal = 'right';
  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={props.state.open}
        autoHideDuration={3000}
        onClose={props.handleClose}
        // TransitionComponent={SlideTransition}
        vertical="bottom"
        key={vertical + horizontal}
        horizontal="right"
      >
        <Alert
          onClose={props.handleClose}
          severity={props.error && !props.password ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {props.error ? props.error : 'Survey link is copied'}
        </Alert>
      </Snackbar>
    </div>
  );
}
