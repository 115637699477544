import React, { useState } from 'react';
import TitleList from '../Modals/TitleLists/TitleList';
import Loading from '../Views/Loading';

export default function SmallCardNoBorder(props) {
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  let finalData = props?.areas && Object.entries(props?.areas);
  return (
    <div
      style={{
        borderLeft: `{${props.border === 'none' && props.border}}!important`,
      }}
      className="SmallCardsContainer"
    >
      <div className="smallCardTitle titleMain">{props.title}</div>
      {finalData ? (
        <div
          style={{ width: `${props.from === 'questionPage' && 'auto'}` }}
          className="smallBody"
        >
          {finalData &&
            finalData.slice(0, 3).map((keys, id) => (
              <div key={id} className="listofwellbeing">
                {/* <p style={{fontSize:'20px',fontWeight:'bold'}}>{keys}</p> */}

                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {keys[0]}
                </p>
                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {keys[1]}
                </p>

                {/* <p style={{fontSize:'14px',fontWeight:'bold'}}>{props.title}</p> */}
              </div>
            ))}
        </div>
      ) : (
        <Loading />
      )}
      <div className="textAreaLink" onClick={showModal}>
        {finalData && 'View All'}
      </div>
      <TitleList
        title={props.title}
        challenge={finalData}
        show={show}
        handleClose={hideModal}
      />
    </div>
  );
}
