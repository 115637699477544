import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import TitleList from '../Modals/TitleLists/TitleList';

export default function CardSurveyChallenges(props) {
  const [message, setMessage] = useState();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getMessagesList/${user?.success?.partnerId}`
      );

      setMessage(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  let finalData = props?.areas && Object.entries(props?.areas);
  return (
    <div
      style={{
        // marginTop: '26px',
        // width: `${props.width}px`,
        height: `${props.width && ''}`,
        borderLeft: `{${props.border === 'none' && props.border}}!important`,
      }}
      className="SmallCardsContainer"
    >
      <div className="smallCardTitle titleMain">{props.title}</div>
      <div
        style={{
          width: `${props.width && 'auto'}`,
          height: `${props.width && 'maxContent'}`,
        }}
        className="smallBody"
      >
        {finalData &&
          finalData.slice(0, 2).map((keys, values) => (
            <div key={keys} className="listofwellbeing">
              <p className="surveys-data-t">{keys[0]}</p>
              <p className="surveys-data-t">{keys[1]}</p>
            </div>
          ))}
        {message &&
          message?.slice(0, 3).map((item, index) => (
            <div className="survey-data01" key={index}>
              {
                <div
                  className="listofwellbeing"
                  // onClick={() => handleContact(item)}
                >
                  <p>{item.manager_name}:</p>
                  <p className="surveys-data-t2">{item.email}</p>
                </div>
              }
            </div>
          ))}
      </div>
      {props.width === 'ljk' && (
        <div className="textAreaLink" onClick={showModal}>
          View All
        </div>
      )}
      {props.loww === 'loww' && (
        <div className="textAreaLink" onClick={showModal}>
          View All
        </div>
      )}
      {/* <SingleContact
        setShowContact={setShowContact}
        show={showContact}
        item={contact}
      /> */}
      {props.areas ? (
        <TitleList
          challenge={finalData}
          // item={message}
          title={props.title}
          // challenge={finalData}
          show={show}
          handleClose={hideModal}
        />
      ) : (
        <TitleList
          // challenge={finalData}
          item={message}
          title={props.title}
          // challenge={finalData}
          show={show}
          handleClose={hideModal}
        />
      )}
    </div>
  );
}
