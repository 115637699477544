import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Further from './Further';
import TextArea from './TextArea';
export default function ListPending(props) {
  const [resources, setResources] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const { user } = useContext(AuthContext);

  const [pendingallQuestions, setpendingAllQuestions] = useState({
    items2: [],
    currentItem2: null,
    index2: 0,
  });
  const [allQuestions, setAllQuestions] = useState({
    items: [],
    currentItem: null,
    index: 0,
  });

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getTrendingResources01/${user?.success?.partnerId}`
      );
      setResources(res.data);
    };

    fetchPosts();
  }, [user?.success?.partnerId]);
  const [jj, setJJ] = useState();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getQuestionsList/${user?.success?.partnerId}`
      );
      setpendingAllQuestions({
        items2: res.data,
        currentItem2: res.data[1],
        index2: 0,
      });
      setAllQuestions({ items: res.data, currentItem: res.data[0], index: 0 });
    };

    fetchPosts();
  }, [user?.success?.partnerId]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    allQuestions?.items?.map((item) => {
      if (item.answer === '' || item.answer === null) {
        setAllQuestions({
          currentItem: allQuestions.currentItem,
          index: allQuestions.index,
          items:
            allQuestions.items.includes(item) &&
            allQuestions.items.filter((n) => n !== item),
        });
      }
    });
    // eslint-disable-next-line array-callback-return
    pendingallQuestions?.items2?.map((item) => {
      if (!(item.answer === '' || item.answer === null)) {
        setpendingAllQuestions({
          currentItem2: pendingallQuestions.currentItem2,
          index2: pendingallQuestions.index2,
          items2:
            pendingallQuestions.items2.includes(item) &&
            pendingallQuestions.items2.filter((n) => n !== item),
        });
      }
    });
    setJJ(pendingallQuestions?.items2[0]?.answer === null);
  }, [
    jj,
    allQuestions.currentItem,
    allQuestions.index,
    allQuestions.items,
    pendingallQuestions.currentItem2,
    pendingallQuestions.index2,
    pendingallQuestions.items2,
  ]);
  // const handleNext = () => {
  //   const { index, items } = allQuestions;
  //   const { index2, items2 } = pendingallQuestions;
  //   // const { index, items } = state;
  //   // const nextQuestion = index + 1;
  //   const nextQuestion2 =
  //     pendingallQuestions.index2 < pendingallQuestions.items2?.length - 1
  //       ? index2 + 1
  //       : index2;
  //   const nextQuestion =
  //     allQuestions.index < allQuestions.items?.length - 1 ? index + 1 : index;

  //   answered &&
  //     setAllQuestions({
  //       items: items,
  //       currentItem: items[nextQuestion],
  //       index: nextQuestion,
  //     });

  //   pending &&
  //     setpendingAllQuestions({
  //       items2: items2,
  //       currentItem2: items2[nextQuestion2],
  //       index2: nextQuestion2,
  //     });
  //   setShow(false);
  // };


  // const handlePrev = () => {
  //   const { index, items } = allQuestions;
  //   const { index2, items2 } = pendingallQuestions;
  //   const nextQuestion2 = pendingallQuestions.index2 > 0 ? index2 - 1 : index2;
  //   const nextQuestion = allQuestions.index > 0 ? index - 1 : index;

  //   answered &&
  //     setAllQuestions({
  //       items: items,
  //       currentItem: items[nextQuestion],
  //       index: nextQuestion,
  //     });

  //   pending &&
  //     setpendingAllQuestions({
  //       items2: items2,
  //       currentItem2: items2[nextQuestion2],
  //       index2: nextQuestion2,
  //     });
  //   setShow(false);
  // };


  const [current2, setCurrent2] = useState();
  const [current, setCurrent] = useState();
  const handleClick = (item) => {
    setShow(true);
    setCurrent(item);
    setCurrent2(item)
  };
  return (
    <div className="CardQuestionsAreaContainer">
      <div className="questionTitleContainer">
        <div className="questionAsked titleMain titleMain2 titleMain222">
          Questions Asked
        </div>
        <div className="questionAsked titleMain titleMain2">
          Date/time submitted
        </div>
        <div className="questionAsked titleMain titleMain2"></div>
        <div className="questionAsked titleMain titleMain2">Wellbeing area</div>
        <div className="questionAsked titleMain titleMain2"></div>
      </div>
      <div className="qListContainer">
        {pendingallQuestions?.items2 &&
          pendingallQuestions?.items2?.map((item,id) => (
            <div className="qListItem" key={id}>
              <div className="qTitle qTitle2">{item.question}</div>
              <div className="qFurther qFurther2">{item.created_on}</div>
              <div className="qFurther qFurther2 qFurtherUpdated"></div>

              <div className="qFurther qFurther2 juscontcenLeft">
                <div className="juscontcenLeftLL">{item.category}</div>
              </div>
              <div className="qButton">
                <div
                  className="centerButton3 centerbtn centerbtn2 ViewdetailSmallWeb"
                  onClick={() => handleClick(item)}
                >
                  View Details
                </div>
                <div
                  className="centerButton3 centerbtn centerbtn2 ViewdetailSmall"
                  onClick={() => handleClick(item)}
                >
                  View
                </div>
              </div>
              {/* <div className="qFurther">
                {item?.rank &&
                  Array.from(Array(parseInt(item?.rank)))?.map(() => <Star />)}
              </div> */}
            </div>
          ))}
      </div>
      <Further
        from="pending"
        current2={current2}
        setShow2={setShow2}
        show2={show2}
      />
      <TextArea
        from="pending"
        current={current}
        state={props?.state}
        setSelectedResource={setSelectedResource}
        selectedResource={selectedResource}
        resources={resources}
        setShow={setShow}
        show={show}
      />
    </div>
  );
}
