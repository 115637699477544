import React from 'react';
import CardServices from '../../components/CardsServices/CardServices';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Footer from '../../components/footer/Index';

export default function Services() {
  return (
    <div className="HomeContainer">
      <Sidebar />
      <div className="mainContainers">
        <Navbar />
        <CardServices />
        <Footer />
      </div>
    </div>
  );
}
