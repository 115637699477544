import React, { useContext, useEffect, useState } from 'react';
import './sidebar.css';
import '../Card/card.css';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import KAYA from '../../assets/images/KAYA.png';
import Home from '../../assets/images/Home.png';
import profilecircle from '../../assets/images/profile-circle.png';
import category from '../../assets/images/category.png';
import messagetext from '../../assets/images/message-text.png';
import MenuIcon from '@mui/icons-material/Menu';
import stickynote from '../../assets/images/stickynote.png';
import edit from '../../assets/images/edit.png';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { IsopenContext } from '../../context/isopenContext';
import { IconButton } from '@mui/material';
import { logOutCall } from '../../ApiCalls';
export default function Sidebar() {
  const { user } = useContext(AuthContext);

  const { dispatch } = useContext(AuthContext);
  const { dispatchNav, isOpen } = useContext(IsopenContext);

  const [profileData, setData] = useState({});
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getPartnerInformation/${user?.success?.partnerId}`
      );

      setData(res.data);
    };
    fetchPosts();
  }, [user?.success?.partnerId]);
  const [data, setDatas] = useState();
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getOverallSummary/${user?.success?.partnerId}`
      );

      setDatas(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);
  const [error, setError] = useState(false);
  useEffect(() => {
    setError(
      data?.totalSurveys > 0 ||
        data?.liveSurveys > 0 ||
        data?.surveysNotStarted > 0 ||
        data?.totalQuestions > 0 ||
        data?.responses > 0 ||
        data?.newQuestions > 0
    );
  }, [
    data?.liveSurveys,
    data?.newQuestions,
    data?.responses,
    data?.surveysNotStarted,
    data?.totalQuestions,
    data?.totalSurveys,
  ]);
  const handleClick = () => {
    // window.alert('You cant go here');
  };
  const handleShow = () => {
    dispatchNav({ type: 'TOGGLE' });
    console.log(isOpen);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    logOutCall(dispatch);
  };

  return (
    <div
      className={`${
        isOpen ? 'showSidebar sidebarContainer ' : ' sidebarContainer'
      }`}
    >
      <div className="logoContainer">
        <img
          style={{ height: 'unset', maxWidth: 'unset' }}
          src={KAYA}
          alt="kaya"
        />
      </div>
      <div className="sidebarButtonContainer">
        <NavLink reloadDocument to={error ? '/profile' : '/'}>
          <div onClick={handleClick} className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={Home} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Overview</p>
            </div>
          </div>
        </NavLink>

        <NavLink reloadDocument to={'/profile'}>
          <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={profilecircle} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Profile</p>
            </div>
          </div>
        </NavLink>
        <NavLink reloadDocument to={'/services'}>
          <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={category} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Services</p>
            </div>
          </div>
        </NavLink>
        <NavLink reloadDocument to={'/resources'}>
          <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={messagetext} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Resources</p>
            </div>
          </div>
        </NavLink>
        <NavLink reloadDocument to={'/surveys'}>
          <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={stickynote} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Surveys</p>
            </div>
          </div>
        </NavLink>
        <NavLink reloadDocument to={'/questions'}>
          <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={edit} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Questions</p>
            </div>
          </div>
        </NavLink>

        {/* <div className="sidebarButtonListItem">
            <div className="sidebarButtonIcon">
              <img src={programmingarrows} alt="" />
            </div>
            <div className="sidebarButtonTitle">
              <p>Ongoing</p>
            </div>
          </div> */}
        <div
          className="sidebarButtonListItem UpgradeAndSingout"
          onClick={handleLogout}
        >
          <div className="sidebarButtonIcon">
            <FontAwesomeIcon
              className="areasprofilecompany"
              icon={faSignOut}
              color="#fff"
              size="3x"
            />
          </div>
          <div className="sidebarButtonTitle">
            <p>Sign out</p>
          </div>
        </div>
      </div>
      <div className="sidebarProfile">
        <div className="sidbebarLogoAndIcon">
          <div className="imgSidebarP">
            <img
              src={`${process.env.REACT_APP_BASE_URL}/` + profileData?.logo}
              alt=""
            />
          </div>
          <div className="humbergerIconMenu" onClick={handleShow}>
            <IconButton
              color="primary"
              style={{ color: '#fff', fontsize: '30' }}
              aria-label="delete"
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
        <div className="sidebarptitleContainer">
          <div className="ptitlesidebar">{profileData?.name}</div>
          <div className="psubtitlesidebar">{profileData?.email}</div>
        </div>
        <div className="threedotsp">
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
      </div>
    </div>
  );
}
