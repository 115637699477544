import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import CardBulkSingle from './CardBulkSingle';
export default function CardBulkUpload() {
  const { user } = useContext(AuthContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFileVideo, setSelectedFileVideo] = useState(null);
  const [mainArea, setMainArea] = useState();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const MAX_COUNT = 20;

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];

    let limitExceeded = false;
    // eslint-disable-next-line array-callback-return
    files.some((file) => {
      // if(file.size === "500"){}
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        file.size < 5e6
          ? uploaded.push(file)
          : alert('File size exceeded, file must be <5mb  ' + file.name);
        if (uploaded.length === MAX_COUNT);

        if (uploaded.length > MAX_COUNT) {
          alert(`Cannot upload files more than ${MAX_COUNT} files`);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };
  const [uploadMessage, setUploadMessage] = useState('Upload');
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const [message, setMessage] = useState();
  // const [len, setlen] = useState(0);
  const [type, setType] = useState('');

  const handleSubmit = async (event) => {
    let len = 0;
    event.preventDefault();

    if (uploadedFiles.length) {
      uploadedFiles.map(async (file) => {
        len = len + 1;
        try {
          setIsLoading(true);
          const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/api/loadIndividualResource`,
            data: {
              username: `${user?.success.username}`,
              wellbeingtype: mainArea,
              resourcetype: 2,
              video_url: '',
              title: '',
              videotitle: '',
              resourcefile: file,
              resource_type: type,
              areas_addressed: '',
            },
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          len === uploadedFiles?.length &&
            setTimeout(
              () => setIsLoading(!(len === uploadedFiles?.length)),
              30000
            );

          setSelectedFileVideo(null);
          // setUploadedFiles([]);
          len === uploadedFiles?.length &&
            setTimeout(() => setUploadMessage('Uploaded'));
          len === uploadedFiles?.length &&
            setTimeout(
              () =>
                setMessage(
                  response.data?.message
                    ? response?.data?.message
                    : 'Resources uploaded successfully'
                ),
              30000
            );
          // window.location.reload()
        } catch (error) {
          console.log(error);
        }
      });
      //  await timeout(1000); //for 1 sec delay
      //  setShow(false)
    }
    if (selectedFileVideo) {
      try {
        setIsLoading(true);
        // setShow(false);
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/bulkUploadVideoLinks`,
          data: {
            username: `${user?.success.username}`,
            videolinksfile: selectedFileVideo,
            wellbeingtype: mainArea,
            resource_type: type,
          },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setIsLoading(false);
        setSelectedFileVideo(null);
        setUploadedFiles([]);
        setMessage(response.data?.message);
      } catch (error) {
        console.log(error);
      }
      // await timeout(1000); //for 1 sec delay
      // setShow(false)
    }
  };
  const handleMain = (item) => {
    setMainArea(item);
  };

  const handleFileSelectVideo = (event) => {
    setSelectedFileVideo(event.target.files[0]);
  };
  const handleShow = () => {
    setSelected('video');
    setShow(!show);
    // show ? setTimeout(() => setShow(!show), 30000) : setShow(!show);
  };
  const handlereload = () => {
    window.location.reload();
  };
  const handleShowPDF = () => {
    setSelected('pdf');
    setShow(!show);
  };
  const downloadWeblink = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/assets/videolinks.csv`,
      headers: { 'Content-Type': 'application/ms-excel' },
    });
  };
  const doNothing = () => {};
  return (
    <div className="bulkuploadContainer">
      <div className="bulkTitle titleMain">Bulk Upload</div>
      <div className="bulkDesc">
        Please note all bulk uploads must be completed by wellbeing area.
      </div>
      <div className="bulkInputContainer">
        <div
          style={{ cursor: selectedFileVideo && 'not-allowed' }}
          className="bulkLeftInput"
        >
          <div
            style={{ cursor: selectedFileVideo && 'not-allowed' }}
            className="buldIconCont"
            onClick={!selectedFileVideo ? handleShowPDF : doNothing}
          >
            <label style={{ cursor: selectedFileVideo && 'not-allowed' }}>
              <FontAwesomeIcon icon={faFolderPlus} color="#2691a3" size="1x" />
            </label>
          </div>

          <div className="docTitle">
            <p className="FileName">Add Documents</p>
          </div>
        </div>
        <div
          style={{ cursor: uploadedFiles.length && 'not-allowed' }}
          className="bulkRightInput"
        >
          <div
            style={{ cursor: uploadedFiles.length && 'not-allowed' }}
            className="buldIconCont"
            onClick={!uploadedFiles.length ? handleShow : doNothing}
          >
            <label style={{ cursor: uploadedFiles.length && 'not-allowed' }}>
              <FontAwesomeIcon icon={faVideo} color="#2691a3" size="1x" />
            </label>
          </div>
          <div className="docTitle">
            <p className="FileName">Add Links</p>
          </div>
        </div>
        <CardBulkSingle
          handleMain={handleMain}
          selected={selected}
          show={show}
          uploadMessage={uploadMessage}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          handleShow={handleShow}
          selectedFileVideo={selectedFileVideo}
          uploadedFiles={uploadedFiles}
          handleFileEvent={handleFileEvent}
          handleFileSelectVideo={handleFileSelectVideo}
          mainArea={mainArea}
          message={message}
          handlereload={handlereload}
          type={type}
          setType={setType}
        />
      </div>
      <div>
        {uploadedFiles &&
          uploadedFiles?.map((file, id) => (
            <div key={id}>
              <p className="FileNamea">{file?.name}</p>
            </div>
          ))}
        {selectedFileVideo && (
          <div className="docTitle">
            <p className="FileNamea">
              {selectedFileVideo && selectedFileVideo.name}
            </p>
          </div>
        )}
      </div>
      <div
        className="centerButtonContainer centerButtonContainerBulk"
        onClick={downloadWeblink}
      >
        <a href={`${process.env.REACT_APP_BASE_URL}/assets/videolinks.csv`}>
          <div className="centerButton2 centerbtn rejectButtonActive">
            Download the Weblink Upload File
          </div>
        </a>
      </div>

      <div></div>
    </div>
  );
}
