import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function Further(props) {
  const { user } = useContext(AuthContext);
  const [selectedResource, setSelectedResource] = useState();
  const [answer, setAnswer] = useState();

  const handleChange = (event) => {
    setAnswer(event.target.value);
    console.log(answer);
  };
  useEffect(() => {
    if (selectedResource?.document_url) {
      setSelectedResource(
        `${process.env.REACT_APP_BASE_URL}/resources/${user?.success?.partnerId}/${selectedResource?.document_url}`
      );
    }
    if (selectedResource?.video_url) {
      setSelectedResource(
        `${process.env.REACT_APP_BASE_URL}/resources/${user?.success?.partnerId}/${selectedResource?.video_url}`
      );
    }
  }, [user?.success?.partnerId, selectedResource]);
  return (
    <div>
      {props.show2 && (
        <div className="qTitleListPop">
          {props.from === 'answered' ? (
            <div className="qcontainerpnding">
              <div className="questionTextare">
                <div style={{ fontWeight: 'bold', color: '#2691a3' }}>
                  Question
                </div>
                <div>{props.current2?.question}</div>
                <div style={{ fontWeight: 'bold', color: '#2691a3' }}>
                  Further Clarification
                </div>
                <textarea
                  onChange={handleChange}
                  value={props.current2?.questionDetails}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  readOnly
                  placeholder="Advise given by the partner"
                ></textarea>
                <div className="buttonContainerSurvey"></div>
              </div>
              <div className="cancelContainer">
                <FontAwesomeIcon
                  icon={faXmark}
                  color="#2691a3"
                  fontSize="20px"
                  onClick={() => props.setShow2(false)}
                />
              </div>
              <div
                style={{ position: 'absolute', bottom: '10px', right: '50%' }}
                className="TitleListClose"
                onClick={() => props.setShow2(false)}
              >
                Close
              </div>
            </div>
          ) : (
            <div className="qcontainerpnding">
              <div className="questionTextare">
                <div style={{ fontWeight: 'bold', color: '#2691a3' }}>
                  Question
                </div>
                <div>{props.current2?.question}</div>
                <div style={{ fontWeight: 'bold', color: '#2691a3' }}>
                  Further Clarification
                </div>

                <textarea
                  onChange={handleChange}
                  value={props.current2?.questionDetails}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Advise given by the partner"
                ></textarea>
              </div>
              <div className="cancelContainer">
                <FontAwesomeIcon
                  icon={faXmark}
                  color="#2691a3"
                  fontSize="20px"
                  onClick={() => props.setShow2(false)}
                />
              </div>
              <div
                style={{ position: 'absolute', bottom: '10px', right: '50%' }}
                className="TitleListClose"
                onClick={() => props.setShow2(false)}
              >
                Close
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
