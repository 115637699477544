import React from 'react';

export default function SingleSub(props) {
  return (
    <div
      className={`${
        props.single?.toString()?.includes(props?.item) ? 'backAdd ' : ''
      } resourceCategory resourceCategorySingle`}
      onClick={() => props?.handleClick(props?.item)}
      style={{ pointerEvents: props.canEdit ? 'auto' : 'none' }}
    >
      {props.item?.toString()}
    </div>
  );
}
