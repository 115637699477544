import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'; // <-- import styles to be used
import axios from 'axios';
import SingleSub from './SingleSub';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
export default function SingleCard(props) {
  const [subAreas, setSubAreas] = useState();
  let canEdit = parseInt(props.single?.kaya_promoted) === 0;
  console.log(props.single?.kaya_curated);
  const [mainArea, setMainArea] = useState();
  const [resourceName, setResourceName] = useState();
  const [resourceUrl, setResourceUrl] = useState();
  const [type, setType] = useState(props.single?.resource_type);
  const [resourceId, setResourceId] = useState();
  const [single, setSingle] = useState();
  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getHelpAreasSplits`
      );
      setSubAreas(res.data);
    };

    fetchPosts();
  }, []);
  const handleChange = (e) => {
    setResourceName(e.target.value);
  };
  const handleChangeURL = (e) => {
    setResourceUrl(e.target.value);
  };
  useEffect(() => {
    setType(props.single?.resource_type);
  }, [props.single?.resource_type]);
  useEffect(() => {
    setResourceName(props.single?.title);
    setResourceUrl(props.single?.video_url);
  }, [props.single?.title, props.single?.video_url]);

  useEffect(() => {
    setResourceId(props.single?.id);
  }, [props.single?.id]);
  var finalitem = [];
  useEffect(() => {
    setSingle(props.single?.areas_addressed?.split(','));
    // props.single?.areas_addressed.split(',').map(()=>}
    // )
  }, [props.single?.areas_addressed]);
  for (let i = 0; i < single; i++) {
    single && finalitem.push(toString(single[i]).trim());
    console.log(i);
  }
  console.log(finalitem);
  useEffect(() => {
    subAreas?.map(
      (item) =>
        item?.area.toUpperCase() ===
          props.single?.wellbeingtype.toUpperCase() &&
        setMainArea(item.areaSplits)
    );
  }, [props.single?.wellbeingtype, subAreas]);
  const handleClick = (value) => {
    single &&
      setSingle(
        single?.includes(value)
          ? single?.filter((n) => n !== value)
          : () => [...single, value]
      );
    !single && setSingle((prev) => [prev, value.toString()]);
  };

  const handleUpdate = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updateResourceSubAreas`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        subAreas: single?.toString(),
        resourceId: resourceId,
        title: resourceName,
        resource_type: type,
        video_url: resourceUrl,
      },
    })
      .then(function (response) {
        props?.setShowSingle(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRemove = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/removeResource`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resourceId: resourceId },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const updatePromote = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updateResourcePromote`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resource_id: resourceId, promote: 1 },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const updatePromote2 = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/api/updateResourcePromote`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: { resource_id: resourceId, promote: 0 },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let sortedUsernamesArray =
    mainArea &&
    mainArea?.split(',')?.sort(function (a, b) {
      //
      return a.title > b.title ? 1 : -1;
    });
  let trimmedArray = [];
  for (let i = 0; i < sortedUsernamesArray?.length; i++)
    trimmedArray[i] = sortedUsernamesArray[i].trim();

  console.log('props.single', {
    subAreas: single?.toString(),
    resourceId: resourceId,
    title: resourceName,
    resource_type: type,
    video_url: resourceUrl,
  });
  return (
    <>
      {props.showSingle && (
        <div
          className="listResourceContainer singleResource single-resource-edit"
          key={props.single?.id}
        >
          <div className="TitleListContainer TitleListContainerSingle">
            <div className="listResourceTitle TitleListTitle">
              <div className="Listcontainertitle">
                <div className="leftdivtitle">Title</div>
                <div className="inpufcntiner">
                  <input
                    type="text"
                    disabled={!canEdit}
                    placeholder={
                      props.single?.title ? '' : "Please enter resource's title"
                    }
                    defaultValue={
                      props.single?.title ? props.single?.title : ''
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="Listcontainertitle">
                <div className="leftdivtitle">Weblink URL</div>
                <div className="inpufcntiner">
                  <input
                    type="text"
                    disabled={!canEdit}
                    placeholder={
                      props.single?.titlvideo_urle
                        ? ''
                        : "Please enter resource's video url"
                    }
                    defaultValue={
                      props.single?.video_url ? props.single?.video_url : ''
                    }
                    onChange={handleChangeURL}
                  />
                </div>
              </div>
            </div>

            <div className="listResourceTitle">
              <h2>{props.single?.wellbeingtype} Wellbeing</h2>
            </div>
            <div className="fffffff">
              {trimmedArray?.sort()?.map((index, id) => {
                return (
                  <SingleSub
                    canEdit={canEdit}
                    single={single}
                    key={id}
                    handleClick={handleClick}
                    singleArea={props.single?.areas_addressed?.split(',')}
                    subAreas={subAreas}
                    mainArea={mainArea?.split(',')}
                    item={index}
                  />
                );
              })}
            </div>
            <FormControl sx={{ span: { color: 'green' } }} className="!ml-4">
              <FormLabel
                id="demo-radio-buttons-group-label"
                className="!text-xl"
              >
                Resources type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={type}
                defaultValue={props.single?.resource_type}
                row
                onChange={(e) => setType(e.target.value)}
                name="radio-buttons-group"
                className="justify-center"
              >
                <FormControlLabel
                  value="free"
                  control={<Radio />}
                  label="Free"
                />
                <FormControlLabel
                  value="paid"
                  control={<Radio />}
                  label="Paid"
                />
              </RadioGroup>
            </FormControl>
            <div className="resourceIconContainer">
              {!parseInt(props.single?.promote_count) > 0 && (
                <div
                  className="removeIcon"
                  onClick={handleRemove}
                  style={{ pointerEvents: canEdit ? 'auto' : 'none' }}
                >
                  Remove
                </div>
              )}
              <div>
                {parseInt(props.single?.promote_count) < 0 && (
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    color="#2691a3"
                    size="2x"
                  />
                )}
              </div>
              <div
                style={{
                  backgroundColor: `${
                    props.single?.promote === '0' ? '#2691a3' : ''
                  }`,
                }}
                className="iconandrating"
              >
                <div className="iconcontainer">
                  {props.single?.promote === '0' && (
                    <div
                      className="promote-single"
                      style={{
                        backgroundColor: '#2691a3',
                        color: 'white',
                        pointerEvents: canEdit ? 'auto' : 'none',
                      }}
                      onClick={updatePromote}
                    >
                      Promote
                    </div>
                  )}
                  {props.single?.promote === '1' && (
                    <div
                      style={{ pointerEvents: canEdit ? 'auto' : 'none' }}
                      className="promote-single"
                      onClick={updatePromote2}
                    >
                      Stop Promoting
                    </div>
                  )}
                </div>
                {/* <div className="rating">{props.single?.rate}</div> */}
              </div>
            </div>
            <div
              className="centerButton3 rejectButtonActive singleUpdate centerbtn"
              style={{ pointerEvents: canEdit ? 'auto' : 'none' }}
              onClick={handleUpdate}
            >
              Update
            </div>
            <div
              className="closeTag rejectButtonActive"
              onClick={() => props?.setShowSingle(false)}
            >
              Close
            </div>
          </div>
        </div>
      )}
    </>
  );
}
