import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import './feel.css';
export default function FeelWork() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState();
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getOverallSummary/${user?.success.partnerId}`
      );

      setData(res.data);
    };

    fetchDatas();
  }, [user?.success.partnerId]);
 

  return (
    <div className="feelContainer">
      <div className="feelTitleNew titleMain">Wellbeing Trends</div>
      <div className="feelmain">
        <div className="feelleft">
          <div className="feelright">
            <div className="rightItem rightitemleft">
              <div className="rightSingle" style={{color:'white'}}>.</div>
              <div className='feetlit' >
                <div className="rightSingle">Issues</div>
                <div className="rightSingle">Risk</div>
                <div className="rightSingle">Maintain</div>
              </div>
            </div>
          </div>
        </div>
        <div className="feelright">
        <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Financial</div>
              <div className="rightSingle feelred" style={{position:'relative'}}>
                {data?.ratings?.financial_tag?.issue?data?.ratings?.financial_tag?.issue:0}

              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.financial_tag?.risk?data?.ratings?.financial_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.financial_tag?.maintain?data?.ratings?.financial_tag?.maintain:0}
              </div>
            </div>
          </div>
          <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Professional</div>

              <div className="rightSingle feelred">
                {data?.ratings?.professional_tag?.issue?data?.ratings?.professional_tag?.issue:0}
              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.professional_tag?.risk?data?.ratings?.professional_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.professional_tag?.maintain?data?.ratings?.professional_tag?.maintain:0}
              </div>
            </div>
          </div>
          <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Mental</div>

              <div className="rightSingle feelred">
                {data?.ratings?.mental_tag?.issue?data?.ratings?.mental_tag?.issue:0}
              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.mental_tag?.risk?data?.ratings?.mental_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.mental_tag?.maintain?data?.ratings?.mental_tag?.maintain:0}
              </div>
            </div>
          </div>
          <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Physical</div>
              <div className="rightSingle feelred">
                {data?.ratings?.physical_tag?.issue?data?.ratings?.physical_tag?.issue:0}
              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.physical_tag?.risk?data?.ratings?.physical_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.physical_tag?.maintain?data?.ratings?.physical_tag?.maintain:0}
              </div>
            </div>
          </div>
          <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Social</div>
              <div className="rightSingle feelred">
                {data?.ratings?.social_tag?.issue?data?.ratings?.social_tag?.issue:0}
              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.social_tag?.risk?data?.ratings?.social_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.social_tag?.maintain?data?.ratings?.social_tag?.maintain:0}
              </div>
            </div>
          </div>
          <div className="feelright">
            <div className="rightItem">
              <div className="rightSingle titlefeell">Societal</div>
              <div className="rightSingle feelred">
                {data?.ratings?.societal_tag?.issue?data?.ratings?.societal_tag?.issue:0}
              </div>
              <div className="rightSingle feelyellow">
                {data?.ratings?.societal_tag?.risk?data?.ratings?.societal_tag?.risk:0}
              </div>
              <div className="rightSingle feelgreen">
                {data?.ratings?.societal_tag?.maintain?data?.ratings?.societal_tag?.maintain:0}
              </div>
            </div>
          </div>
        </div>

      <div style={{position:'absolute', top:'53%',width:'100%',height:'1.5px',backgroundColor:'#2691a3'}}></div>
      <div style={{position:'absolute', top:'75%',width:'100%',height:'1.5px',backgroundColor:'#2691a3'}}></div>
      <div style={{position:'absolute', top:'97%',width:'100%',height:'1.5px',backgroundColor:'#2691a3'}}></div>
      </div>
    </div>
  );
}
