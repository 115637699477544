import React from 'react';
import '../../../../Card/card.css';
import profilecircle from '../../../../../assets/images/profile-circle.png';
import Logo from '../../../../../assets/images/Logo.png';
export default function ImageUpload(props) {
  return (
    <div className="">
      <label htmlFor={'picturefile'}>
        <div className="imguploaded">
          <div className="addLogo addProfileLogoo !w-24 !h-18 relative">
            <input
              type="file"
              name={'picturefile'}
              id={'picturefile'}
              onChange={(e) => props?.handleChange(e)}
              accept=".png,.jpeg,.jpg"
              style={{ border: 'none', display: 'none' }}
              className=""
            />
            <label
              htmlFor={'picturefile'}
              className="flex flex-col items-center justify-end py-1 !text-xs absolute bottom-0"
            >
              <img
                className="w-6"
                htmlFor="picturefile"
                src={
                  !props.file
                    ? props.image
                      ? props.image
                      : Logo
                    : URL.createObjectURL(props.file)
                }
                alt=""
              />
              Add a Logo
            </label>
          </div>
          <div className="rejectButtonActive helpButton">
            <div className="navHelpButton">
              <img src={profilecircle} alt="" />
            </div>

            <div className="navButtonTitle">
              <p>Upload</p>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
}
