import React, { useContext } from 'react';
import ListOfQuestionRating from '../Views/ListOfQuestionRating/ListOfQuestionRating';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faRunning } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
export default function CardQuestionRating() {
  const [data, setData] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getQuestionStats/${user?.success?.partnerId}`
      );

      setData(res.data);
    };

    fetchDatas();
  }, [user?.success?.partnerId]);

  return (
    <div className="CardQuestionRatingContainer">
      <div className="qRetingTitle titleMain">Question Rating</div>
      <div className="qRatingsContainer">
        <ListOfQuestionRating
          rate="5"
          color="#4AA9BF"
          data={data && Object.values(data.ratings.financial)}
        />
        <ListOfQuestionRating
          rate="4"
          color="#9AD2C5"
          data={data && Object.values(data.ratings.professional)}
        />
        <ListOfQuestionRating
          rate="3"
          color="#F7BB1F"
          data={data && Object.values(data.ratings.physical)}
        />
        <ListOfQuestionRating
          rate="2"
          color="#EE7D2F"
          data={data && Object.values(data.ratings.social)}
        />
        <ListOfQuestionRating
          rate="1"
          color="#E9666B"
          data={data && Object.values(data.ratings.societal)}
        />
        <div className="ratingItemCont">
          <div className="ratingcomponent">
            <div>
              {' '}
              {data &&
                Object.values(data.ratings.financial)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
            <div>
              {data &&
                Object.values(data.ratings.professional)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
            <div>
              {data &&
                Object.values(data.ratings.physical)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
            <div>
              {data &&
                Object.values(data.ratings.mental)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
            <div>
              {data &&
                Object.values(data.ratings.social)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
            <div>
              {data &&
                Object.values(data.ratings.societal)
                  .reverse()
                  .map((values, id) => (
                    <div className="res-value" key={id}>
                      {values}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div className="wellcntr">
        <div className="centerIconAndTitleContainer">
          <div className="centerIconAndTitle ">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faCoins} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Financial <br /> Wellbeing
            </div>
          </div>
          <div className="centerIconAndTitle">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faHandshake} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Professional <br /> Wellbeing
            </div>
          </div>
          <div className="centerIconAndTitle">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faRunning} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Physical <br /> Wellbeing
            </div>
          </div>
          <div className="centerIconAndTitle">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faBrain} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Mental <br /> Wellbeing
            </div>
          </div>
          <div className="centerIconAndTitle">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faSmile} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Social <br /> Wellbeing
            </div>
          </div>
          <div className="centerIconAndTitle">
            <div className="centerIcon">
              <FontAwesomeIcon icon={faUsers} color="#2691a3" size="2x" />
            </div>
            <div className="centerTitle">
              Societal <br /> Wellbeing
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
