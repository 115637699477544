import React, { useState } from 'react';
import SurveyBanner2 from '../../assets/images/SurveyBanner2.jpeg';
import kayalogo from '../../assets/images/kayalogo.jpeg';
import '../Login/login.css';
import './signup.css';
import Button from '@mui/material/Button';
import Footer from '../../components/footer/Index';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function Signup() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const date = new Date();
  const year = date.getFullYear();

  const navigate = useNavigate();
  const handleClick = async (e) => {
    setLoading(true);
    state.email?.length > 7 && state.confirm === state.email
      ? await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BASE_URL}/api/addNewPartner`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: {
            description: state.description,
            email: state.email,
            first_name: state.first_name,
            name: state.name,
            phone: state.phone,
            surname: state.surname,
            url: state.url,
            picturefile: state.picturefile,
          },
        })
          .then(function (response) {
            response?.data?.error && setError(response.data?.error);
            console.log(response);
            !response?.data?.error &&
              navigate('/login', { state: { id: '1' } });

            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            setError(error.message + ', please try again');
          })
      : state.email?.length > 7 && !(state.confirm === state.email)
      ? setError('Email must match')
      : setError('Enter a valid email');
    setLoading(false);
  };
  const [state, setState] = useState({});

  const handleUser = (e) => {
    const val = e.target.name;
    setState({
      ...state,
      [val]: e.target.value,
    });
    setError(false);
  };
  console.log(state);

  const handleChangelogo = (e) => {
    (e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpeg' ||
      e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'png' ||
      e.target.files[0]?.type?.split('/')[1]?.toLowerCase() === 'jpg') &&
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    // console.log(state);
  };
  return (
    <div>
      <div className="LoginContainer signupContainer">
        <div className="loginImageConai">
          <img src={SurveyBanner2} alt="loginImageConaiImage" />
        </div>
        <div className="loginLogoContainer">
          <img className="loginLogoContainerImage" src={kayalogo} alt="" />
        </div>
        <h2 className="heading">Partner Details</h2>

        <div className="com-reg-d">
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4>Please enter your business name</h4>{' '}
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  placeholder="Business name"
                  name="name"
                  readOnly={false}
                  // ref={username}
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4>Please enter your business URL</h4>{' '}
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="url"
                  readOnly={false}
                  placeholder="Business URL"
                  // ref={username}
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4>Please enter a description of business</h4>{' '}
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup input-container-textarea">
                <textarea
                  name="description"
                  onChange={handleUser}
                  placeholder="Description"
                  id=""
                ></textarea>
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4>{''}</h4>
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <div className="signupLogo">
                  <Button
                    className="signupCVbtnSign"
                    htmlFor="cvFile"
                    variant="contained"
                    style={{
                      minHeight: '50px',
                      backgroundColor: '#2691a3',
                    }}
                  >
                    Upload Your Logo
                    <input
                      type="file"
                      name="picturefile"
                      accept=".png,.jpeg,.jpg"
                      onChange={handleChangelogo}
                    />
                  </Button>
                  {state?.picturefile && (
                    <img
                      src={
                        state?.picturefile
                          ? URL?.createObjectURL(state?.picturefile)
                          : ''
                      }
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4>Please enter contact information</h4>{' '}
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="first_name"
                  readOnly={false}
                  // ref={username}
                  placeholder="First name"
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4> </h4>
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="surname"
                  readOnly={false}
                  placeholder="Surname"
                  // ref={username}
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4> </h4>
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="email"
                  readOnly={false}
                  placeholder="Email"
                  // ref={username}
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4> </h4>
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="confirm"
                  readOnly={false}
                  placeholder="Confirm Email"
                  // ref={username}
                  required
                />
              </div>
            </div>
          </div>
          <div className="com-reg-d1">
            <div className="com-reg-d1-l">
              <h4> </h4>
            </div>
            <div className="com-reg-d1-r">
              <div className="input-container input-container-signup">
                <input
                  onChange={handleUser}
                  type="text"
                  name="phone"
                  readOnly={false}
                  // ref={username}
                  placeholder="Contact phone"
                  required
                />
              </div>
            </div>
          </div>

          <div className="rg-btn">
            <Button
              disabled={loading}
              className="signupCVbtnSign"
              htmlFor="cvFile"
              variant="contained"
              style={{
                backgroundColor: '#FDC003',
              }}
              onClick={handleClick}
            >
              Save
            </Button>
          </div>
          <div className="ky-lg-err">{error}</div>
          <div className="ky-lg-link">
            {' '}
            Already have an account? <Link to={'/login'}>Login here.</Link>
          </div>
        </div>
        <div
          className="loginBottom"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '10px 0px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Footer />
          </div>
          <div>{year} © kayawellbeingindex.com All rights reserved.</div>
        </div>
      </div>
    </div>
  );
}
